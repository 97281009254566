import { defineComponent as _defineComponent } from 'vue'
import IconButton from "@/common/components/IconButtonV2.vue";
import Menu from "@/common/components/Menu.vue";
import { asyncNotStarted, asyncResultOr } from "@/common/lib/async";
import { GraphValueType, QUANTITATIVE_VALUE_TYPES } from "@/common/lib/value";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { computed, Ref, ref } from "vue";
import PropertyValue from "./PropertyValue.vue";
import Textbox from "@/common/components/Textbox.vue";
import { propertyValueType } from "@/common/lib/derived";
import { columnName, QueryColumn } from "@/common/lib/query";
import { isFunction } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'Table',
  setup(__props, { expose: __expose }) {
  __expose();

const dropdownShown = ref<string | null>(null);
const exploreStore = useExploreStore();
const renamingColumnAlias: Ref<string | undefined> = ref(undefined);
const renamingColumn: Ref<string | undefined> = ref(undefined);

const columns = computed(() =>
  (exploreStore.query?.columns ?? []).map((columnDef) => ({
    column: columnDef,
    label: columnName(columnDef),
    menuItems: columnMenuItems(columnDef.alias),
    sort: exploreStore.columnSortState(columnDef.alias),
    isQuantitative: QUANTITATIVE_VALUE_TYPES.includes(
      propertyValueType(columnDef.property_type) as GraphValueType
    ),
  }))
);

const columnMenuItems = (columnAlias: string) => {
  const items = exploreMenu(ExploreMenuSubject.Column, { columnAlias });
  items.push({
    key: "rename",
    label: "Rename",
    action: () => {
      renamingColumnAlias.value = columnAlias;
    },
  });
  return items;
};

const table = computed(() => asyncResultOr(exploreStore.table, []));

const isDropdownShown = (alias: string) => {
  return alias === dropdownShown.value;
};

const toggleDropdownShown = (alias: string, shown: boolean) => {
  dropdownShown.value = shown ? alias : null;
};

const sortOrderLabel = (order: number | undefined) => {
  if (order !== undefined && exploreStore.query!.order_by.length > 1) {
    return String(order + 1);
  }
};

function deselectAndRename(event: Event) {
  if (event.target && "blur" in event.target && isFunction(event.target.blur)) {
    event.target.blur();
  }
}

function rename(column: QueryColumn) {
  if (renamingColumn.value) {
    exploreStore.renameColumn(column.alias, renamingColumn.value);
    renamingColumn.value = undefined;
    exploreStore.sqlData = asyncNotStarted();
  }
  renamingColumnAlias.value = undefined;
}

function toggleAscendingAt(index: number) {
  const newOrderBy = [...exploreStore.query!.order_by];
  const target = newOrderBy[index];
  newOrderBy.splice(index, 1, { ...target, asc: !target.asc });
  exploreStore.setOrderBy(newOrderBy);
}

function formatWithLeadingZeros(number: number, length: number) {
  return String(number).padStart(length, "0");
}

const draggedColumnIndex = ref<number | null>(null);

function onColumnDragStart(index: number) {
  draggedColumnIndex.value = index;
}

function onColumnDrop(index: number) {
  if (draggedColumnIndex.value === null || exploreStore.query == null) return;

  const queryColumns = [...exploreStore.query.columns];
  const [movedColumn] = queryColumns.splice(draggedColumnIndex.value, 1);
  queryColumns.splice(index, 0, movedColumn);
  exploreStore.setColumnOrder(queryColumns);

  draggedColumnIndex.value = null;
}

const __returned__ = { dropdownShown, exploreStore, renamingColumnAlias, renamingColumn, columns, columnMenuItems, table, isDropdownShown, toggleDropdownShown, sortOrderLabel, deselectAndRename, rename, toggleAscendingAt, formatWithLeadingZeros, draggedColumnIndex, onColumnDragStart, onColumnDrop, IconButton, Menu, get Dropdown() { return Dropdown }, PropertyValue, Textbox }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})