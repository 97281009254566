<template>
  <Icon name="spinner" :size="size" class="opacity-40" />
</template>

<script lang="ts" setup>
import { IconSize } from "../lib/icons";
import Icon from "./Icon.vue";

withDefaults(
  defineProps<{
    size?: IconSize;
  }>(),
  { size: "xl" }
);
</script>
