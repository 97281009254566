/* Helper functions for dealing with routers and routes */

import { Routes, SelectedTab } from "@/common/stores/settingsStore";
import { isArray } from "lodash";
import { Component } from "vue";
import {
  LocationQueryValue,
  RouteLocationNormalizedLoaded,
  Router,
  RouteRecordRaw,
} from "vue-router";
/**
 * Get the value of a route parameter (e.g., /:moduleId).
 *
 * Return undefined if the parameter doesn't exist.
 * If multiple parameter values exist, return the first one.
 * @param route Vue route
 * @param key parameter key
 * @returns parameter value, or undefined if it isn't defined.
 */
export function getRouteParameter(
  route: RouteLocationNormalizedLoaded,
  key: string
): string | undefined {
  if (!route) {
    return undefined;
  }
  return extractParameter(route.params[key]);
}

/**
 * Get the value of a query parameter (e.g., ?foo=bar).
 *
 * Return undefined if the parameter doesn't exist.
 * If multiple parameter values exist, return the first one.
 * @param route Vue route
 * @param key parameter key
 * @returns parameter value, or undefined if it isn't defined.
 */
export function getQueryParameter(
  route: RouteLocationNormalizedLoaded,
  key: string
): string | undefined {
  return extractParameter(route.query[key]);
}

function extractParameter(
  value: string | string[] | LocationQueryValue[] | undefined | null
): string | undefined {
  if (value == null) {
    return undefined;
  }
  if (isArray(value)) {
    return value[0] ?? undefined;
  }
  return value;
}

/**
 * Add a query parameter (e.g., ?key=value) to the current URL.
 *
 * @param router Vue router
 * @param key parameter key
 * @param value parameter value
 */
export function addQueryParameter(router: Router, key: string, value: string) {
  // Copy the query; otherwise the replace won't work.
  const query = Object.assign({}, router.currentRoute.value.query);
  query[key] = value;
  router.replace({ query });
}

/**
 * Remove a query parameter key from the current URL.
 *
 * This should be a no-op if the parameter doesn't exist.
 * @param router Vue router
 * @param key parameter key
 */
export function removeQueryParameter(router: Router, key: string) {
  // Copy the query; otherwise the replace won't work.
  const query = Object.assign({}, router.currentRoute.value.query);
  delete query[key];
  router.replace({ query });
}

/**
 * Get common routes shared between home and reader
 *
 * @param mainComponent the component to load for that route
 * @returns an Array of routes
 */
export function createSharedRoutes(mainComponent: Component): Array<RouteRecordRaw> {
  return [
    {
      path: "/",
      name: Routes.Home,
      component: mainComponent,
      children: [
        {
          path: SelectedTab.Connections,
          name: SelectedTab.Connections,
          component: mainComponent,
        },
        {
          path: SelectedTab.UserSettings,
          name: SelectedTab.UserSettings,
          component: mainComponent,
        },
        {
          path: SelectedTab.Preferences,
          name: SelectedTab.Preferences,
          component: mainComponent,
        },
        {
          path: SelectedTab.Admin,
          name: SelectedTab.Admin,
          component: mainComponent,
        },
        {
          path: SelectedTab.QuickBooks,
          name: SelectedTab.QuickBooks,
          component: mainComponent,
        },
      ],
    },
  ];
}
