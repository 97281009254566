<template>
  <v-select
    v-model="model"
    class="select"
    :class="{ 'light-select': !darkMode }"
    :options="options"
    :getOptionLabel="groupByLabel"
    :clearable="false"
    placeholder="Choose a Property"
  />
</template>

<script lang="ts" setup>
import { rootColumns } from "@/reader/lib/explore";
import { computed, inject, Ref, ComputedRef } from "vue";
import vSelect from "vue-select";
import { DarkMode } from "@/common/lib/keys";
import { columnName, QueryColumn } from "@/common/lib/query";
import { v4 as uuid } from "uuid";

const darkMode = inject(DarkMode) as Ref<boolean>;
const model = defineModel<QueryColumn | null>({ required: true });

const options: ComputedRef<QueryColumn[]> = computed(() =>
  rootColumns().map((column) => ({
    alias: uuid(),
    property_type: column.property_type,
  }))
);

function groupByLabel(col: QueryColumn) {
  return columnName(col);
}
</script>
