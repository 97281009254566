import { Query } from "@/common/lib/query";
import { ExploreState } from "../stores/explore";
import { VisualizationType } from "./visualizationTypes";

// Reader Session Storage keys
const READER_SESSION_EXPLORE_STATE = "explore_state";

// Store a query and optional visualization to be loaded exactly once upon
// navigating to explore

export type StoredExploreState = Required<Pick<ExploreState, "query">> &
  Pick<ExploreState, "visualizationConfig" | "visualizationType">;

export function storeExploreState(
  query: Query,
  visualizationType?: VisualizationType,
  visualizationConfig?: Record<string, unknown>
) {
  window.sessionStorage.setItem(
    READER_SESSION_EXPLORE_STATE,
    JSON.stringify({
      query,
      visualizationType,
      visualizationConfig,
    })
  );
}

// If a query is stored, return it for single use and then remove it
export function consumeStoredExploreState(): StoredExploreState | null {
  const item = window.sessionStorage.getItem(READER_SESSION_EXPLORE_STATE);
  if (item == null) {
    return null;
  } else {
    window.sessionStorage.removeItem(READER_SESSION_EXPLORE_STATE);
    return JSON.parse(item);
  }
}
