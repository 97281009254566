// Concrete visualization types and their configuration definitions

import { Query } from "@/common/lib/query";
import { GraphValueType } from "@/common/lib/value";
import { PostprocessOperation } from "./postprocess";
import { ValueGenerator } from "./visualization";
import { VisualizationConfigControlType, VisualizationTypeDef } from "./visualizationConfig";

export enum VisualizationType {
  DiscreteDistribution = "discrete_distribution",
  TimeDistribution = "time_distribution",
  Indicator = "indicator",
  PieChart = "pie_chart",
  Table = "table",
  Sankey = "sankey",
  FinancialStatement = "financial_statement",
}

interface BaseVisualization {
  type: VisualizationType;
  title: string; // Maybe this is eventually optional
  query: Query;
  config: Record<string, unknown>;
  postprocess?: PostprocessOperation[];
  description?: string;
  explore_disabled?: boolean;
}

export interface DiscreteDistributionVisualization extends BaseVisualization {
  type: VisualizationType.DiscreteDistribution;
  config: {
    category: ValueGenerator;
    category_name?: ValueGenerator; // If not specified, category is used
    subcategory?: ValueGenerator; // Results in stacked bars if specified
    multicolor?: boolean; // If true non-stacked bars will be different colors
    value: ValueGenerator;
  };
}

export interface TimeDistributionVisualization extends BaseVisualization {
  type: VisualizationType.TimeDistribution;
  config: {
    time: ValueGenerator;
    value: ValueGenerator;
    category?: ValueGenerator; // Results in stacked bars with a legend if specified
  };
}

export interface PieChartVisualization extends BaseVisualization {
  type: VisualizationType.PieChart;
  config: {
    category: ValueGenerator;
    category_name?: ValueGenerator; // If not specified, category is used
    value: ValueGenerator;
    show_legend_values?: boolean; // Defaults to false
    show_total?: boolean; // Defaults to false
  };
}

export interface IndicatorVisualization extends BaseVisualization {
  type: VisualizationType.Indicator;
  config: {
    value: ValueGenerator;
  };
}

export interface TableVisualizationGroup {
  category: string;
  category_name?: ValueGenerator; // If not specified, category is used
}

export interface TableVisualization extends BaseVisualization {
  type: VisualizationType.Table;
  config: {
    columns: Array<{
      value: ValueGenerator;
      label?: string; // If left out, we'll try to determine one automatically
    }>;
    groups?: TableVisualizationGroup[];
  };
}

export interface FinancialStatementRow {
  label: string;
  id?: string; // Give this row an ID so as to refer to it in a compareToId
  compareToId?: string;
  value: ValueGenerator;
  highlight?: boolean;
  contents?: FinancialStatementRow[];
  asset?: boolean; // Is this line item an asset (vs. a liability); defaults to false
}

export interface FinancialStatementVisualization extends BaseVisualization {
  type: VisualizationType.FinancialStatement;
  config: {
    rows: FinancialStatementRow[];
    columns: ValueGenerator;
    trailing_months?: number[];
    ytd?: boolean;
  };
}

export interface SankeyVisualization extends BaseVisualization {
  type: VisualizationType.Sankey;
  config: {
    links: Array<{
      from: string;
      to: string;
      value: ValueGenerator;
    }>;
    transformer?: string;
  };
}

export type Visualization =
  | DiscreteDistributionVisualization
  | TimeDistributionVisualization
  | IndicatorVisualization
  | PieChartVisualization
  | TableVisualization
  | SankeyVisualization;

export const VISUALIZATION_CONFIG_DEFS: Record<string, VisualizationTypeDef> = {
  [VisualizationType.DiscreteDistribution]: {
    label: "Category Bars",
    controls: [
      {
        type: VisualizationConfigControlType.Column,
        key: "category",
        label: "Category",
        description: "Identifies each bar.",
        config: {
          limit_value_types: [GraphValueType.String, GraphValueType.Bool],
        },
      },
      {
        type: VisualizationConfigControlType.Column,
        key: "value",
        label: "Value",
        description: "Sets the length of the bars.",
        config: {
          limit_value_types: [
            GraphValueType.Float,
            GraphValueType.Integer,
            GraphValueType.Duration,
          ],
        },
      },
      {
        type: VisualizationConfigControlType.Column,
        key: "category_name",
        label: "Category Label",
        optional: true,
        description:
          "Optionally provides labels for the bars. If not provided, the Category will be used.",
        config: {
          limit_value_types: [GraphValueType.String],
        },
      },
      {
        type: VisualizationConfigControlType.Column,
        key: "subcategory",
        label: "Subcategory",
        optional: true,
        description: "Optionally subdivides each bar.",
        config: {
          limit_value_types: [GraphValueType.String, GraphValueType.Bool],
        },
      },
    ],
  },
  [VisualizationType.PieChart]: {
    label: "Pie Chart",
    controls: [
      {
        type: VisualizationConfigControlType.Column,
        key: "category",
        label: "Category",
        description: "Identifies each pie slice.",
        config: {
          limit_value_types: [GraphValueType.String, GraphValueType.Bool],
        },
      },
      {
        type: VisualizationConfigControlType.Column,
        key: "value",
        label: "Value",
        description: "Sets the size of the slices.",
        config: {
          limit_value_types: [
            GraphValueType.Float,
            GraphValueType.Integer,
            GraphValueType.Duration,
          ],
        },
      },
      {
        type: VisualizationConfigControlType.Column,
        key: "category_name",
        label: "Category Label",
        optional: true,
        description:
          "Optionally provides labels for the slices. If not provided, the Category will be used.",
        config: {
          limit_value_types: [GraphValueType.String],
        },
      },
    ],
  },
  [VisualizationType.TimeDistribution]: {
    label: "Time Periods",
    controls: [
      {
        type: VisualizationConfigControlType.Column,
        key: "time",
        label: "Time Period",
        description: "An hour, day, month, etc. identifying each bar.",
        config: {
          limit_value_types: [GraphValueType.Date, GraphValueType.Datetime],
        },
      },
      {
        type: VisualizationConfigControlType.Column,
        key: "value",
        label: "Value",
        description: "Sets the height of each bar.",
        config: {
          limit_value_types: [
            GraphValueType.Float,
            GraphValueType.Integer,
            GraphValueType.Duration,
          ],
        },
      },
      {
        type: VisualizationConfigControlType.Column,
        key: "category",
        label: "Category",
        optional: true,
        description: "Optionally divides each bar into a stack broken up by this value.",
        config: {
          limit_value_types: [GraphValueType.String, GraphValueType.Bool],
        },
      },
    ],
  },
  [VisualizationType.Indicator]: {
    label: "Summary Indicator",
    controls: [
      {
        type: VisualizationConfigControlType.Column,
        key: "value",
        label: "Value",
        description: "Sets the value to be shown.",
        config: {},
      },
    ],
  },
};
