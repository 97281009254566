import {
  Async,
  asyncFailed,
  asyncInProgress,
  asyncNotStarted,
  asyncSucceeded,
} from "@/common/lib/async";
import { defineStore } from "pinia";
import { httpClient } from "../http/http";
import { UserSettings } from "../lib/userSettings";
import { Warehouse } from "./workspaceStore";

export interface AuthUser {
  user_id: string;
  name: string;
  email: string;
  status: string;
  organization?: string;
  settings?: Record<string, string>;
}

interface ListWarehousesResponse {
  warehouses: Warehouse[];
}

interface State {
  users: Async<AuthUser[]>;
}

export const useAdminStore = defineStore("admin", {
  state: (): State => ({
    users: asyncNotStarted(),
  }),
  actions: {
    async loadUsers() {
      this.users = asyncInProgress("Loading users");
      const response = await httpClient.get("/api/admin/users");
      this.users = asyncSucceeded(response.data.users);
    },
    async changePassword(
      userId: string,
      new_password: string
    ): Promise<{ success: boolean; error?: string }> {
      const params = { new_password };
      const response = await httpClient.patch<{ success: boolean; error?: string }>(
        `/api/admin/users/${userId}/password`,
        params
      );
      return response.data;
    },
    async setHomepage(userId: string, params: UserSettings) {
      await httpClient.patch(`/api/admin/users/${userId}/settings`, params);
    },
    async updateName(userId: string, name: string) {
      await httpClient.patch(`/api/admin/users/${userId}/name`, { name });
    },
    async listWarehouses(workspaceId: string) {
      try {
        const response = await httpClient.get(`/api/workspaces/${workspaceId}/warehouses`);
        const config: ListWarehousesResponse = response.data;
        return asyncSucceeded(config.warehouses);
      } catch (error: unknown) {
        return asyncFailed("Could not load warehouses");
      }
    },
    async setCurrentWarehouse(options: {
      userId: string;
      workspaceId: string;
      warehouseId: string;
    }) {
      await httpClient.patch(`/api/admin/users/${options.userId}/update-warehouse`, {
        workspace_id: options.workspaceId,
        warehouse_id: options.warehouseId,
      });
    },
    async resyncUser(userId: string): Promise<void> {
      await httpClient.post(`/api/admin/users/${userId}/resync`);
    },
  },
});
