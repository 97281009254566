import { environment } from "@/common/environments/environmentLoader";
import { defineStore } from "pinia";
import { RouteRecordNameGeneric } from "vue-router";
import { useUserStore } from "./userStore";

export enum SelectedTab {
  Connections = "connections",
  UserSettings = "user-settings",
  Preferences = "preferences",
  Admin = "admin",
  QuickBooks = "quickbooks",
}
export enum SubPage {
  Modules = "modules",
  UserSettings = "user-settings",
}

export enum Routes {
  Home = "home",
}

export type Route = Routes | SelectedTab;

interface State {
  selectedTab: SelectedTab;
  userSelected: boolean;
}

const canEditDatabricksConnection = environment.requireBoolean("EDIT_DATABRICKS_CONNECTION");
const showAdminPage = environment.requireBoolean("ADMIN_ENABLED");
const showQuickBookPage = environment.requireBoolean("QUICKBOOKS_ENABLED");

export const useSettingsStore = defineStore("settings", {
  state: (): State => ({
    selectedTab: SelectedTab.UserSettings,
    userSelected: false,
  }),
  getters: {
    subPage: (state) => subPage(state.userSelected),
    availableTabs: () => {
      const availableTabs = [{ label: "User settings", key: SelectedTab.UserSettings }];
      availableTabs.push({ label: "Preferences", key: SelectedTab.Preferences });

      if (canEditDatabricksConnection) {
        availableTabs.push({ label: "Connection", key: SelectedTab.Connections });
      }
      if (showAdminPage && useUserStore().user?.global_admin) {
        availableTabs.push({ label: "Admin", key: SelectedTab.Admin });
      }
      if (showQuickBookPage && useUserStore().user?.global_admin) {
        availableTabs.push({ label: "QuickBooks", key: SelectedTab.QuickBooks });
      }
      return availableTabs;
    },
  },
  actions: {
    handleRouteChange(routeName: RouteRecordNameGeneric) {
      switch (routeName) {
        case SelectedTab.Connections:
        case SelectedTab.UserSettings:
        case SelectedTab.Preferences:
        case SelectedTab.Admin:
        case SelectedTab.QuickBooks:
          this.userSelected = true;
          this.selectedTab = routeName;
          break;
        default:
          this.userSelected = false;
          break;
      }
    },
  },
});

function subPage(userSelected: boolean): SubPage {
  if (userSelected) {
    return SubPage.UserSettings;
  }
  return SubPage.Modules;
}
