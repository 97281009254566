import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import Select from "@/common/components/Select.vue";
import { PropertyValueGenerator } from "@/reader/lib/visualization";
import { Visualization, VisualizationType } from "@/reader/lib/visualizationTypes";
import { VISUALIZATION_CONFIG_DEFS } from "@/reader/lib/visualizationTypes";
import { fromPairs } from "lodash";
import { computed } from "vue";
import VisualizationColumnControl from "./VisualizationColumnControl.vue";
import { Query } from "@/common/lib/query";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";
import { Widget } from "@/reader/lib/page";
import { v4 as uuidv4 } from "uuid";
import { useExploreStore } from "@/reader/stores/explore";


export default /*@__PURE__*/_defineComponent({
  __name: 'VisualizationConfig',
  props: /*@__PURE__*/_mergeModels({
    query: { type: Object, required: true }
  }, {
    "type": { type: null, ...{ required: true } },
    "typeModifiers": {},
    "config": { type: null, ...{ required: true } },
    "configModifiers": {},
  }),
  emits: ["update:type", "update:config"],
  setup(__props: any, { expose: __expose }) {
  __expose();

const exploreStore = useExploreStore();

const type = _useModel<VisualizationType | undefined>(__props, "type");
const config = _useModel<Record<string, unknown> | undefined>(__props, "config");


const types = computed(() => Object.keys(VISUALIZATION_CONFIG_DEFS));
const controls = computed(() => configDef()?.controls ?? []);

function configDef() {
  if (type.value == null) return undefined;
  return VISUALIZATION_CONFIG_DEFS[type.value];
}

function typeName(vizType: string) {
  return VISUALIZATION_CONFIG_DEFS[vizType].label;
}

function prepareNewType(newType?: VisualizationType) {
  if (newType == null) {
    config.value = {};
  } else {
    const pairs = VISUALIZATION_CONFIG_DEFS[newType].controls.map((c) => [c.key, undefined]);
    config.value = fromPairs(pairs);
  }
}

function copyWidgetJSON() {
  const widget: Widget = {
    key: uuidv4(),
    top: 0,
    left: 0,
    width: 4,
    height: 4,
    visualization: {
      type: type.value!,
      title: "Explored Visualization",
      config: config.value!,
      query: exploreStore.query!,
    } as Visualization,
  };
  navigator.clipboard.writeText(JSON.stringify(widget, undefined, 2));
}

const __returned__ = { exploreStore, type, config, types, controls, configDef, typeName, prepareNewType, copyWidgetJSON, Select, VisualizationColumnControl, IconButtonV2 }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})