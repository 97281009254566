<template>
  <div
    class="group flex w-full flex-col items-start pt-5"
    :class="{ 'pb-5': errorText === undefined }"
  >
    <label
      v-if="label"
      :for="id"
      class="text-s pointer-events-none mb-5 flex items-start gap-5 font-semibold text-gray-500"
      :class="[
        requiredText,
        {
          'group-focus-within:text-orange': !disabled,
        },
      ]"
      >{{ label }}</label
    >
    <div v-if="type === 'search'" class="relative flex w-full gap-30">
      <Icon
        class="absolute left-8 top-[7px]"
        :class="{
          'svg-to-dark-gray': darkMode,
          'svg-to-medium-gray': !darkMode,
        }"
        name="search"
      ></Icon>
      <input
        class="flex h-[34px] w-full resize-none items-center gap-10 self-stretch rounded border border-solid py-10 pl-[38px] pr-20 text-base text-gray-800 caret-orange outline-0 focus:border-[1px] focus:border-orange focus:outline-none search-cancel:appearance-none dark:border-gray-700 dark:text-gray-200 dark:focus:border-orange"
        :class="{
          'border-gray-200 bg-gray-100 hover:border-gray-500 hover:bg-gray-100 focus:bg-gray-100 dark:border-gray-800 dark:bg-gray-900 dark:hover:border-black dark:hover:bg-black dark:focus:bg-gray-900':
            !disabled,
          'background-unset border-[1px] border-gray-300 dark:border-gray-700': disabled,
        }"
        v-model="value"
        :type="type"
        :disabled="disabled"
        :autocomplete="autocomplete"
        helper-text="hmm okay"
        :required="required"
        :id="id"
        ref="el"
      />
    </div>
    <input
      v-else-if="!textarea"
      v-model="value"
      required
      class="flex h-[34px] resize-none items-center gap-10 self-stretch rounded border-[1px] border-solid bg-gray-200 py-10 pl-10 pr-20 caret-orange focus:border-orange focus:outline-none dark:bg-gray-800"
      :class="{
        'border-gray-500 text-gray-900 hover:border-gray-500 hover:bg-gray-100 focus:bg-gray-100 dark:border-gray-700 dark:text-gray-100 dark:hover:bg-gray-900 dark:focus:bg-gray-900':
          !disabled,
        'border-gray-300 text-gray-300 dark:border-gray-700 dark:text-gray-700': disabled,
      }"
      :style="{ 'color-scheme': darkMode ? 'dark' : 'light' }"
      :type="type"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :autofocus="true"
      :id="id"
      data-test="text-input"
      ref="el"
      @focus="emit('focus', $event)"
      @blur="emit('blur', $event)"
      @keyup.enter="emit('keyup.enter', $event)"
    />
    <textarea
      v-else
      v-model="value"
      required
      class="flex h-[40px] max-h-[140px] resize-none items-center gap-10 self-stretch rounded border-[1px] border-solid bg-gray-200 py-10 pl-10 pr-20 caret-orange focus:border-orange focus:outline-none dark:bg-gray-800"
      :class="{
        'dark:focus:gray-900 border-gray-300 text-gray-900 hover:border-gray-500 hover:bg-gray-100 focus:bg-gray-100 dark:border-gray-700 dark:text-gray-100 dark:hover:bg-gray-900 dark:focus:bg-gray-900':
          !disabled,
        'border-gray-300 text-gray-300 dark:border-gray-700 dark:text-gray-700': disabled,
      }"
      :type="type"
      :disabled="disabled"
      :id="id"
      ref="el"
      :style="{ 'min-height': minHeight, 'max-height': maxHeight }"
    ></textarea>
    <div class="pt-5 text-xs text-gray-500" v-if="helperText && !errorText">
      {{ helperText }}
    </div>
    <div class="flex w-full items-start gap-10 p-5 text-xs text-red-300" v-if="errorText">
      {{ errorText }}
    </div>
    <div v-if="errorText === '' && !helperText" class="min-h-[22px]"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch, Ref, inject } from "vue";
import Icon from "./Icon.vue";
import { DarkMode } from "../lib/keys";
const props = defineProps<{
  label?: string;
  helperText?: string;
  errorText?: string;
  modelValue?: string | number;
  type?: string;
  disabled?: boolean;
  textarea?: boolean;
  minHeight?: string;
  maxHeight?: string;
  autocomplete?: string;
  autoselect?: boolean;
  required?: boolean;
  id?: string;
}>();
const emit = defineEmits(["update:modelValue", "blur", "keyup.enter", "focus"]);
const el = ref<HTMLTextAreaElement | undefined>(undefined);
const darkMode = inject(DarkMode) as Ref<boolean>;

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

if (props.textarea) {
  watch([value, el], () => {
    if (!el.value) {
      return;
    }
    // Make tiny to force shrinking when text is deleted
    el.value.style["height"] = "1px";
    const height = `${el.value.scrollHeight}px`;
    el.value.style["height"] = height;
  });
}

function focus() {
  el.value?.focus();
  el.value?.select();
}

defineExpose({
  focus,
});

onMounted(() => {
  if (props.autoselect && el.value) {
    focus();
  }
});

const requiredText = computed(() => {
  if (props.required) {
    return 'after:content-["*"]';
  } else {
    return "";
  }
});
</script>
