import { defineComponent as _defineComponent } from 'vue'
import { storeToRefs } from "pinia";
import { useAdminStore } from "../../stores/adminStore";
import { AsyncStatus, asyncValue } from "../../lib/async";
import { computed, onMounted, ref } from "vue";
import AdminSetting from "./AdminSetting.vue";
import { isNil, toString } from "lodash";
import Dialog from "../Dialog.vue";
import MessageBar from "../MessageBar.vue";
import AdminWarehouseChooser from "./AdminWarehouseChooser.vue";
import Spinner from "../Spinner.vue";
import TextButton from "../TextButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminPage',
  setup(__props, { expose: __expose }) {
  __expose();

const adminStore = useAdminStore();
const { users } = storeToRefs(adminStore);
const loading = ref(false);

const currentUserId = ref<string | undefined>();
const currentUser = computed(() => {
  const currentUsers = asyncValue(users.value);
  if (isNil(currentUsers)) {
    return undefined;
  }
  return currentUsers.find((u) => u.user_id === currentUserId.value);
});

const errorMessage = ref("");

async function setHomepage(userId: string, homepage: string) {
  await doUpdate(() => adminStore.setHomepage(userId, { default_homepage: homepage }));
}

async function changePassword(userId: string, password: string) {
  await doUpdate(async () => {
    const result = await adminStore.changePassword(userId, password);
    if (result.error) {
      errorMessage.value = result.error;
    }
  });
}

async function updateName(userId: string, name: string) {
  await doUpdate(() => adminStore.updateName(userId, name));
}

async function resync(userId: string) {
  await doUpdate(() => adminStore.resyncUser(userId));
}

async function doUpdate(action: () => Promise<unknown>) {
  loading.value = true;
  errorMessage.value = "";
  try {
    await action();
    await adminStore.loadUsers();
  } finally {
    loading.value = false;
  }
}

onMounted(() => adminStore.loadUsers());

const __returned__ = { adminStore, users, loading, currentUserId, currentUser, errorMessage, setHomepage, changePassword, updateName, resync, doUpdate, get AsyncStatus() { return AsyncStatus }, AdminSetting, get toString() { return toString }, Dialog, MessageBar, AdminWarehouseChooser, Spinner, TextButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})