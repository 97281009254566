import { defineComponent as _defineComponent } from 'vue'
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Menu from "@/common/components/Menu.vue";
import { asyncValue } from "@/common/lib/async";
import { Dropdown } from "floating-vue";
import { computed, toRefs } from "vue";
import { propertyValueMenu } from "../../lib/exploreMenus";
import { useExploreStore } from "../../stores/explore";
import MiniBar from "../MiniBar.vue";
import { last } from "lodash";
import { conceptPropertyValueDisplay, uniquelyIdentifiesConcept } from "../../lib/concept";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { FloatValue, toNative } from "@/common/lib/value";
import { columnIsDerived, QueryColumn } from "@/common/lib/query";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";


export default /*@__PURE__*/_defineComponent({
  __name: 'PropertyValue',
  props: {
    value: { type: Object, required: true },
    column: { type: Object, required: false },
    quantitative: { type: Boolean, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { column, value } = toRefs(props);

const exploreStore = useExploreStore();

function conceptType() {
  if (column.value == null) return null;
  return column.value.path == null
    ? exploreStore.query!.root_concept_type
    : last(column.value.path)!.concept_type;
}

const menuItems = computed(() =>
  propertyValueMenu(value.value.originalValue, value.value.formattedValue, column?.value)
);

const hasBarChart = computed(
  () => column?.value != null && asyncValue(exploreStore.columnStats)![column.value.alias] != null
);

const hasConceptPage = computed(function () {
  if (column.value == null) return false;
  if (columnIsDerived(column.value)) return false;
  return uniquelyIdentifiesConcept(
    conceptType()!,
    column.value.property_type as PropertyKnowledgeRef
  );
});

const max = computed(() => {
  const stats = asyncValue(exploreStore.columnStats)![column.value?.alias ?? 0];
  return toNative(stats.max) as number;
});

const color = computed(function () {
  if (column.value == null || columnIsDerived(column.value)) return null;
  const ctype = conceptType();
  if (ctype == null) return null;
  const display = conceptPropertyValueDisplay(
    ctype,
    column.value.property_type as PropertyKnowledgeRef,
    value.value.originalValue
  );
  return display?.color ?? null;
});

const __returned__ = { props, column, value, exploreStore, conceptType, menuItems, hasBarChart, hasConceptPage, max, color, GraphValueComponent, Menu, get Dropdown() { return Dropdown }, MiniBar }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})