<template>
  <div class="flex flex-col items-start gap-15" v-if="user">
    <div class="text-xl">Name</div>
    <div>{{ user.name }}</div>
    <div><TextButton label="Update name" @click="editUserName" /></div>
  </div>
  <div class="flex flex-col items-start gap-15" v-if="user">
    <div class="text-xl">Email</div>
    <div class="flex flex-col gap-5">
      <span>{{ user.email }}</span
      ><span v-if="user.email_verified" class="text-500 italic">verified</span>
    </div>
    <TextButton label="Resync" @click="resync" />
  </div>
  <div class="flex flex-col items-start gap-15" v-if="user">
    <div class="text-xl">Password</div>
    <div>
      <TextButton
        label="Change password"
        @click="editingChangePassword = { newPassword: '', confirmNewPassword: '' }"
      />
    </div>
  </div>
  <div class="flex flex-col items-start gap-15">
    <div class="text-xl">Session</div>
    <TextButton label="Logout" @click="logout" />
  </div>

  <Dialog
    v-if="editingUserName !== undefined"
    title="Update name"
    success-label="Update"
    @succeeded="finishEditUserName"
    @cancelled="editingUserName = undefined"
  >
    <Textbox label="Name" v-model="editingUserName" :autoselect="true" />
  </Dialog>

  <Dialog
    v-if="editingChangePassword !== undefined"
    title="Change password"
    success-label="Update"
    @succeeded="finishChangePassword"
    @cancelled="editingChangePassword = undefined"
  >
    <Textbox
      label="New password"
      v-model="editingChangePassword.newPassword"
      :autoselect="true"
      autocomplete="new-password"
    />
    <MessageBar mode="error" v-if="editingChangePassword.error">
      <div>{{ editingChangePassword.error }}</div>
    </MessageBar>
  </Dialog>
</template>

<script lang="ts" setup>
import { useAuthProvider } from "@/common/auth/authStore";
import Dialog from "@/common/components/Dialog.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import MessageBar from "./MessageBar.vue";

const auth = useAuthProvider();
const editingUserName = ref<string | undefined>();
const editingChangePassword = ref<ChangePassword | undefined>();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

async function resync() {
  await userStore.resyncUser();
}

function logout() {
  auth.logout(window.location.origin + environment.require("AUTH0_LOGOUT_PATH"));
}

function editUserName() {
  editingUserName.value = user?.value?.name;
}

async function finishEditUserName() {
  if (!editingUserName.value) {
    return;
  }
  const success = await userStore.updateUserName(editingUserName.value);
  if (!success) {
    return;
  }
  editingUserName.value = undefined;
}

interface ChangePassword {
  newPassword: string;
  confirmNewPassword: string;
  error?: string;
}

async function finishChangePassword() {
  if (!editingChangePassword.value) {
    return;
  }
  editingChangePassword.value.error = undefined;
  const result = await userStore.updateUserPassword(editingChangePassword.value.newPassword);
  if (result.error) {
    editingChangePassword.value.error = result.error;
    return;
  }
  editingChangePassword.value = undefined;
}
</script>
