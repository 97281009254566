import { defineComponent as _defineComponent } from 'vue'
import { Subtable as SubtableType, Column } from "../Table.vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Icon from "@/common/components/Icon.vue";
import { toggle } from "@/common/lib/set";
import { computed, ref, Ref, toRefs } from "vue";
import MiniBar from "../../MiniBar.vue";
import { toNative, isNumeric, FloatValue } from "@/common/lib/value";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { isNil } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'Subtable',
  props: {
    subtable: { type: Object, required: true },
    columns: { type: Array, required: true },
    depth: { type: Number, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { depth } = toRefs(props);

const collapsed: Ref<Set<string>> = ref(new Set());

const indentStyle = computed(() => ({ marginLeft: `${20 * depth.value}px` }));

const maxValueForColumn = (colIndex: number) => {
  const validValues: number[] = (props.subtable.rows ?? [])
    .map((row) => row[colIndex]?.originalValue)
    .filter((originalValue) => !isNil(originalValue) && isNumeric(originalValue))
    .map((originalValue) => toNative(originalValue));
  return Math.max(...validValues, -Infinity);
};
function showMinibar(col: Column, row: ValueWithFormattedValue) {
  return isNumeric(row.originalValue) && !col.hideBars;
}

const __returned__ = { props, depth, collapsed, indentStyle, maxValueForColumn, showMinibar, GraphValueComponent, Icon, get toggle() { return toggle }, MiniBar }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})