// Types and code related to the UI that lets users configure their own visualizations.

import { COMPOSITE_PROPERTY_VALUE_TYPE } from "@/common/lib/knowledge";
import { findDeepColumnByAlias, Query } from "@/common/lib/query";
import { GraphValueType } from "@/common/lib/value";
import { cloneDeep, isString } from "lodash";
import { PropertyValueGenerator, ValueGeneratorType } from "./visualization";

export interface VisualizationTypeDef {
  label: string;
  controls: VisualizationConfigControl[];
}

export enum VisualizationConfigControlType {
  Column = "column",
}

interface BaseVisualizationConfigControl {
  type: VisualizationConfigControlType;
  key: string; // This matches with the config key in the viz's interface in visualization.ts
  label: string;
  config: Record<string, unknown>;
  optional?: boolean; // Defaults to false (an entry is required)
  description?: string;
}

// Lets a user choose one of their query columns as input to the visualization
export interface ColumnVisualizationConfigControl extends BaseVisualizationConfigControl {
  type: VisualizationConfigControlType.Column;
  config: {
    limit_value_types?: (GraphValueType | typeof COMPOSITE_PROPERTY_VALUE_TYPE)[];
  };
}

type VisualizationConfigControl = ColumnVisualizationConfigControl;

// Removes keys from visualization config that don't pass a reference check
// against the provided query
export function scrubVisualizationConfig(
  config: Record<string, unknown>,
  configDef: VisualizationTypeDef,
  query: Query
) {
  config = cloneDeep(config);
  for (const control of configDef.controls) {
    if (config[control.key] != null && control.type === VisualizationConfigControlType.Column) {
      const value = config[control.key] as PropertyValueGenerator;
      if (findDeepColumnByAlias(query, value.alias) == null) delete config[control.key];
    }
  }
  return config;
}

// There are some conveniences we allow in visualization configs for the sake of
// poor users writing JSON manually, but they will confuse the config views. This
// function converts them to their canonical forms
export function canonicalizeVisualizationConfig(
  config: Record<string, unknown>,
  configDef: VisualizationTypeDef
) {
  config = cloneDeep(config);
  for (const control of configDef.controls) {
    if (isString(config[control.key]) && control.type === VisualizationConfigControlType.Column) {
      config[control.key] = {
        type: ValueGeneratorType.Property,
        alias: config[control.key],
      };
    }
  }
  return config;
}
