import { defineComponent as _defineComponent } from 'vue'
import TabSelect from "@/common/components/TabSelect.vue";
import { SelectedTab, useSettingsStore } from "@/common/stores/settingsStore";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { onMounted, watch } from "vue";
import ConnectionSettings from "@/common/components/settings/ConnectionSettings.vue";
import { useRouter } from "vue-router";
import AdminPage from "@/common/components/admin/AdminPage.vue";
import UserPreferences from "./UserPreferences.vue";
import UserSettings from "./UserSettings.vue";
import QuickBooksTab from "./QuickBooksTab.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserPage',
  setup(__props, { expose: __expose }) {
  __expose();

const userStore = useUserStore();
const router = useRouter();

const { availableTabs, selectedTab } = storeToRefs(useSettingsStore());

watch(selectedTab, (newValue) => {
  router.replace({ name: newValue });
});
onMounted(() => {
  userStore.reloadUser();
});

const __returned__ = { userStore, router, availableTabs, selectedTab, TabSelect, get SelectedTab() { return SelectedTab }, ConnectionSettings, AdminPage, UserPreferences, UserSettings, QuickBooksTab }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})