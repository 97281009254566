import { httpClient as axios } from "@/common/http/http";
import { Async, asyncFailed, AsyncStatus } from "@/common/lib/async";
import { FailureType } from "@/common/lib/failure";
import { CTMap } from "@/common/lib/map";
import { AxiosResponse } from "axios";
import { orderBy } from "lodash";
import { DateTime } from "luxon";
import { defineStore } from "pinia";
import { Module, ModuleResponse } from "../lib/api";
import { asyncInProgress, asyncNotStarted, asyncSucceeded } from "../lib/async";
import { Query } from "../lib/query";
import { useFailureStore } from "./failureStore";

export type ModuleMetadata = {
  id: string;
  moduleType: ModuleType;
  name: string;
  updated: DateTime;
  thumbnail: string;
  thumbnail_url?: string;
  owned?: boolean;
  owner_name?: string;
  knowledge_version: string;
  databricks_host?: string;
  module_version: number;
  created: DateTime;
  description?: string;
  published_reader_view: boolean;
  bookmarks?: Bookmark[];
  reader_pages?: ReaderPage[];
  tag?: string;

  // View-only
  selected?: boolean;
};

export type Bookmark = {
  id: string;
  metadata: {
    name: string;
    description?: string;
    updated: DateTime;
    created: DateTime;
  };
  state: Query;
};

export type ReaderPage = {
  id: string;
  title: string;
};

interface State {
  currentModuleType: ModuleType | undefined;
  modules: Async<ModuleMetadata[]>;
  bookmarks: Async<Bookmark[]>;
  workspaceId?: string;
}

interface UserBookmark {
  id: string;
  name: string;
  description?: string;
  state: Query;
}
type CreateUserBookmark = Omit<UserBookmark, "id">;
type UpdateUserBookmark = Omit<UserBookmark, "state">;

export enum ModuleType {
  User = "user", // regular user module
  Published = "published", // a published module
  Local = "local", // a locally exported module
  Reader = "reader", // a reader module
}

export function moduleHasType(module: ModuleMetadata, ...type: ModuleType[]): boolean {
  return type.includes(module.moduleType);
}

export const useUserModuleStore = defineStore("home-user-module", {
  state: (): State => ({
    currentModuleType: undefined,
    modules: asyncNotStarted(),
    bookmarks: asyncNotStarted(),
    workspaceId: undefined,
  }),
  getters: {},
  actions: {
    setModuleType(moduleType: ModuleType): Promise<void> {
      if (moduleType === this.currentModuleType && this.modules.status === AsyncStatus.Succeeded) {
        return Promise.resolve();
      }
      this.currentModuleType = moduleType;
      switch (this.currentModuleType) {
        case ModuleType.User:
          return this.loadProjects();
        case ModuleType.Published:
          return this.loadPublished();
        case ModuleType.Local:
          return this.loadLocal();
        case ModuleType.Reader:
          return this.loadReader();
      }
    },
    boot(workspaceId: string, moduleType: ModuleType) {
      this.workspaceId = workspaceId;
      return this.setModuleType(moduleType);
    },
    setStatus(status: Async<ModuleMetadata[]>) {
      this.modules = status;
    },
    async loadProjects() {
      if (!this.workspaceId) {
        return;
      }
      this.modules = asyncInProgress("Loading projects…");
      this.modules = await fetchSorted("/api/projects", ModuleType.User, "projects", {
        workspace_id: this.workspaceId,
      });
    },
    async newModule() {
      const params = { workspace_id: this.workspaceId };
      const createResponse = await axios.post("/api/projects", undefined, { params });
      return createResponse.data.id;
    },
    loadModuleAxios(moduleId: string): Promise<AxiosResponse<ModuleResponse>> {
      return axios.get<ModuleResponse>(`/api/projects/${moduleId}`);
    },
    async loadModule(moduleId: string): Promise<ModuleResponse> {
      const module = await this.loadModuleAxios(moduleId);
      return module.data;
    },
    async updateModule(
      moduleId: string,
      params: {
        name?: string;
        description?: string;
        map?: CTMap;
        thumbnail?: Blob;
        dependencies?: string[];
        published_reader_view?: boolean;
        app_state?: Module["app_state"];
      }
    ) {
      return await axios.patch(`/api/projects/${moduleId}`, params);
    },
    async renameModule(
      moduleId: string,
      oldModuleName: string,
      newModuleName: string
    ): Promise<string | undefined> {
      if (oldModuleName === newModuleName) {
        // Do not rename to same name.
        return;
      }
      if (newModuleName.trim() === "") {
        // Do not rename to empty string
        return;
      }
      const params = {
        name: newModuleName,
      };
      const response = await this.updateModule(moduleId, params);
      const newName = response.data["name"];
      if (newName && this.modules.status === AsyncStatus.Succeeded) {
        const module = this.modules.result.find((mod) => mod.id === moduleId);
        if (module) {
          module.name = newName;
        }
      }
      return newName;
    },
    async duplicateModule(moduleId: string, newModuleName: string) {
      if (this.workspaceId == null) {
        return;
      }
      const oldModules = this.modules;
      this.modules = asyncInProgress("Duplicating project…");
      const params = { module_name: newModuleName };
      try {
        await axios.post(`/api/projects/${moduleId}/duplicate`, params);
        await this.loadProjects();
      } catch (error: unknown) {
        useFailureStore().backendFail({
          type: FailureType.Api,
          description: "Failed to duplicate project",
          error,
        });
        this.modules = oldModules;
      }
    },
    async deleteModule(moduleId: string) {
      if (this.workspaceId == null) {
        return;
      }
      const oldModules = this.modules;
      this.modules = asyncInProgress("Deleting project…");
      try {
        await axios.delete(`/api/projects/${moduleId}`);
        await this.loadProjects();
      } catch (error: unknown) {
        useFailureStore().backendFail({
          type: FailureType.Api,
          description: "Failed to delete project",
          error,
        });
        this.modules = oldModules;
      }
    },
    async loadPublished() {
      if (!this.workspaceId) {
        return;
      }
      this.modules = asyncInProgress("Loading published projects…");
      this.modules = await fetchSorted(
        "/api/published-projects",
        ModuleType.Published,
        "published projects",
        {
          workspace_id: this.workspaceId,
        }
      );
    },
    async duplicatePublishedModule(moduleId: string): Promise<string> {
      const response = await axios.post(`/api/published-projects/${moduleId}`);
      return response.data.id;
    },
    async deletePublishedModule(moduleId: string) {
      return await axios.delete(`/api/published-projects/${moduleId}`);
    },
    async publishModule(moduleId: string) {
      await axios.post(`/api/projects/${moduleId}/publish`);
    },
    async loadLocal() {
      if (!this.workspaceId) {
        return;
      }
      this.modules = asyncInProgress("Loading exported projects…");
      this.modules = await fetchSorted(
        "/api/local-projects",
        ModuleType.Local,
        "exported projects",
        {
          workspace_id: this.workspaceId,
        }
      );
    },
    async duplicateLocalModule(moduleId: string): Promise<string> {
      const response = await axios.post(`/api/local-projects/${moduleId}`);
      return response.data.id;
    },
    async publishLocalModule(moduleId: string) {
      await axios.post(`/api/projects/${moduleId}/publish-local`);
    },
    async publishReaderView(moduleId: string, isPublished: boolean) {
      const params = {
        published_reader_view: !isPublished,
      };
      await this.updateModule(moduleId, params);
    },
    async unshareReaderView(moduleId: string) {
      const params = {
        workspace_id: this.workspaceId!,
      };
      await axios.post(`/api/projects/${moduleId}/unshare_reader_view`, params);
      await this.loadReader();
    },
    async loadReader() {
      if (!this.workspaceId) {
        return;
      }
      this.modules = asyncInProgress("Loading Reader projects…");
      this.modules = await fetchSorted("/api/projects", ModuleType.Reader, "reader modules", {
        mode: "reader",
        workspace_id: this.workspaceId,
      });
    },
    async newBookmark(moduleId: string, params: CreateUserBookmark) {
      const response = await axios.post(`/api/projects/${moduleId}/bookmarks`, params);
      return response.data.id;
    },
    async loadProjectBookmarks(moduleId: string) {
      this.bookmarks = asyncInProgress("Loading bookmarks…");
      this.bookmarks = await fetchBookmarks(`/api/projects/${moduleId}/bookmarks`);
    },
    async getBookmark(moduleId: string, bookmarkId: string) {
      const response = await axios.get(`/api/projects/${moduleId}/bookmarks/${bookmarkId}`);
      return response.data;
    },
    async deleteBookmark(moduleId: string, bookmarkId: string) {
      const response = await axios.delete(`/api/projects/${moduleId}/bookmarks/${bookmarkId}`);
      return response.data.id;
    },
    async updateBookmark(moduleId: string, bookmarkId: string, params: UpdateUserBookmark) {
      const response = await axios.patch(
        `/api/projects/${moduleId}/bookmarks/${bookmarkId}`,
        params
      );
      return response.data.id;
    },
    async lookupTag(tagName: string): Promise<string | undefined> {
      const response = await axios.get(`/api/tags/${tagName}`);
      return response.data.module_id;
    },
    async tagModule(tagName: string, moduleId: string) {
      await axios.post(`/api/projects/${moduleId}/tag/${tagName}`);
    },
  },
});

function parseModuleType(moduleType: ModuleType) {
  return (module: ModuleMetadata & { updated: string; created: string }) =>
    parseModule(moduleType, module);
}

function parseModule(
  moduleType: ModuleType,
  module: ModuleMetadata & { updated: string; created: string }
): ModuleMetadata {
  return Object.assign(module, {
    moduleType,
    updated: DateTime.fromISO(module.updated),
    created: DateTime.fromISO(module.created),
  });
}

async function fetchSorted(
  url: string,
  moduleType: ModuleType,
  label: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any
) {
  try {
    const modulesResponse = await axios.get(url, { params });
    return asyncSucceeded(
      orderBy(
        modulesResponse.data.modules.map(parseModuleType(moduleType)),
        (m) => m.updated,
        "desc"
      )
    );
  } catch (error: unknown) {
    return asyncFailed(`Failed to load ${label}`);
  }
}

async function fetchBookmarks(url: string) {
  try {
    const bookmarksResponse = await axios.get(url);
    return asyncSucceeded(
      orderBy(bookmarksResponse.data.bookmarks, (m) => m.metadata.created, "desc")
    );
  } catch (error: unknown) {
    return asyncFailed(`Failed to load`);
  }
}
