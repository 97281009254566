<template>
  <div class="absolute inset-0 z-0 overflow-auto" data-test="table">
    <table class="z-10 border-collapse">
      <thead>
        <tr>
          <th class="sticky top-0 z-10 bg-gray-200 p-0">
            <div class="flex h-[25px] border-b border-r border-white px-10"></div>
          </th>
          <template v-for="(colName, index) in columns" :key="index">
            <th class="sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline">
              <div
                class="flex h-[25px] items-center justify-end whitespace-nowrap border-b border-r border-white px-10 text-right"
              >
                <div class="mx-auto">{{ colName }}</div>
              </div>
            </th>
            <th v-if="index > 0" class="sticky top-0 z-10 bg-gray-200 p-0">
              <div class="flex h-[25px] border-b border-r border-white px-10"></div>
            </th>
          </template>
          <template v-for="(col, index) in trailingColumns" :key="index">
            <th
              class="sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline"
              :class="{ 'border-l-[8px] border-solid border-l-finance-highlight': index === 0 }"
            >
              <div
                class="flex h-[25px] items-center justify-end whitespace-nowrap border-b border-r border-white px-10 text-right"
              >
                <div class="mx-auto">{{ col.name }}</div>
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <FinancialSubtable
          :rows="table"
          :columns="columns"
          :trailingColumns="trailingColumns.map((col) => col.name)"
          :depth="0"
          :expanded-map="expandedMap"
          @update-expanded="updateExpanded"
        />
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
%column-styles {
  @apply bg-finance-header_hover;
}
@for $i from 2 to 30 {
  table:has(tr > *:nth-child(#{$i}):hover) tr > *:nth-child(#{$i}) {
    @extend %column-styles;
  }
}
</style>

<script lang="ts" setup>
import { generateValue } from "@/reader/lib/visualization";
import {
  FinancialStatementRow,
  FinancialStatementVisualization,
} from "@/reader/lib/visualizationTypes";
import { computed, onMounted, ref, toRefs } from "vue";
import {
  GraphValue,
  GraphValueType,
  isValue,
  stringifyValue,
  toNative,
  toValue,
} from "@/common/lib/value";
import FinancialSubtable from "./financial-statement/FinancialSubtable.vue";
import { TRANSFORMERS, ValueWithFormattedValue } from "@/common/lib/format";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { last, min, range } from "lodash";
import { GraphCompoundValue } from "@/common/lib/graph";
import { DateTime } from "luxon";
import { toggle } from "@/common/lib/set";
import { getCookie, setCookie } from "@/common/lib/cookie";

const props = defineProps<{
  visualization: FinancialStatementVisualization;
  results: UseQueryResult[];
  width: number;
  height: number;
}>();

defineEmits<{ select: [alias: string, value: GraphValue | null] }>();

const { visualization, results } = toRefs(props);
const trailingMonths = computed(function () {
  return visualization.value.config.trailing_months ?? [];
});
const ytdTrailingMonths = computed(function () {
  const numCol = results.value.length;
  const value = columnValues.value[numCol - 1];
  const date = toNative(value?.originalValue);
  const month = date.month;
  return min([month, numCol]);
});

const columnValues = computed(function () {
  const query = visualization.value.query;
  const gen = visualization.value.config.columns;
  return results.value.map(function (period) {
    return generateValue(gen, period, query);
  });
});

const expandedMap = ref(new Set<string>());

function updateExpanded(rowLabel: string) {
  toggle(expandedMap.value, rowLabel);
  setCookie("financial-statement-selection", JSON.stringify(Array.from(expandedMap.value)), 600);
}

export interface FinComputedCell {
  value?: ValueWithFormattedValue;
  overComparisonValue?: ValueWithFormattedValue;
  overPreviousPeriod?: ValueWithFormattedValue;
  asset: number;
}

export interface FinComputedRow {
  label: string;
  cells: FinComputedCell[];
  contents?: FinComputedRow[];
  highlight: boolean;
}

const columns = computed(function () {
  return columnValues.value.map(function (value) {
    return value ? stringifyValue(value.formattedValue) : "???";
  });
});

const trailingColumns = computed(function () {
  const trailingColumnNames = trailingMonths.value
    .filter((month) => isTrailingAvailable(month))
    .map((month) => {
      return { name: `T${month}M`, months: month };
    });
  if (showYTD()) {
    return [{ name: "YTD", months: ytdTrailingMonths.value }, ...trailingColumnNames];
  }
  return trailingColumnNames;
});

function showYTD() {
  return visualization.value.config.ytd && isTrailingAvailable(ytdTrailingMonths.value);
}

function areConsecutiveMonths(dates: DateTime[]) {
  dates.sort((a, b) => a.year - b.year || a.month - b.month);

  for (let i = 1; i < dates.length; i++) {
    const prev = dates[i - 1];
    const current = dates[i];

    const monthDifference = (current.year - prev.year) * 12 + (current.month - prev.month);
    if (monthDifference !== 1) {
      return false;
    }
  }
  return true;
}

function isTrailingAvailable(months: number): boolean {
  if (columnValues.value.length < months) {
    return false;
  }
  const dates = columnValues.value.slice(-months).map(function (value) {
    return toNative(value?.originalValue);
  });
  return areConsecutiveMonths(dates);
}

function buildSubtable(rowDefs: FinancialStatementRow[]): FinComputedRow[] {
  return rowDefs.map((rowDef) => {
    const cells = buildCells(rowDef);
    const query = visualization.value.query;
    const alias = rowDef.value.alias || rowDef.value;

    const additionalSums = trailingColumns.value.map((col) => {
      const sum = cells.slice(-col.months).reduce((total, cell) => {
        const originalValue = cell.value?.originalValue?.value;
        const numericValue = Number(originalValue) || 0;
        return total + numericValue;
      }, 0);

      const result = {
        valuesByAlias: {
          [alias]: [{ _type: GraphValueType.Float, value: sum.toString() }],
        },
      } as UseQueryResult;
      const generatedValue = generateValue(rowDef.value, result, query) ?? undefined;

      return { value: generatedValue };
    });

    const extendedCells = [...cells, ...additionalSums];

    return {
      label: rowDef.label,
      cells: extendedCells,
      contents: rowDef.contents == null ? undefined : buildSubtable(rowDef.contents),
      highlight: rowDef.highlight ?? false,
    };
  });
}

function buildCells(rowDef: FinancialStatementRow): FinComputedCell[] {
  const query = visualization.value.query;
  const cells: FinComputedCell[] = [];
  for (const index of range(results.value.length)) {
    const period = results.value[index];
    const value = generateValue(rowDef.value, period, query) ?? undefined;
    cells.push({
      value,
      overPreviousPeriod: compareValues(
        last(cells)?.value?.originalValue,
        value?.originalValue,
        true
      ),
      asset: rowDef.asset === true ? 1 : -1,
    });
  }
  return cells;
}

function compareValues(
  val1: GraphValue | GraphCompoundValue | undefined,
  val2: GraphValue | GraphCompoundValue | undefined,
  isChange: boolean
): ValueWithFormattedValue | undefined {
  if (!isValue(val1) || !isValue(val2)) return undefined;
  const num1 = Number(val1?.value) ?? 0;
  const num2 = Number(val2?.value) ?? 0;
  let ratio;
  if (isChange) {
    if (num1 <= 0 || num2 < 0) return undefined;
    ratio = (num2 - num1) / num1;
  } else {
    if (num2 === 0) return undefined;
    ratio = num1 / num2;
  }
  return {
    originalValue: toValue(ratio),
    formattedValue: TRANSFORMERS[isChange ? "percentChange" : "percent"](toValue(ratio)),
  };
}

const table = computed(() => buildSubtable(visualization.value.config.rows));

onMounted(() => {
  const jsonValue = getCookie("financial-statement-selection");
  if (jsonValue) {
    const value = JSON.parse(jsonValue);
    expandedMap.value = new Set(value);
  }
});
</script>
