import ExcelJS from "exceljs";
import { isNil } from "lodash";

export enum ExportType {
  Excel = "excel",
  CSV = "csv",
}

export async function excelExport(
  data: unknown[][],
  options: {
    filename: string;
    worksheetTitle: string;
    exportType: ExportType;
    columns?: Partial<ExcelJS.Column>[];
    author?: string;
  }
) {
  const workbook = new ExcelJS.Workbook();

  if (!isNil(options.author)) {
    workbook.creator = options.author;
    workbook.lastModifiedBy = options.author;
  }
  workbook.created = new Date();
  workbook.modified = new Date();

  const worksheet = workbook.addWorksheet(options.worksheetTitle);

  if (!isNil(options.columns)) {
    worksheet.columns = options.columns;
  }

  for (const row of data) {
    worksheet.addRow(row);
  }

  // Make first row bold and sticky
  worksheet.getRow(1).font = { bold: true };
  worksheet.views.push({ state: "frozen", ySplit: 1 });

  let filename = options.filename;
  let buffer: ExcelJS.Buffer;
  switch (options.exportType) {
    case ExportType.Excel:
      buffer = await workbook.xlsx.writeBuffer();
      filename += ".xlsx";
      break;
    case ExportType.CSV:
      buffer = await workbook.csv.writeBuffer({ sheetName: options.worksheetTitle });
      filename += ".csv";
      break;
  }
  downloadFile(buffer, { filename });
}

/**
 * Trigger downloading a file.
 */
export function downloadFile(blob: BlobPart, options: { filename: string; type?: string }) {
  // Create a document link that we'll programmatically 'click'.
  const link = document.createElement("a");

  // Associate the response data to the URL of the link
  link.href = window.URL.createObjectURL(new Blob([blob], { type: options.type }));

  // Tell the browser to download, not render, the file
  link.setAttribute("download", options.filename);

  // Place the link in the DOM
  document.body.appendChild(link);

  link.click();

  URL.revokeObjectURL(link.href);
}
