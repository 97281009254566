<template>
  <Select
    :label="control.label"
    :options="columns"
    v-model="model"
    :option-label="columnLabel"
    :required="!control.optional"
    :helper-text="control.description"
    placeholder="Select a column"
  />
</template>

<script lang="ts" setup>
import { PropertyValueGenerator, ValueGeneratorType } from "@/reader/lib/visualization";
import Select from "@/common/components/Select.vue";
import { ColumnVisualizationConfigControl } from "@/reader/lib/visualizationConfig";
import { columnName, findDeepColumnByAlias, Query } from "@/common/lib/query";
import { computed, Ref, toRefs } from "vue";
import { propertyValueType } from "@/common/lib/derived";

const props = defineProps<{ control: ColumnVisualizationConfigControl; query: Query }>();
const { query, control } = toRefs(props);

const model = defineModel<PropertyValueGenerator | undefined>({ required: true });

const columns: Ref<PropertyValueGenerator[]> = computed(function () {
  let columns = query.value.columns;
  const limit = control.value.config.limit_value_types;
  if (limit != null) {
    columns = columns.filter((col) => limit.includes(propertyValueType(col.property_type)));
  }
  return columns.map((c) => ({
    type: ValueGeneratorType.Property,
    alias: c.alias,
  }));
});

function columnLabel(pvg: PropertyValueGenerator) {
  return columnName(findDeepColumnByAlias(query.value, pvg.alias)!);
}
</script>
