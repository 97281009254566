<template>
  <TextButton label="Connect to QuickBooks" @click="connectToQuickBooks()" />
  <h1>Connections</h1>
  <table>
    <thead>
      <tr>
        <td>Company</td>
        <td>Realm ID</td>
        <td>Status</td>
        <td>Actions</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="connection in connections" :key="connection.realm_id">
        <td>{{ connection.company_name }}</td>
        <td>{{ connection.realm_id }}</td>
        <td>{{ connection.access_token === undefined ? "⚠" : "✅" }}</td>
        <td>
          <div class="flex flex-row">
            <IconButtonV2
              name="md:refresh"
              label="Refresh connection"
              @click="refreshConnection(connection)"
            />
            <IconButtonV2 name="md:close" label="Disconnect" @click="disconnect(connection)" />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import { isNil } from "lodash";
import { useNavigation } from "../composables/useNavigation";
import { environment } from "../environments/environmentLoader";
import { httpClient } from "../http/http";
import { useWorkspaceStore } from "../stores/workspaceStore";
import TextButton from "./TextButton.vue";
import { onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import IconButtonV2 from "./IconButtonV2.vue";

const { currentWorkspaceId } = storeToRefs(useWorkspaceStore());

const connections = ref<QuickBooksConnection[]>([]);

async function connectToQuickBooks() {
  const connectUrl = environment.require("QUICKBOOKS_CONNECT_URL");
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  // Preauthorize first
  const body = {
    workspace_id: workspaceId,
    redirect_url: window.location.href,
  };
  const response = await httpClient.post<QuickBooksPreAuthResponse>(
    `/api/quickbooks/${workspaceId}/authorize`,
    body
  );
  const code = response.data.code;
  return useNavigation().goToUrl(`${connectUrl}?request_code=${code}`);
}

interface QuickBooksConnection {
  company_name: string;
  realm_id: string;
  access_token: string | undefined;
  refresh_token: string | undefined;
  created_by: string;
  modified_by: string;
  created: string;
  modified: string;
}

interface QuickBooksPreAuthResponse {
  code: string;
}

interface GetQuickBooksConnectionsResponse {
  connections: QuickBooksConnection[];
}

async function getQuickBooksConnections(workspaceId: string) {
  const response = await httpClient.get<GetQuickBooksConnectionsResponse>(
    `/api/quickbooks/${workspaceId}/connections`
  );
  return response.data.connections;
}

async function refreshConnection(connection: QuickBooksConnection) {
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  await httpClient.post(`/api/quickbooks/${workspaceId}/realms/${connection.realm_id}/refresh`);
  connections.value = await getQuickBooksConnections(workspaceId);
}

async function disconnect(connection: QuickBooksConnection) {
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  await httpClient.post(`/api/quickbooks/${workspaceId}/realms/${connection.realm_id}/disconnect`);
  connections.value = await getQuickBooksConnections(workspaceId);
}

watch(currentWorkspaceId, async (workspaceId) => {
  if (isNil(workspaceId)) {
    connections.value = [];
  } else {
    connections.value = await getQuickBooksConnections(workspaceId);
  }
});

onMounted(async () => {
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  connections.value = await getQuickBooksConnections(workspaceId);
});
</script>
