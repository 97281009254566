import { createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-5 my-4 flex h-20 cursor-pointer items-center rounded-full p-4 hover:bg-gray-200 dark:text-white dark:hover:bg-black" }
const _hoisted_2 = { class: "mx-5 basis-full overflow-hidden text-ellipsis whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["IconButton"], {
        name: "close",
        color: "orange",
        size: "l",
        highlighted: true,
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.removeColumn()))
      }),
      _createVNode($setup["Dropdown"], {
        placement: "right",
        class: "flex min-w-0 basis-full items-center"
      }, {
        popper: _withCtx(() => [
          _withDirectives(_createVNode($setup["Menu"], { items: $setup.menuItems }, null, 8 /* PROPS */, ["items"]), [
            [_directive_close_popper]
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString($setup.label), 1 /* TEXT */),
          _createVNode($setup["IconButton"], {
            name: "chevron-down",
            size: "xs"
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.relatedFilters, (filter) => {
      return (_openBlock(), _createBlock($setup["Filter"], {
        filter: filter,
        key: filter.alias
      }, null, 8 /* PROPS */, ["filter"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 64 /* STABLE_FRAGMENT */))
}