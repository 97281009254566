import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["TextButton"], {
      label: "Connect to QuickBooks",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.connectToQuickBooks()))
    }),
    _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Connections", -1 /* HOISTED */)),
    _createElementVNode("table", null, [
      _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, "Company"),
          _createElementVNode("td", null, "Realm ID"),
          _createElementVNode("td", null, "Status"),
          _createElementVNode("td", null, "Actions")
        ])
      ], -1 /* HOISTED */)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.connections, (connection) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: connection.realm_id
          }, [
            _createElementVNode("td", null, _toDisplayString(connection.company_name), 1 /* TEXT */),
            _createElementVNode("td", null, _toDisplayString(connection.realm_id), 1 /* TEXT */),
            _createElementVNode("td", null, _toDisplayString(connection.access_token === undefined ? "⚠" : "✅"), 1 /* TEXT */),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_1, [
                _createVNode($setup["IconButtonV2"], {
                  name: "md:refresh",
                  label: "Refresh connection",
                  onClick: ($event: any) => ($setup.refreshConnection(connection))
                }, null, 8 /* PROPS */, ["onClick"]),
                _createVNode($setup["IconButtonV2"], {
                  name: "md:close",
                  label: "Disconnect",
                  onClick: ($event: any) => ($setup.disconnect(connection))
                }, null, 8 /* PROPS */, ["onClick"])
              ])
            ])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}