import { createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex" }
const _hoisted_2 = {
  key: 1,
  class: "absolute inset-0 flex flex-col items-center justify-center text-center text-gray-500"
}
const _hoisted_3 = {
  key: 1,
  class: "absolute inset-0 flex items-center justify-center text-center text-xxxl text-gray-500"
}
const _hoisted_4 = { class: "border-bg-gray-200 relative m-15 basis-[400px] rounded-md border bg-gray-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "relative m-15 basis-full overflow-hidden",
      onMousemove: $setup.updateDropdownPosition
    }, [
      _createVNode($setup["ResizeObserver"], {
        "emit-on-mount": true,
        onNotify: $setup.handleResize
      }),
      ($setup.isValid)
        ? (_openBlock(), _createBlock($setup["AsyncWrapper"], {
            key: 0,
            async: $setup.queryResults
          }, {
            default: _withCtx(() => [
              ($setup.isDone)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (!$setup.isEmpty)
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.vizComponent), {
                          key: 0,
                          visualization: $setup.visualization,
                          results: $setup.results,
                          width: $setup.width,
                          height: $setup.height,
                          onSelect: $setup.handleSelection
                        }, null, 40 /* PROPS, NEED_HYDRATION */, ["visualization", "results", "width", "height"]))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", {
                      class: "absolute h-1 w-1",
                      style: _normalizeStyle({
              left: `${$setup.dropdownX}px`,
              top: `${$setup.dropdownY}px`,
              pointerEvents: $setup.dropdownShown ? 'all' : 'none',
            })
                    }, [
                      _createVNode($setup["Dropdown"], {
                        shown: $setup.dropdownShown,
                        "onUpdate:shown": _cache[0] || (_cache[0] = ($event: any) => (($setup.dropdownShown) = $event)),
                        triggers: []
                      }, {
                        popper: _withCtx(() => [
                          _withDirectives(_createVNode($setup["Menu"], { items: $setup.menuItems }, null, 8 /* PROPS */, ["items"]), [
                            [_directive_close_popper]
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["shown"])
                    ], 4 /* STYLE */),
                    ($setup.isEmpty)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[3] || (_cache[3] = [
                          _createElementVNode("div", { class: "text-xxl" }, "No results", -1 /* HOISTED */),
                          _createElementVNode("div", { class: "mt-5" }, "Consider changing or removing filters.", -1 /* HOISTED */)
                        ])))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["async"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, " To visualize your data, please make all required selections at right. "))
    ], 32 /* NEED_HYDRATION */),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["VisualizationConfig"], {
        type: $setup.exploreStore.visualizationType,
        "onUpdate:type": _cache[1] || (_cache[1] = ($event: any) => (($setup.exploreStore.visualizationType) = $event)),
        config: $setup.exploreStore.visualizationConfig,
        "onUpdate:config": _cache[2] || (_cache[2] = ($event: any) => (($setup.exploreStore.visualizationConfig) = $event)),
        query: $setup.exploreStore.query!
      }, null, 8 /* PROPS */, ["type", "config", "query"])
    ])
  ]))
}