<template>
  <div class="isolate">
    <div
      class="absolute inset-0 z-10 flex flex-col items-center justify-center gap-10 text-black dark:text-white"
      :class="{ 'bg-white bg-opacity-85 dark:bg-black dark:bg-opacity-85': overlay }"
      v-if="async.status !== AsyncStatus.Succeeded"
    >
      <div v-if="async.status === AsyncStatus.Failed" class="text-xxxl">{{ async.message }}</div>
      <template v-if="async.status === AsyncStatus.InProgress">
        <Spinner size="xxl" />
        <div class="mt-10 text-xxxl" :class="{ 'opacity-60': overlay }">
          {{ async.message }}
        </div>
        <div :class="{ 'opacity-60': overlay }">
          <WarehouseLoading />
        </div>
      </template>
    </div>
    <div class="absolute inset-0"><slot /></div>
  </div>
</template>

<script lang="ts" setup>
import { Async, AsyncStatus } from "@/common/lib/async";
import { toRefs } from "vue";
import Spinner from "./Spinner.vue";
import WarehouseLoading from "./WarehouseLoading.vue";

const props = withDefaults(defineProps<{ async: Async<unknown>; overlay?: boolean }>(), {
  overlay: true,
});
const { async } = toRefs(props);
</script>
