import { chunk, findKey, fromPairs, isArray, isEqual, isString, omit } from "lodash";
import { environment } from "../environments/environmentLoader";
import {
  AliasLocations,
  FetchNFilter,
  FetchNNeighborhood,
  FetchNOrderBy,
  FetchNPathNode,
  FetchNRequest,
  FilterType,
  GROUP_BY_ALL,
  PropertyFilter,
} from "./fetchApi";
import { LinkDescriptor } from "./graph";
import { ConceptKnowledgeRef } from "./knowledge";
import {
  columnName,
  filterIsComplete,
  Query,
  QueryFilter,
  QueryOrderBy,
  QueryPathNode,
} from "./query";

// This code converts a Query (as found in ./query.ts) to a FetchNRequest (see ./fetchApi.ts)

export function expandQuery(query: Query): [FetchNRequest, AliasLocations] {
  const [neighbors, aliasLocations] = buildNeighborhoods(query);
  const request: FetchNRequest = {
    concept_type: query.root_concept_type,
    neighbors,
    size: query.size ?? 100,
    order_by: query.order_by.map((ob) => expandOrderBy(query, ob, aliasLocations)),
    group_by:
      query.group_by === GROUP_BY_ALL
        ? GROUP_BY_ALL
        : query.group_by.filter((g) => g.path == null).map((g) => g.property_type),
    properties: fromPairs(
      query.columns.filter((c) => c.path == null).map((c) => [c.alias, c.property_type])
    ),
    filters: query.filters.filter(filterIsComplete).map((ef) => expandFilter(ef, aliasLocations)),
    columns: query.columns.map((column) => ({
      alias: column.alias,
      name: columnName(column),
    })),
  };
  return [request, aliasLocations];
}

function buildNeighborhoods(query: Query): [Record<string, FetchNNeighborhood>, AliasLocations] {
  const neighborhoods: Record<string, FetchNNeighborhood> = {};
  const aliasLocations: AliasLocations = {};

  function neighborhoodToPath(neighborhood: FetchNNeighborhood): QueryPathNode[] {
    return chunk(neighborhood, 2).map(([ld, c]) => ({
      link_descriptor: ld as LinkDescriptor,
      concept_type: isString(c) ? (c as ConceptKnowledgeRef) : c.concept_type,
    }));
  }

  function addNeighborhood(
    path: QueryPathNode[],
    endpointAttrs: Omit<FetchNPathNode, "concept_type" | "tag"> = {}
  ) {
    let neighborhoodKey = findKey(neighborhoods, (neighborhood) =>
      isEqual(neighborhoodToPath(neighborhood), path)
    );
    if (neighborhoodKey == null) {
      neighborhoodKey = path
        .flatMap((element) => [element.link_descriptor, element.concept_type])
        .join("_");
      neighborhoods[neighborhoodKey] = expandPath(path, {
        tag: generateTagName(neighborhoodKey, path.length * 2 - 1),
        ...endpointAttrs,
      });
    } else {
      // Merge existing and new endpoint attributes
      const node = neighborhoods[neighborhoodKey][path.length * 2 - 1] as FetchNPathNode;
      if (endpointAttrs.group_by != null) {
        if (endpointAttrs.group_by === GROUP_BY_ALL || node.group_by === GROUP_BY_ALL) {
          node.group_by = GROUP_BY_ALL;
        } else {
          node.group_by = [...(node.group_by ?? []), ...endpointAttrs.group_by];
        }
      }
      if (endpointAttrs.properties) {
        node.properties = { ...endpointAttrs.properties, ...(node.properties ?? {}) };
      }
      if (environment.requireBoolean("APPLY_FILTERS_TWICE") && endpointAttrs.filters) {
        node.filters = [...endpointAttrs.filters, ...(node.filters ?? [])];
      }
    }
    return neighborhoodKey;
  }
  for (const column of query.columns) {
    if (column.path) {
      const nkey = addNeighborhood(column.path, {
        properties: { [column.alias]: column.property_type },
      });
      aliasLocations[column.alias] = { neighborhood: nkey, position: column.path.length * 2 - 1 };
    }
  }
  for (const filter of query.filters) {
    if (filter.path) {
      let endpointAttrs = {};
      if (environment.requireBoolean("APPLY_FILTERS_TWICE")) {
        // We currently add each filter twice; once at the root and once at neighbor
        // depth, as this is thought to more accurately reflect common user intent
        // See https://claritype.slack.com/archives/C03QLCTM8P7/p1729012096088099
        const deepFilter = expandFilter(omit(filter, "path"), {});
        endpointAttrs = { filters: [deepFilter] };
      }
      const nkey = addNeighborhood(filter.path, endpointAttrs);
      aliasLocations[filter.alias] = { neighborhood: nkey, position: filter.path.length * 2 - 1 };
    }
  }
  if (isArray(query.group_by)) {
    for (const group of query.group_by) {
      if (group.path) {
        addNeighborhood(group.path, { group_by: [group.property_type] });
      }
    }
  }
  return [neighborhoods, aliasLocations];
}

function expandFilter(filter: QueryFilter, aliasLocations: AliasLocations): FetchNFilter {
  const tag = filter.path != null ? aliasLocations[filter.alias] : undefined;
  const on_tag = tag ? generateTagName(tag.neighborhood, tag.position) : undefined;
  let filters: PropertyFilter[];
  if (filter.type === FilterType.Exists) {
    filters = [{ type: filter.type, property_type: filter.property_type, on_tag }];
  } else {
    filters = filter.values.map((value) => ({
      type: filter.type,
      property_type: filter.property_type,
      on_tag,
      ...value,
    })) as PropertyFilter[];
  }
  let outerFilter: FetchNFilter;
  if (filters.length === 1) {
    outerFilter = filters[0];
  } else {
    outerFilter = { type: FilterType.Or, filters }; // Later, make op configurable
  }
  if (filter.negated) outerFilter = { type: FilterType.Not, filter: outerFilter };
  return outerFilter;
}

function expandOrderBy(
  query: Query,
  orderBy: QueryOrderBy,
  aliasLocations: AliasLocations
): FetchNOrderBy {
  const column = query.columns.find((c) => c.alias === orderBy.on);
  if (column == null) throw `Order by column missing (${orderBy.on})`;
  const tag = column.path != null ? aliasLocations[column.alias] : undefined;
  return {
    ...orderBy,
    on_tag: tag ? generateTagName(tag.neighborhood, tag.position) : undefined,
  };
}

function expandPath(
  path: QueryPathNode[],
  endpointAttrs: Partial<FetchNPathNode> = {}
): FetchNNeighborhood {
  const neighborhood: FetchNNeighborhood = [];
  for (const pathNode of path) {
    neighborhood.push(pathNode.link_descriptor!);
    neighborhood.push({ concept_type: pathNode.concept_type });
  }
  neighborhood[neighborhood.length - 1] = {
    ...(neighborhood[neighborhood.length - 1] as FetchNPathNode),
    ...endpointAttrs,
  };
  return neighborhood;
}

function generateTagName(neighborhoodKey: string, position: number) {
  return `${neighborhoodKey}_${position}`;
}
