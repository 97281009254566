import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { CheckboxVisualizationConfigControl } from "@/reader/lib/visualizationConfig";
import { Query } from "@/common/lib/query";
import { toRefs } from "vue";
import Checkbox from "@/common/components/Checkbox.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'VisualizationCheckboxControl',
  props: /*@__PURE__*/_mergeModels({
    control: { type: Object, required: true },
    query: { type: Object, required: true }
  }, {
    "modelValue": { type: Boolean, ...{ default: false } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { control } = toRefs(props);

const model = _useModel<boolean>(__props, "modelValue");

const __returned__ = { props, control, model, Checkbox }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})