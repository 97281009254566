import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import IconButton from "@/common/components/IconButton.vue";
import Menu from "@/common/components/Menu.vue";
import { LinkDescriptor } from "@/common/lib/graph";
import Disclosure from "@/common/components/Disclosure.vue";
import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { childPaths, expandTreePath, ExploreTreePath } from "@/reader/lib/exploreTree";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { groupBy, identity, isEqual, last, mapValues } from "lodash";
import { computed, toRefs } from "vue";
import TreeDerivedColumn from "./TreeDerivedColumn.vue";
import TreeProperty from "./TreeProperty.vue";
import { underlyingPropertyTypes } from "@/common/lib/derived";
import { MISSING_CONCEPT_COLOR } from "@/common/lib/conceptColors";
import { hasResolutionClauseForTypes, propertySorter } from "@/common/lib/map";
import { useAppStore } from "@/reader/stores/app";
import { columnIsDerived } from "@/common/lib/query";

const TOP_LEVEL_DERIVED_COLS = "";


export default /*@__PURE__*/_defineComponent({
  __name: 'TreeConcept',
  props: {
    path: { type: Array, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { path } = toRefs(props);

const exploreStore = useExploreStore();
const { getConceptIconName, typeLabel } = useKnowledge();
const { getConceptsOfType } = useGraph(() => exploreStore.metagraph);
const appStore = useAppStore();

const conceptType = computed(() => last(path.value)!.concept_type);
const name = computed(() => typeLabel(conceptType.value));
const iconName = computed(() => getConceptIconName(conceptType.value));

const isRoot = computed(() => path.value.length === 1);

const propertyTypes = computed(function () {
  const properties = getConceptsOfType(conceptType.value)[0].properties ?? [];
  return propertySorter(
    appStore.map,
    conceptType.value,
    properties.map((p) => p.type),
    identity
  );
});

const linkDescription = computed(function () {
  const { elidedRoleType, link_descriptor } = last(path.value) ?? {};
  if (elidedRoleType) {
    const elidedLabel = typeLabel(elidedRoleType);
    if (link_descriptor === LinkDescriptor.RelatedTo) return `As ${elidedLabel}:`;
    if (link_descriptor === LinkDescriptor.RoleOf) return `${elidedLabel}:`;
  }
  if (link_descriptor === LinkDescriptor.AsA) return "As";
  if (link_descriptor === LinkDescriptor.RoleOf) return "Role of";
  return null;
});

const children = computed(function () {
  return childPaths(path.value).map((path) => ({
    path,
    key: JSON.stringify(last(path)),
  }));
});

const menuItems = computed(() =>
  exploreMenu(ExploreMenuSubject.ConceptType, {
    conceptPath: path.value,
  })
);

const derivedColumns = computed(function () {
  const qpath = expandTreePath(path.value);
  const cols = exploreStore
    .query!.columns.filter((column) => isEqual(column.path, qpath))
    .filter((column) => columnIsDerived(column));
  const groups = groupBy(cols, function (column) {
    const props = underlyingPropertyTypes(column.property_type);
    return props.length != 1 ? TOP_LEVEL_DERIVED_COLS : props[0];
  });
  return mapValues(groups, (cols) => cols.map((col) => col.alias));
});

const isExpanded = computed(() => isRoot.value || exploreStore.isPathExpanded(path.value));

const color = computed(
  () => exploreStore.conceptColors[conceptType.value] ?? MISSING_CONCEPT_COLOR
);

function toggleExpanded() {
  exploreStore.togglePathExpanded(path.value);
}

function isResolved(propertyType: string): boolean {
  return hasResolutionClauseForTypes(appStore.map, conceptType.value, propertyType);
}

const __returned__ = { props, path, exploreStore, getConceptIconName, typeLabel, getConceptsOfType, appStore, conceptType, name, iconName, isRoot, propertyTypes, linkDescription, children, menuItems, TOP_LEVEL_DERIVED_COLS, derivedColumns, isExpanded, color, toggleExpanded, isResolved, Icon, IconButton, Menu, Disclosure, get Dropdown() { return Dropdown }, TreeDerivedColumn, TreeProperty }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})