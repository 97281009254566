import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute inset-0 m-15 mt-10 flex flex-col" }
const _hoisted_2 = { class: "mb-10 flex flex-row items-center" }
const _hoisted_3 = { class: "basis-full overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "basis-full text-xl text-gray-500" }, "Visualization Settings", -1 /* HOISTED */)),
      _createVNode($setup["IconButtonV2"], {
        name: "md:content_copy",
        size: "l",
        label: "Copy Widget JSON",
        onClick: $setup.copyWidgetJSON,
        disabled: $setup.type == null
      }, null, 8 /* PROPS */, ["disabled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["Select"], {
        options: $setup.types,
        "option-label": $setup.typeName,
        required: "",
        label: "Visualization Type",
        placeholder: "Choose a type to get started",
        modelValue: $setup.type,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => (($setup.type) = $event)),
          $setup.prepareNewType
        ]
      }, null, 8 /* PROPS */, ["options", "modelValue"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.controls, (control) => {
        return (_openBlock(), _createElementBlock("div", {
          key: control.key,
          class: "my-10"
        }, [
          _createVNode($setup["VisualizationColumnControl"], {
            control: control,
            query: $props.query,
            "model-value": $setup.config![control.key] as PropertyValueGenerator,
            "onUpdate:modelValue": (value) => ($setup.config![control.key] = value)
          }, null, 8 /* PROPS */, ["control", "query", "model-value", "onUpdate:modelValue"])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}