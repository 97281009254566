import { defineComponent as _defineComponent } from 'vue'
import { ValueWithFormattedValue } from "@/common/lib/format";
import { GraphValue, stringifyValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";

export interface LegendItem {
  label: ValueWithFormattedValue;
  identifier?: GraphValue;
  value?: ValueWithFormattedValue;
  color: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Legend',
  props: {
    items: { type: Array, required: true },
    total: { type: Object, required: false }
  },
  emits: ["select"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const { items, total } = toRefs(props);

const emit = __emit;

const itemDetails = computed(function () {
  return items.value.map(function (item, index) {
    return {
      key: index,
      label: stringifyValue(item.label.formattedValue),
      value: item.value ? stringifyValue(item.value.formattedValue) : null,
      identifier: item.identifier,
      color: item.color,
    };
  });
});

const totalString = computed(() => (total.value ? stringifyValue(total.value.formattedValue) : ""));

const __returned__ = { props, items, total, emit, itemDetails, totalString }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})