// This composable supports navigation between apps. Navigation within an app
// should use the app's router instead. (Also, this maybe should be a simple

import { sleep } from "../lib/asynchronous";

// lib as it doesn't have any arguments or internal state).
export function useNavigation() {
  /**
   * Navigate to the specified project.
   * @param projectId
   */
  function openProject(projectId: string, newProject = false) {
    const query = newProject ? "?newProject=true" : "";
    window.location.href = `/editor/${projectId}${query}`;
  }

  /**
   * Go to the home page.
   */
  function goHome() {
    window.location.href = "/home";
  }

  function openUrl(url: string) {
    window.open(url);
  }

  async function goToUrl(url: string): Promise<never> {
    window.location.href = url;
    // Okay, this is a pain.
    // The above redirect doesn't get executed immediately, and we need to return something here.
    // So we wait a small amount of time to allow the above to complete, THEN throw.
    await sleep(5000);
    throw new Error("Expected redirect");
  }

  return { goHome, openProject, openUrl, goToUrl };
}
