import { defineComponent as _defineComponent } from 'vue'
import AsyncWrapper from "@/common/components/AsyncWrapper.vue";
import Modal from "@/common/components/Modal.vue";
import CalculationForm from "../components/explore/calculation/CalculationForm.vue";
import MetagraphPane from "../components/explore/metagraph/MetagraphPane.vue";
import QueryBasics from "../components/explore/QueryBasics.vue";
import QueryProblems from "../components/explore/QueryProblems.vue";
import SqlView from "../components/explore/SqlView.vue";
import Table from "../components/explore/Table.vue";
import BookmarkMenu from "../components/explore/BookmarkMenu.vue";
import { Mode, useExploreStore, ExploreContext } from "../stores/explore";
import { useExploreMetagraphStore } from "../stores/exploreMetagraph";
import { computed, onMounted, onUnmounted, ref, Ref, watch } from "vue";
import TabSelect from "@/common/components/TabSelect.vue";
import ExploreTools from "../components/explore/ExploreTools.vue";
import IconButton from "@/common/components/IconButton.vue";
import IconButton2 from "@/common/components/IconButtonV2.vue";
import AskBar from "../components/explore/AskBar.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import Textbox from "@/common/components/Textbox.vue";
import Dialog from "@/common/components/Dialog.vue";
import { cloneDeep } from "lodash";
import { useUserModuleStore, Bookmark } from "@/common/stores/userModuleStore";
import SpinnerButton from "@/common/components/SpinnerButton.vue";
import { validateQuery } from "@/common/lib/query";
import { consumeStoredExploreState, StoredExploreState } from "../lib/storage";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { storeExploreState } from "../lib/storage";
import Visualization from "../components/explore/Visualization.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Explore',
  props: {
    bookmark: { type: String, required: false },
    module: { type: String, required: false },
    moduleId: { type: String, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const exploreStore = useExploreStore();
const exploreMetagraphStore = useExploreMetagraphStore();
const userModuleStore = useUserModuleStore();
const router = useRouter();
const route = useRoute();

const projectId = computed(() => exploreStore.module);
const bookmarksEnabled = computed(() => projectId.value);
const createTestEnabled = environment.requireBoolean("EXPLORER_CREATE_TEST_ENABLED");
const exportEnabled = environment.requireBoolean("EXCEL_EXPORT_ENABLED");
const showCreateTestDialog = ref(false);
const testName = ref("");
const bookmarkStatus: Ref<"create" | "update" | "showInvalidBookmark" | "failure" | undefined> =
  ref(undefined);
const bookmarkName = ref<string>();
const currentBookmark: Ref<Bookmark | undefined> = ref(undefined);

const props = __props;
const exporting = ref(false);

const tabs = computed(() => {
  return [
    {
      label: "Table",
      key: Mode.Table,
      icon: "md:table",
      disabled: exploreStore.query == null,
    },
    {
      label: "Visualization",
      key: Mode.Visualization,
      icon: "md:insert_chart",
      disabled: exploreStore.query == null,
    },
    {
      label: "SQL",
      key: Mode.SQL,
      icon: "SQL",
      disabled: exploreStore.query == null,
    },
  ];
});

function createExplorerTest() {
  exploreStore.load(true, { testName: testName.value });
  testName.value = "";
  showCreateTestDialog.value = false;
}
async function loadBookmark(bookmarkId: string) {
  if (projectId.value) {
    try {
      const response = await userModuleStore.getBookmark(projectId.value, bookmarkId);
      const errors = validateQuery(response.state, exploreStore.metagraph);
      if (errors.length > 0) {
        bookmarkStatus.value = "showInvalidBookmark";
        bookmarkErrors.value = errors;
        currentBookmark.value = response;
      } else {
        router.push({
          name: route.name,
          params: { module: projectId.value },
        });

        exploreStore.loadQuery(response.state);
      }
    } catch (error: string) {
      bookmarkStatus.value = "failure";
      bookmarkErrors.value = [error];
    }
  }
}

async function handleBookmark() {
  if (bookmarkStatus.value == "create") {
    await addBookmark();
  } else {
    await updateBookmark();
  }
}

async function addBookmark() {
  if (!projectId.value || !bookmarkName.value) {
    return;
  }
  try {
    const bookmark = cloneDeep(exploreStore.query);
    if (!bookmark) return;
    const bookmarkId = await userModuleStore.newBookmark(projectId.value, {
      name: bookmarkName.value,
      description: "",
      state: bookmark,
    });

    if (bookmarkId) {
      userModuleStore.loadProjectBookmarks(projectId.value);
    }

    clearBookmark();
  } catch (error) {
    bookmarkStatus.value = "failure";
    bookmarkErrors.value = [String(error)];
  }
}
async function updateBookmark() {
  const bookmarkToUpdate = currentBookmark.value;
  if (!projectId.value || !bookmarkName.value || !bookmarkToUpdate) {
    return;
  }
  try {
    const bookmarkId = await userModuleStore.updateBookmark(projectId.value, bookmarkToUpdate.id, {
      id: bookmarkToUpdate.id,
      name: bookmarkName.value,
      description: "",
    });

    if (bookmarkId && projectId.value) {
      userModuleStore.loadProjectBookmarks(projectId.value);
    }

    clearBookmark();
  } catch (error: string) {
    bookmarkStatus.value = "failure";
    bookmarkErrors.value = [error];
  }
}

async function deleteBookmark(bookmark: Bookmark | undefined) {
  if (projectId.value && bookmark) {
    try {
      await userModuleStore.deleteBookmark(projectId.value, bookmark.id);
      userModuleStore.loadProjectBookmarks(projectId.value);
    } catch (error: string) {
      bookmarkStatus.value = "failure";
      bookmarkErrors.value = [error];
    }

    clearBookmark();
  }
}

function openUpdateBookmark(bookmark: Bookmark) {
  bookmarkStatus.value = "update";
  currentBookmark.value = bookmark;
  bookmarkName.value = bookmark.metadata.name;
}

const bookmarkErrors: Ref<string[]> = ref([]);
function displayError(invalidBookmark: Bookmark, errors: string[]) {
  bookmarkStatus.value = "showInvalidBookmark";
  bookmarkErrors.value = errors;
  currentBookmark.value = invalidBookmark;
}

function clearBookmark() {
  bookmarkName.value = undefined;
  bookmarkStatus.value = undefined;
}

async function downloadExcel() {
  exporting.value = true;
  try {
    await exploreStore.downloadExcel();
  } finally {
    exporting.value = false;
  }
}

const maybeState: Ref<StoredExploreState | null> = ref(null);

watch(
  () => exploreStore.metagraph,
  () => {
    if (exploreStore.module) {
      userModuleStore.loadProjectBookmarks(exploreStore.module);
    }
    if (props.bookmark) {
      loadBookmark(props.bookmark);
    } else if (maybeState.value != null) {
      exploreStore.restoreState(maybeState.value);
    }
  },
  { immediate: true }
);

watch(
  () => props.bookmark,
  (newVal) => {
    if (newVal) {
      loadBookmark(newVal);
    }
  }
);

const storeCurrentQuery = environment.requireBoolean("EXPLORER_STORE_CURRENT_QUERY");

onMounted(function () {
  if (storeCurrentQuery) {
    window.addEventListener("beforeunload", handleBeforeUnload);
  }
  if (props.bookmark) {
    loadBookmark(props.bookmark);
  } else {
    maybeState.value = consumeStoredExploreState();
    if (maybeState.value != null) exploreStore.restoreState(maybeState.value);
  }
});

const handleBeforeUnload = () => {
  const query = exploreStore.query;
  query && storeExploreState(query);
};

if (storeCurrentQuery) {
  onUnmounted(function () {
    window.removeEventListener("beforeunload", handleBeforeUnload);
  });
}

const __returned__ = { exploreStore, exploreMetagraphStore, userModuleStore, router, route, projectId, bookmarksEnabled, createTestEnabled, exportEnabled, showCreateTestDialog, testName, bookmarkStatus, bookmarkName, currentBookmark, props, exporting, tabs, createExplorerTest, loadBookmark, handleBookmark, addBookmark, updateBookmark, deleteBookmark, openUpdateBookmark, bookmarkErrors, displayError, clearBookmark, downloadExcel, maybeState, storeCurrentQuery, handleBeforeUnload, AsyncWrapper, Modal, CalculationForm, MetagraphPane, QueryBasics, QueryProblems, SqlView, Table, BookmarkMenu, get Mode() { return Mode }, get ExploreContext() { return ExploreContext }, TabSelect, ExploreTools, IconButton, IconButton2, AskBar, TextButton, Textbox, Dialog, SpinnerButton, Visualization }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})