import { defineComponent as _defineComponent } from 'vue'
import { IconSize } from "../lib/icons";
import Icon from "./Icon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Spinner',
  props: {
    size: { type: String, required: false, default: "xl" }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();



const __returned__ = { Icon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})