<template>
  <div class="flex flex-col items-start gap-15" v-if="user">
    <div class="flex flex-col items-start gap-15">
      <div class="text-xl">Home Page</div>
      <div v-if="userSettings?.default_homepage">{{ userSettings?.default_homepage }}</div>
      <div v-else class="italic">Home page not set</div>
      <TextButton
        label="Update
        home page"
        @click="editHomePage"
      />
    </div>
    <div class="flex flex-col items-start gap-15">
      <div class="text-xl">Default LLM</div>
      <div v-if="currentDefaultLLMLabel">{{ currentDefaultLLMLabel }}</div>
      <div v-else class="italic">Default LLM not set</div>
      <TextButton label="Update default LLM" @click="editDefaultLLM" />
    </div>
  </div>

  <Dialog
    v-if="editingHomePage !== undefined"
    title="Update home page"
    success-label="Update"
    @succeeded="finishEditHomePage"
    @cancelled="editingHomePage = undefined"
  >
    <Textbox label="Home page path" v-model="editingHomePage" :autoselect="true" />
    Enter a custom URL path to determine where you are taken upon login (e.g., /reader).
  </Dialog>

  <Dialog
    v-if="editingDefaultLLM !== undefined"
    title="Update default LLM"
    success-label="Update"
    @succeeded="finishEditDefaultLLM"
    @cancelled="editingDefaultLLM = undefined"
  >
    <Select
      label="Select default LLM"
      v-model="editingDefaultLLM"
      :options="llmMenuItems"
      :option-label="(option: LLMOption) => option.label"
      :option-value="(option: LLMOption) => option.key"
      class="h-[350px]"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import Dialog from "@/common/components/Dialog.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import Select from "./Select.vue";
import { environment } from "../environments/environmentLoader";

const editingHomePage = ref<string | undefined>();
const editingDefaultLLM = ref<string | undefined>();

const userStore = useUserStore();
const { user, userSettings } = storeToRefs(userStore);

interface LLMOption {
  key: string;
  label: string;
}

const LLM_OPTIONS: { [key: string]: string } = environment.requireJson("LLM_PROVIDER_MAP");
const llmMenuItems = computed<LLMOption[]>(() => {
  return Object.entries(LLM_OPTIONS).map(([key, label]) => ({
    key,
    label,
  }));
});

const currentDefaultLLMLabel = computed(() => {
  const currentId = userSettings?.value?.default_llm;
  return currentId ? LLM_OPTIONS[currentId] : undefined;
});

function editHomePage() {
  editingHomePage.value = userSettings?.value?.default_homepage ?? "";
}

function editDefaultLLM() {
  editingDefaultLLM.value =
    userSettings?.value?.default_llm ?? environment.require("LLM_PROVIDER_DEFAULT");
}

async function finishEditHomePage() {
  userSettings.value ??= {};
  userSettings.value.default_homepage =
    editingHomePage.value?.trim() === "" ? undefined : editingHomePage.value;
  const success = await userStore.updateUserSettings(userSettings.value);
  if (!success) {
    return;
  }
  editingHomePage.value = undefined;
}

async function finishEditDefaultLLM() {
  userSettings.value ??= {};
  userSettings.value.default_llm =
    editingDefaultLLM.value?.trim() === "" ? undefined : editingDefaultLLM.value;
  const success = await userStore.updateUserSettings(userSettings.value);
  if (!success) {
    return;
  }
  editingDefaultLLM.value = undefined;
}
</script>
