import Ajv, { ErrorObject, JSONSchemaType } from "ajv";
const ajv = new Ajv({ allErrors: false });

export type ValidateJsonResult<T> = { valid: true; value: T } | { valid: false; errors: string[] };

export function validateJson<T>(value: unknown, schema: JSONSchemaType<T>): ValidateJsonResult<T> {
  const validate = ajv.compile(schema);
  const valid = validate(value);
  if (!valid) {
    const errors = (validate.errors ?? []).map(formatError);
    return { valid, errors };
  }
  return { valid, value };
}

export function fixSchema<T>(schema: JSONSchemaType<T>): JSONSchemaType<T> {
  // Delete invalid "date-time" reference;
  // this is incorrectly being generated by the ts-json-schema-generator tool.
  delete schema.definitions!["DateValue"]["properties"]["_type"]["format"];
  return schema;
}

function formatError(e: ErrorObject): string {
  return `${e.keyword}: ${e.instancePath} ${e.message} (${Object.values(e.params).join(", ")})`;
}
