import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  name: "footer",
  class: "flex flex-none flex-row justify-end gap-5 border-t border-t-gray-200 bg-gray-100 p-10 text-gray-500 dark:border-t-gray-900 dark:bg-gray-800 dark:text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return ($setup.showFooter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString($setup.rowsReturned) + " rows returned", 1 /* TEXT */),
        _cache[0] || (_cache[0] = _createElementVNode("div", null, "|", -1 /* HOISTED */)),
        _createVNode($setup["Dropdown"], { class: "menu-wrapper" }, {
          popper: _withCtx(() => [
            _withDirectives(_createVNode($setup["Menu"], { items: $setup.limitMenuItems }, null, 8 /* PROPS */, ["items"]), [
              [_directive_close_popper]
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", null, "Row limit: " + _toDisplayString($setup.queryLimit), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]))
    : _createCommentVNode("v-if", true)
}