import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withKeys as _withKeys, normalizeStyle as _normalizeStyle, vModelText as _vModelText } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 1,
  class: "relative flex w-full gap-30"
}
const _hoisted_3 = ["type", "disabled", "autocomplete", "required", "id"]
const _hoisted_4 = ["type", "disabled", "autocomplete", "id"]
const _hoisted_5 = ["type", "disabled", "id"]
const _hoisted_6 = {
  key: 4,
  class: "pt-5 text-xs text-gray-500"
}
const _hoisted_7 = {
  key: 5,
  class: "flex w-full items-start gap-10 p-5 text-xs text-red-300"
}
const _hoisted_8 = {
  key: 6,
  class: "min-h-[22px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["group flex w-full flex-col items-start pt-5", { 'pb-5': $props.errorText === undefined }])
  }, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: $props.id,
          class: _normalizeClass(["text-s pointer-events-none mb-5 flex items-start gap-5 font-semibold text-gray-500", [
        $setup.requiredText,
        {
          'group-focus-within:text-orange': !$props.disabled,
        },
      ]])
        }, _toDisplayString($props.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    ($props.type === 'search')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["Icon"], {
            class: _normalizeClass(["absolute left-8 top-[7px]", {
          'svg-to-dark-gray': $setup.darkMode,
          'svg-to-medium-gray': !$setup.darkMode,
        }]),
            name: "search"
          }, null, 8 /* PROPS */, ["class"]),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["flex h-[34px] w-full resize-none items-center gap-10 self-stretch rounded border border-solid py-10 pl-[38px] pr-20 text-base text-gray-800 caret-orange outline-0 focus:border-[1px] focus:border-orange focus:outline-none search-cancel:appearance-none dark:border-gray-700 dark:text-gray-200 dark:focus:border-orange", {
          'border-gray-200 bg-gray-100 hover:border-gray-500 hover:bg-gray-100 focus:bg-gray-100 dark:border-gray-800 dark:bg-gray-900 dark:hover:border-black dark:hover:bg-black dark:focus:bg-gray-900':
            !$props.disabled,
          'background-unset border-[1px] border-gray-300 dark:border-gray-700': $props.disabled,
        }]),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
            type: $props.type,
            disabled: $props.disabled,
            autocomplete: $props.autocomplete,
            "helper-text": "hmm okay",
            required: $props.required,
            id: $props.id,
            ref: "el"
          }, null, 10 /* CLASS, PROPS */, _hoisted_3), [
            [_vModelDynamic, $setup.value]
          ])
        ]))
      : (!$props.textarea)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 2,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.value) = $event)),
            required: "",
            class: _normalizeClass(["flex h-[34px] resize-none items-center gap-10 self-stretch rounded border-[1px] border-solid bg-gray-200 py-10 pl-10 pr-20 caret-orange focus:border-orange focus:outline-none dark:bg-gray-800", {
        'border-gray-500 text-gray-900 hover:border-gray-500 hover:bg-gray-100 focus:bg-gray-100 dark:border-gray-700 dark:text-gray-100 dark:hover:bg-gray-900 dark:focus:bg-gray-900':
          !$props.disabled,
        'border-gray-300 text-gray-300 dark:border-gray-700 dark:text-gray-700': $props.disabled,
      }]),
            style: _normalizeStyle({ 'color-scheme': $setup.darkMode ? 'dark' : 'light' }),
            type: $props.type,
            disabled: $props.disabled,
            autocomplete: $props.autocomplete,
            autofocus: true,
            id: $props.id,
            "data-test": "text-input",
            ref: "el",
            onFocus: _cache[2] || (_cache[2] = ($event: any) => ($setup.emit('focus', $event))),
            onBlur: _cache[3] || (_cache[3] = ($event: any) => ($setup.emit('blur', $event))),
            onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => ($setup.emit('keyup.enter', $event)), ["enter"]))
          }, null, 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_4)), [
            [_vModelDynamic, $setup.value]
          ])
        : _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 3,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.value) = $event)),
            required: "",
            class: _normalizeClass(["flex h-[40px] max-h-[140px] resize-none items-center gap-10 self-stretch rounded border-[1px] border-solid bg-gray-200 py-10 pl-10 pr-20 caret-orange focus:border-orange focus:outline-none dark:bg-gray-800", {
        'dark:focus:gray-900 border-gray-300 text-gray-900 hover:border-gray-500 hover:bg-gray-100 focus:bg-gray-100 dark:border-gray-700 dark:text-gray-100 dark:hover:bg-gray-900 dark:focus:bg-gray-900':
          !$props.disabled,
        'border-gray-300 text-gray-300 dark:border-gray-700 dark:text-gray-700': $props.disabled,
      }]),
            type: $props.type,
            disabled: $props.disabled,
            id: $props.id,
            ref: "el",
            style: _normalizeStyle({ 'min-height': $props.minHeight, 'max-height': $props.maxHeight })
          }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_5)), [
            [_vModelText, $setup.value]
          ]),
    ($props.helperText && !$props.errorText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($props.helperText), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($props.errorText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($props.errorText), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($props.errorText === '' && !$props.helperText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}