<template>
  <div
    v-if="showFooter"
    name="footer"
    class="flex flex-none flex-row justify-end gap-5 border-t border-t-gray-200 bg-gray-100 p-10 text-gray-500 dark:border-t-gray-900 dark:bg-gray-800 dark:text-gray-500"
  >
    <div>{{ rowsReturned }} rows returned</div>
    <div>|</div>
    <Dropdown class="menu-wrapper">
      <template #popper>
        <Menu :items="limitMenuItems" v-close-popper />
      </template>
      <div>Row limit: {{ queryLimit }}</div>
    </Dropdown>
  </div>
</template>

<script lang="ts" setup>
import { Mode, useExploreStore } from "../stores/explore";
import { computed } from "vue";
import { environment } from "@/common/environments/environmentLoader";
import { isNil } from "lodash";
import { MenuItem } from "@/common/lib/menus";
import { Dropdown } from "floating-vue";
import Menu from "@/common/components/Menu.vue";
import { asyncHasValue, asyncResultOr } from "@/common/lib/async";
import { UserAction } from "@/common/lib/userAction";

const exploreStore = useExploreStore();

const showFooter = computed(() => {
  return exploreStore.mode === Mode.Table && limitMenuItems.value.length > 0;
});

const rowsReturned = computed(() => {
  return asyncResultOr(exploreStore.table, []).length.toLocaleString();
});

const queryLimit = computed(() => {
  return exploreStore.query?.size ?? environment.requireJson("EXPLORER_DEFAULT_QUERY_LIMIT");
});

const limitMenuItems = computed(() => {
  const query = exploreStore.query;
  if (isNil(query)) {
    return [];
  }
  if (!asyncHasValue(exploreStore.table)) {
    return [];
  }
  function makeMenuItem(limit: number): MenuItem {
    return {
      key: limit.toString(),
      label: limit.toLocaleString(),
      icon: queryLimit.value === limit ? "md:check" : null,
      action: async () => {
        query!.size = limit;
        await exploreStore.loadTable(UserAction.Change_Query_Limit);
      },
      align: "end",
    };
  }
  return environment.requireJson("EXPLORER_QUERY_LIMIT_OPTIONS").map(makeMenuItem);
});
</script>
