import { Auth0Plugin, User as Auth0User, createAuth0, createAuthGuard } from "@auth0/auth0-vue";
import { isObject } from "lodash";
import { App, unref } from "vue";
import { RouteLocationNormalized } from "vue-router";
import { environment } from "../environments/environmentLoader";
import { sleep, waitFor } from "../lib/asynchronous";
import { setCookie } from "../lib/cookie";
import { User } from "../lib/user";
import { Sentry } from "../monitoring/sentry/sentry";
import { AuthProvider } from "./authProvider";

/**
 * Auth Provider backed by Auth0.
 */
export class Auth0AuthProvider implements AuthProvider {
  private _auth0?: Auth0Plugin;

  private getRedirectUrl(): string {
    return `${location.origin}${environment.require("AUTH_REDIRECT_PAGE")}`;
  }

  constructor(
    private sentry: Sentry | undefined,
    app: App
  ) {
    this._auth0 = createAuth0({
      domain: environment.require("AUTH0_DOMAIN"),
      clientId: environment.require("AUTH0_CLIENT_ID"),
      cacheLocation: "localstorage",
      useRefreshTokens: true,
      authorizationParams: {
        audience: environment.require("AUTH0_AUDIENCE"),
        redirect_uri: this.getRedirectUrl(),
      },
    });
    app.use(this._auth0);
  }

  public async authGuard(to: RouteLocationNormalized): Promise<boolean> {
    const target = environment.require("AUTH_REDIRECT_BASE_DIR");
    const authGuard = createAuthGuard({
      redirectLoginOptions: { appState: { target } },
    });
    const success = await authGuard(to);
    if (!success && location.pathname !== environment.require("AUTH_REDIRECT_PAGE")) {
      setCookie("ct_redirect_url", location.href, 60);
    }
    return success;
  }

  public get isAuthenticated(): boolean {
    return unref(this.auth0.isAuthenticated);
  }

  public async getAccessToken(): Promise<string> {
    try {
      const userId = await this.auth0.getAccessTokenSilently();
      this.updateUser();
      return userId;
    } catch (error: unknown) {
      if (!isMissingRefreshToken(error)) {
        throw error;
      }
      // Redirect to login
      return this.handleAuthenticationError();
    }
  }

  public async loginWithRedirect(): Promise<never> {
    const target = environment.require("AUTH_REDIRECT_BASE_DIR");
    await this.auth0.loginWithRedirect({
      appState: { target },
    });
    // Okay, this is a pain.
    // The above redirect doesn't get executed immediately, and we need to return something here.
    // If we throw an exception, it causes the frontend to briefly display an error message.
    // So we wait a small amount of time to allow the above to complete, THEN throw.
    await sleep(5000);
    throw new Error("Expected redirect");
  }

  public logout(returnTo: string): Promise<void> {
    this.sentry?.clearUser();
    const logoutParams = { returnTo };
    return this.auth0.logout({ logoutParams });
  }

  private get user(): User | undefined {
    const auth0User: Auth0User = this.auth0.user;
    const user: User = {
      user_id: auth0User.sub!,
      email: auth0User.email!,
      email_verified: auth0User.email_verified!,
      name: auth0User.name!,
      picture: auth0User.picture,
      global_admin: false,
    };
    return user;
  }

  public get isLoading(): boolean {
    return unref(this.auth0.isLoading);
  }

  public get isEnabled(): boolean {
    return true;
  }

  public async waitAuthenticated(): Promise<boolean> {
    await waitFor(() => !this.isLoading);
    return this.isAuthenticated;
  }

  public handleAuthenticationError(): Promise<never> {
    if (location.pathname !== environment.require("AUTH_REDIRECT_PAGE")) {
      setCookie("ct_redirect_url", location.href, 60);
    }
    return this.loginWithRedirect();
  }

  public tryRefreshToken(): Promise<boolean> {
    return Promise.resolve(false);
  }

  private updateUser() {
    this.sentry?.setUser({
      id: this.user?.user_id,
      email: this.user?.email,
      name: this.user?.name,
    });
  }

  private get auth0(): Auth0Plugin {
    if (!this._auth0) {
      throw new Error("auth0 not initialized");
    }
    return this._auth0;
  }
}

function isMissingRefreshToken(error: unknown): boolean {
  return (
    isObject(error) &&
    error !== null &&
    "error" in error &&
    (error as Record<"error", unknown>).error === "missing_refresh_token"
  );
}
