// Validates a query against a metagraph, returning a list of reasons why it can't
// be loaded as-is. An empty list means it should be good to go. This list is
// meant to be sent back to an LLM that created the query in the first place
// so it can hopefully fix its mistakes. This could also be used to validate
// a user-created query against schema drift, but the actual strings are not
// worth showing to the user.
//
// This function assumes that the query is *structurally* valid, i.e. matches
// its type, and internally consistent. It returns errors for mismatches between
// the query's references and what's available in the metagraph. If you pass it
// a structurally broken or self-inconsistent query, it will throw runtime

import {
  bind,
  compact,
  isArray,
  isBoolean,
  isNumber,
  isObject,
  isString,
  isUndefined,
  last,
} from "lodash";
import useGraph from "../composables/useGraph";
import { PropertyOpType, propertyValueType, TimeUnit } from "./derived";
import {
  FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE,
  FILTER_VALUE_TYPES_FOR_PROPERTY_VALUE_TYPE,
  FilterType,
} from "./fetchApi";
import { Graph } from "./graph";
import {
  COMPOSITE_PROPERTY_VALUE_TYPE,
  ConceptKnowledgeRef,
  PropertyKnowledgeRef,
} from "./knowledge";
import {
  BaseQuery,
  findQueryBranchByAlias,
  Query,
  QueryBranch,
  QueryFilter,
  QueryPathNode,
  TextFilterMatch,
} from "./query";
import { QueryPropertyTerm } from "./queryProperties";
import { GraphValue, GraphValueType } from "./value";

// errors or at least produce invalid results.
export function validateQuery(query: Query, metagraph: Graph) {
  const { getConceptsOfType } = useGraph(() => metagraph);
  // Validate root_concept_type
  const root = query.root_concept_type;
  if (getConceptsOfType(root).length == 0) {
    // Just return - nothing further useful to do
    return [`Root concept type ${query.root_concept_type} not found`];
  }
  return validateBranch(query, root, metagraph, query);
}

function validateBranch(
  query: BaseQuery,
  root: ConceptKnowledgeRef,
  metagraph: Graph,
  rootQuery: Query
) {
  const problems: string[] = [];

  // Validate columns
  for (const column of query.columns) {
    const errs = validatePropertyTerm(
      metagraph,
      root,
      column.path,
      rootQuery,
      column.property_type
    );
    problems.push(...errs.map((e) => `Column ${column.alias}: ${e}`));
  }

  // Validate filters
  for (const filter of query.filters) {
    let refErrs: string[] = [];
    let valueType: GraphValueType | typeof COMPOSITE_PROPERTY_VALUE_TYPE;
    if (filter.property_type != null) {
      refErrs = validatePathReference(metagraph, root, filter.path, [filter.property_type]);
      valueType = propertyValueType(filter.property_type);
    } else if (filter.on != null) {
      const col = query.columns.find((col) => col.alias === filter.on);
      if (col == null) {
        refErrs.push(`Filter: no column with alias "${filter.on}"`);
      } else {
        valueType = propertyValueType(col.property_type);
      }
    } else {
      refErrs.push("Filter has neither a property_type nor an alias");
    }
    // No alias included in these error messages because the LLM won't know what they mean
    if (refErrs.length) {
      problems.push(...refErrs.map((e) => `Filter: ${e}`));
      // Unsafe to try to validate filter on a missing property
    } else {
      problems.push(...validateFilter(filter, valueType!));
    }
  }

  // Validate group_by
  if (isArray(query.group_by)) {
    for (const gb of query.group_by) {
      const col = query.columns.find((col) => col.alias === gb);
      if (col == null) problems.push(`Group by: no column with alias "${gb}"`);
    }
  }

  // Validate order_by
  if (isArray(query.order_by)) {
    for (const ob of query.order_by) {
      const col = query.columns.find((col) => col.alias === ob.on);
      if (col == null) problems.push(`Order by: no column with alias "${ob.on}"`);
    }
  }

  // Validate branches
  for (const branch of query.branches ?? []) {
    if (branch.path.length == 0) {
      problems.push(`Branch ${branch.alias} has empty path`);
      continue;
    }
    const pathErrors = validatePathReference(metagraph, root, branch.path, []);
    if (pathErrors.length > 0) {
      problems.push(...pathErrors.map((e) => `In branch ${branch.alias}: path: ${e}`));
      continue;
    }
    const newRoot = last(branch.path)!.concept_type;
    problems.push(
      ...validateBranch(branch, newRoot, metagraph, rootQuery).map(
        (e) => `In branch ${branch.alias}: ${e}`
      )
    );
  }

  return compact(problems);
}

function validatePropertyTerm(
  metagraph: Graph,
  rootConceptType: ConceptKnowledgeRef,
  path: QueryPathNode[] = [],
  rootQuery: Query,
  propType: QueryPropertyTerm
): string[] {
  if (isString(propType)) {
    return validatePathReference(metagraph, rootConceptType, path, [propType]);
  }
  if (!Object.values(PropertyOpType).includes(propType.op)) {
    return [`invalid op "${propType.op}"`];
  }
  // Partially apply this function so it's easy to work with recursively
  const furtherValidate: (t: QueryPropertyTerm) => string[] = bind(
    validatePropertyTerm,
    null,
    metagraph,
    rootConceptType,
    path,
    rootQuery
  );

  let localRoot: ConceptKnowledgeRef;
  let localPath: QueryPathNode[];

  switch (propType.op) {
    case PropertyOpType.Sum:
    case PropertyOpType.Avg:
    case PropertyOpType.Median:
    case PropertyOpType.Min:
    case PropertyOpType.Max:
      return furtherValidate(propType.term);
    case PropertyOpType.Ntile:
      return compact([
        !isNumber(propType.n) ? "n must be a number" : null,
        ...furtherValidate(propType.term),
      ]);
    case PropertyOpType.DateTrunc:
      return compact([
        !Object.values(TimeUnit).includes(propType.bucket_size.toUpperCase() as TimeUnit)
          ? "invalid bucket_size"
          : null,
        ...furtherValidate(propType.term),
      ]);
    case PropertyOpType.Percentile:
      return compact([
        !isNumber(propType.percentage) ? "percentage must be a number" : null,
        ...furtherValidate(propType.term),
      ]);
    case PropertyOpType.Map:
      return compact([
        !isArray(propType.mappings) ? "invalid mappings" : null, // pretty lame
        ...furtherValidate(propType.term),
      ]);
    case PropertyOpType.Add:
    case PropertyOpType.Subtract:
      return propType.terms.flatMap(furtherValidate);
    case PropertyOpType.Multiply:
      return propType.factors.flatMap(furtherValidate);
    case PropertyOpType.Divide:
      return [propType.dividend, propType.divisor].flatMap(furtherValidate);
    case PropertyOpType.DateDiff:
      return [propType.start, propType.end].flatMap(furtherValidate);
    case PropertyOpType.Count:
      if (propType.on_neighbor != null) {
        if (propType.on_neighbor.branch != null) {
          const branch = findQueryBranchByAlias(rootQuery, propType.on_neighbor.branch);
          if (branch == null) return [`no such branch ${propType.on_neighbor.branch}`];
          localRoot = last((branch as QueryBranch).path)!.concept_type;
          localPath = propType.on_neighbor.path ?? [];
        } else {
          localRoot = rootConceptType;
          localPath = [...path, ...(propType.on_neighbor.path ?? [])];
        }
        return validatePathReference(metagraph, localRoot, localPath, []);
      }
      return [];
    case PropertyOpType.Property: // Frustrating to repeat a chunk of the above but it's torturous not to
      if (propType.on_neighbor != null) {
        if (propType.on_neighbor.branch != null) {
          const branch = findQueryBranchByAlias(rootQuery, propType.on_neighbor.branch);
          if (branch == null) return [`no such branch ${propType.on_neighbor.branch}`];
          localRoot = last((branch as QueryBranch).path)!.concept_type;
          localPath = propType.on_neighbor.path ?? [];
        } else {
          localRoot = rootConceptType;
          localPath = [...path, ...(propType.on_neighbor.path ?? [])];
        }
        return validatePropertyTerm(
          metagraph,
          localRoot,
          localPath,
          rootQuery,
          propType.property_type
        );
      }
      return furtherValidate(propType.property_type);
  }
}

function validatePathReference(
  metagraph: Graph,
  rootConceptType: ConceptKnowledgeRef,
  path: QueryPathNode[] = [],
  propertyTypes: PropertyKnowledgeRef[]
): string[] {
  const { getConceptsOfType, getLinkPartners, getConcept } = useGraph(() => metagraph);
  let currentConcept = getConceptsOfType(rootConceptType)[0];
  for (const node of path) {
    if (node.link_descriptor == null) return ["Each path node must have a link_descriptor"];
    const conceptTypeSought = node.concept_type;
    const partner = getLinkPartners(currentConcept.id, node.link_descriptor)
      .map(getConcept)
      .find((partnerConcept) => partnerConcept.type === conceptTypeSought);
    if (partner == null) {
      return [`${currentConcept.type} has no ${node.link_descriptor} link to ${conceptTypeSought}`];
    } else {
      currentConcept = partner;
    }
  }
  // Now that we've found the concept, validate property types
  const problems: string[] = [];
  for (const ptype of propertyTypes) {
    if ((currentConcept.properties ?? []).find((p) => p.type === ptype) == null) {
      problems.push(`${currentConcept.type} has no property ${ptype}`);
    }
  }
  return problems;
}

function validateFilter(
  filter: QueryFilter,
  valueType: GraphValueType | typeof COMPOSITE_PROPERTY_VALUE_TYPE
) {
  const problems: string[] = [];
  const validFilterTypes = FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE[valueType];
  if (!validFilterTypes.includes(filter.type)) {
    problems.push(
      `Filter on ${filter.property_type} has invalid type ${filter.type} - properties of type ${valueType} only support filters of types: ${validFilterTypes.join(",")}`
    );
  }
  if (filter.type != FilterType.Exists) {
    for (const values of filter.values) {
      for (const [key, value] of Object.entries(values)) {
        if (isObject(value) && "_type" in value) {
          const validValueTypes = FILTER_VALUE_TYPES_FOR_PROPERTY_VALUE_TYPE[valueType];
          const filterValueType = (value as GraphValue)._type;
          if (!validValueTypes.includes(filterValueType)) {
            problems.push(
              `Filter on ${filter.property_type ?? filter.on} has value of type ${filterValueType}, a mismatch with the property's value type (${valueType})`
            );
          }
        } else if (
          key === "match" &&
          !(isString(value) && Object.values(TextFilterMatch).includes(value as TextFilterMatch))
        ) {
          problems.push(`Filter on ${filter.property_type} has invalid 'match' value ${value}`);
        } else if (key === "case_sensitive" && !(isUndefined(value) || isBoolean(value))) {
          problems.push(
            `Filter on ${filter.property_type ?? filter.on} has invalid 'case_sensitive' value ${value}`
          );
        }
      }
    }
  }
  return problems;
}
