<template>
  <Dropdown placement="auto">
    <template #popper><Menu :items="menuItems" v-close-popper /></template>
    <div
      class="flex cursor-pointer items-baseline"
      :class="{
        'justify-end': quantitative,
        underline: hasConceptPage,
      }"
    >
      <div
        v-if="color"
        :style="{ backgroundColor: color }"
        class="mr-4 h-10 w-10 rounded-full"
      ></div>
      <GraphValueComponent :value="value.formattedValue" />
      <div class="pl-10" v-if="hasBarChart">
        <MiniBar :value="value.originalValue as FloatValue" :max="max" />
      </div>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Menu from "@/common/components/Menu.vue";
import { asyncValue } from "@/common/lib/async";
import { Dropdown } from "floating-vue";
import { computed, toRefs } from "vue";
import { propertyValueMenu } from "../../lib/exploreMenus";
import { useExploreStore } from "../../stores/explore";
import MiniBar from "../MiniBar.vue";
import { last } from "lodash";
import { conceptPropertyValueDisplay, uniquelyIdentifiesConcept } from "../../lib/concept";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { FloatValue, toNative } from "@/common/lib/value";
import { columnIsDerived, QueryColumn } from "@/common/lib/query";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";

const props = defineProps<{
  value: ValueWithFormattedValue;
  column?: QueryColumn;
  quantitative: boolean;
}>();
const { column, value } = toRefs(props);

const exploreStore = useExploreStore();

function conceptType() {
  if (column.value == null) return null;
  return column.value.path == null
    ? exploreStore.query!.root_concept_type
    : last(column.value.path)!.concept_type;
}

const menuItems = computed(() =>
  propertyValueMenu(value.value.originalValue, value.value.formattedValue, column?.value)
);

const hasBarChart = computed(
  () => column?.value != null && asyncValue(exploreStore.columnStats)![column.value.alias] != null
);

const hasConceptPage = computed(function () {
  if (column.value == null) return false;
  if (columnIsDerived(column.value)) return false;
  return uniquelyIdentifiesConcept(
    conceptType()!,
    column.value.property_type as PropertyKnowledgeRef
  );
});

const max = computed(() => {
  const stats = asyncValue(exploreStore.columnStats)![column.value?.alias ?? 0];
  return toNative(stats.max) as number;
});

const color = computed(function () {
  if (column.value == null || columnIsDerived(column.value)) return null;
  const ctype = conceptType();
  if (ctype == null) return null;
  const display = conceptPropertyValueDisplay(
    ctype,
    column.value.property_type as PropertyKnowledgeRef,
    value.value.originalValue
  );
  return display?.color ?? null;
});
</script>
