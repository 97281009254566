import { defineComponent as _defineComponent } from 'vue'
import { generateValue } from "@/reader/lib/visualization";
import { computed, ref, Ref, toRefs, watchEffect } from "vue";
import { UseQueryResult } from "@/reader/composables/useQuery";
import * as d3Core from "d3";
import * as d3Sankey from "d3-sankey";
import {
  FloatValue,
  GraphValue,
  isNumeric,
  isValue,
  stringifyValue,
  toNative,
  toValue,
} from "@/common/lib/value";
import { compact } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { TRANSFORMERS } from "@/common/lib/format";
import { SankeyVisualization } from "@/reader/lib/visualizationTypes";
import { combinedQuerySignature } from "@/common/lib/combiningQuery";

interface SNodeExtra {
  name: string;
}

interface SLinkExtra {
  id: string;
}

interface DAG {
  nodes: SNode[];
  links: SLink[];
}

type SNode = d3Sankey.SankeyNode<SNodeExtra, SLinkExtra>;
type SLink = d3Sankey.SankeyLink<SNodeExtra, SLinkExtra>;


export default /*@__PURE__*/_defineComponent({
  __name: 'Sankey',
  props: {
    visualization: { type: Object, required: true },
    results: { type: Array, required: true },
    width: { type: Number, required: true },
    height: { type: Number, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { visualization, results, width, height } = toRefs(props);

const svgEl: Ref<SVGElement | null> = ref(null);

const data = computed(function (): DAG {
  const row = results.value[0];
  const query = combinedQuerySignature(visualization.value.query);
  const links = compact(
    visualization.value.config.links.map(function (link) {
      const value = generateValue(link.value, row, query);
      if (
        value == null ||
        !isValue(value.originalValue) ||
        !isNumeric(value.originalValue as GraphValue)
      )
        return null;
      return {
        id: uuidv4(),
        source: link.from,
        target: link.to,
        value: toNative(value.originalValue as FloatValue),
      };
    })
  );
  const nodes = Array.from(new Set(links.flatMap((l) => [l.source, l.target])), (name) => ({
    name,
  }));
  return { nodes, links };
});

// A purely hierarchical sort. Within a fanout, sort is from biggest to smallest
function nodeSort(n1: SNode, n2: SNode): number {
  const n1parent = n1.targetLinks?.[0]?.source as SNode;
  const n2parent = n2.targetLinks?.[0]?.source as SNode;
  if (n1parent != null && n2parent != null) {
    const parentComparison = nodeSort(n1parent, n2parent);
    if (parentComparison !== 0) return parentComparison;
  }
  return Math.sign(n2.value! - n1.value!);
}

function formatValue(value?: number) {
  if (value == null) return "";
  const gvalue = toValue(value);
  const transformer = visualization.value.config.transformer;
  if (transformer == null) {
    return stringifyValue(gvalue);
  } else {
    return stringifyValue(TRANSFORMERS[transformer](gvalue));
  }
}

watchEffect(function () {
  if (svgEl.value == null) return;
  const d3 = { ...d3Core, ...d3Sankey };
  svgEl.value.replaceChildren();

  const svg = d3.select(svgEl.value);

  const sankey: d3Sankey.SankeyLayout<DAG, SNodeExtra, SLinkExtra> = d3
    .sankey<DAG, SNodeExtra, SLinkExtra>()
    .nodeId((d) => d.name)
    .nodeAlign(d3.sankeyLeft)
    .nodeWidth(10)
    .nodeSort(nodeSort)
    .nodePadding(10)
    .extent([
      [1, 5],
      [width.value - 1, height.value - 5],
    ]);

  // Applies it to the data. We make a copy of the nodes and links objects
  // so as to avoid mutating the original.
  const { nodes, links } = sankey({
    nodes: data.value.nodes,
    links: data.value.links,
  });

  // Defines a color scale.
  const color = d3.scaleOrdinal(d3.schemeCategory10);

  // Creates the rects that represent the nodes.
  const rect = svg
    .append("g")
    .selectAll()
    .data(nodes)
    .join("rect")
    .attr("x", (d) => d.x0!)
    .attr("y", (d) => d.y0!)
    .attr("height", (d) => d.y1! - d.y0!)
    .attr("width", (d) => d.x1! - d.x0!)
    .attr("stroke", (d) => color(d.name))
    .attr("fill", (d) => color(d.name));

  // Adds a title on the nodes.
  rect.append("title").text((d) => `${d.name}\n${formatValue(d.value)}`);

  // Creates the paths that represent the links.
  const link = svg
    .append("g")
    .attr("fill", "none")
    .attr("stroke-opacity", 0.5)
    .selectAll()
    .data(links)
    .join("g")
    .style("mix-blend-mode", "multiply");

  const gradient = link
    .append("linearGradient")
    .attr("id", (d) => d.id)
    .attr("gradientUnits", "userSpaceOnUse")
    .attr("x1", (d) => (d.source as SNode).x1!)
    .attr("x2", (d) => (d.target as SNode).x0!);
  gradient
    .append("stop")
    .attr("offset", "0%")
    .attr("stop-color", (d) => color((d.source as SNode).name));
  gradient
    .append("stop")
    .attr("offset", "100%")
    .attr("stop-color", (d) => color((d.target as SNode).name));

  link
    .append("path")
    .attr("d", d3.sankeyLinkHorizontal())
    .attr("stroke", (d) => `url(#${d.id})`)
    .attr("stroke-width", (d) => Math.max(1, d.width!));

  link
    .append("title")
    .text(
      (d) => `${(d.source as SNode).name} → ${(d.target as SNode).name}\n${formatValue(d.value)}`
    );

  // Adds labels on the nodes.
  svg
    .append("g")
    .selectAll()
    .data(nodes)
    .join("text")
    .attr("x", (d) => (d.x0! < width.value / 2 ? d.x1! + 6 : d.x0! - 6))
    .attr("y", (d) => (d.y1! + d.y0!) / 2)
    .attr("dy", "0.35em")
    .attr("text-anchor", (d) => (d.x0! < width.value / 2 ? "start" : "end"))
    .text((d) => d.name);
});

const __returned__ = { props, visualization, results, width, height, svgEl, data, nodeSort, formatValue }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})