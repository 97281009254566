<template>
  <div class="my-10">
    <div class="uppercase text-gray-500">{{ propTypeLabel }}</div>
    <div class="values">
      <div
        v-for="({ value, color }, index) in valueDetails"
        :key="index"
        class="flex items-baseline"
      >
        <div
          v-if="color"
          :style="{ backgroundColor: color }"
          class="mr-4 h-10 w-10 rounded-full"
        ></div>
        <GraphValueComponent :value="value" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useKnowledge from "@/common/composables/useKnowledge";
import { computed, toRefs } from "vue";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { conceptPropertyValueDisplay } from "@/reader/lib/concept";

const props = defineProps<{
  values: ValueWithFormattedValue[];
  conceptType: ConceptKnowledgeRef;
  propertyType: PropertyKnowledgeRef;
}>();
const { values, propertyType, conceptType } = toRefs(props);

const { typeLabel } = useKnowledge();

const propTypeLabel = computed(() => typeLabel(propertyType.value));

const valueDetails = computed(function () {
  return values.value.map(function (value) {
    const display = conceptPropertyValueDisplay(
      conceptType.value,
      propertyType.value,
      value.originalValue
    );
    return {
      value: value.formattedValue,
      color: display?.color ?? null,
    };
  });
});
</script>
