import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { FilterType } from "@/common/lib/fetchApi";
import {
  GraphCompoundValue,
  GraphConcept,
  invertLinkDescriptor,
  LinkDescriptor,
  linkPartner,
  stringifyProperty,
} from "@/common/lib/graph";
import { ConceptKnowledgeRef, PropertyKnowledgeRef, ROLE_LINK_TYPE } from "@/common/lib/knowledge";
import { getMapClausesWhere, MapSectionKey } from "@/common/lib/map";
import { Query } from "@/common/lib/query";
import { GraphValue, isValue, stringifyValue } from "@/common/lib/value";
import { every, isEqual, isString, sortBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useAppStore } from "../stores/app";

type ConceptKeySet = Record<PropertyKnowledgeRef, GraphValue>;

export interface ConceptAddress {
  conceptType: ConceptKnowledgeRef;
  keys: ConceptKeySet;
}

interface ConceptDisplayTitleInterpolation {
  property_type: PropertyKnowledgeRef; // More parameters to come
}

type ConceptDisplayTitle = Array<string | ConceptDisplayTitleInterpolation>;

interface ConceptDisplayPropertyValue {
  value: GraphValue;
  color?: string; // e.g. #955b6b
  displayValue?: GraphValue;
}

interface ConceptDisplayProperty {
  values?: ConceptDisplayPropertyValue[];
}

export interface ConceptDisplay {
  titles?: ConceptDisplayTitle[];
  properties?: Record<PropertyKnowledgeRef, ConceptDisplayProperty>;
}

export function buildConceptQuery(address: ConceptAddress): Query {
  const { getConceptsOfType } = useGraph(() => useAppStore().metagraph);
  const concept = getConceptsOfType(address.conceptType)[0];
  return {
    root_concept_type: address.conceptType,
    filters: Object.entries(address.keys).map(([pt, value]) => ({
      alias: uuidv4(),
      type: FilterType.Equality,
      property_type: pt as PropertyKnowledgeRef,
      values: [{ value: value }],
      negated: false,
    })),
    columns: (concept.properties ?? []).map((prop) => ({
      alias: uuidv4(),
      property_type: prop.type,
    })),
    order_by: [],
    group_by: [],
    size: 1,
  };
}

export function buildNeighborhoodQueries(address: ConceptAddress) {
  const { getLinksWith, getConcept, getConceptsOfType } = useGraph(() => useAppStore().metagraph);
  const concept = getConceptsOfType(address.conceptType)[0];
  const queries: Record<string, Query> = {};
  for (const link of getLinksWith(concept.id)) {
    const partner = linkPartner(link, concept.id);
    if (partner == null) continue; // Don't handle self-links
    let linkDescriptor = LinkDescriptor.RelatedTo;
    let neighborConcept;
    if (link.from === concept.id) {
      if (link.type === ROLE_LINK_TYPE) linkDescriptor = LinkDescriptor.AsA;
      neighborConcept = getConcept(link.to);
    } else {
      if (link.type === ROLE_LINK_TYPE) linkDescriptor = LinkDescriptor.RoleOf;
      neighborConcept = getConcept(link.from);
    }
    const props = (neighborConcept.properties ?? []).map((p) => p.type);
    if (props.length == 0) continue; // If we can't construct a title or a link, no point in including this
    queries[`${linkDescriptor}-${neighborConcept.type}`] = {
      root_concept_type: neighborConcept.type,
      columns: props.map((prop) => ({ alias: uuidv4(), property_type: prop })),
      filters: Object.entries(address.keys).map(([pt, value]) => ({
        alias: uuidv4(),
        type: FilterType.Equality,
        property_type: pt as PropertyKnowledgeRef,
        path: [
          {
            link_descriptor: invertLinkDescriptor(linkDescriptor),
            concept_type: address.conceptType,
          },
        ],
        values: [{ value: value }],
        negated: false,
      })),
      order_by: [],
      group_by: [],
      size: 10,
    };
  }
  return queries;
}

// Would a property of the passed type uniquely identify a concept of the
// passed type (would it form a concept address or filter to a single concept)?
export function uniquelyIdentifiesConcept(
  conceptType: ConceptKnowledgeRef,
  propertyType: PropertyKnowledgeRef
) {
  const keySet = keySetsForConceptType(conceptType).find(
    (ks) => ks.length == 1 && ks[0] === propertyType
  );
  return !!keySet;
}

export function conceptAddress(concept: GraphConcept): ConceptAddress | undefined {
  const keySets = keySetsForConceptType(concept.type);
  for (const keySet of keySets) {
    const keys: Record<string, unknown> = {};
    for (const ptype of keySet) {
      const prop = (concept.properties ?? []).find((p) => p.type === ptype);
      keys[ptype] = prop?.value;
    }
    if (every(Object.values(keys), (k) => isValue(k))) {
      return { conceptType: concept.type, keys: keys as ConceptKeySet };
    }
  }
  return undefined;
}

export function readerConceptTitle(concept: GraphConcept) {
  // Side effect: saves the title in the appStore cache, if possible
  const appStore = useAppStore();

  function generate() {
    const display = appStore.conceptDisplays[concept.type] ?? {};
    const props = concept.properties ?? [];
    for (const titleTemplate of display.titles ?? []) {
      const strings: string[] = [];
      for (const element of titleTemplate) {
        if (isString(element)) {
          strings.push(element);
        } else {
          const prop = props.find((prop) => prop.type === element.property_type);
          if (prop != undefined) strings.push(stringifyProperty(prop));
        }
      }
      if (strings.length === titleTemplate.length) return strings.join("");
    }
    return useKnowledge().conceptTitle(concept);
  }

  const title = generate();
  const address = conceptAddress(concept);
  if (address != null) appStore.conceptTitleCache[conceptAddressToString(address)] = title;
  return title;
}

export function conceptAddressToString(address: ConceptAddress) {
  const parts: string[] = [address.conceptType];
  for (const [pt, v] of sortBy(Object.entries(address.keys), ([pt]) => pt)) {
    parts.push("[", pt, "=", encodeURIComponent(stringifyValue(v)), "]");
  }
  return parts.join("");
}

export function conceptPropertyValueDisplay(
  conceptType: ConceptKnowledgeRef,
  propertyType: PropertyKnowledgeRef,
  value: GraphValue | GraphCompoundValue
) {
  if (!isValue(value)) return null;
  const appStore = useAppStore();
  const values = appStore.conceptDisplays[conceptType]?.properties?.[propertyType]?.values;
  if (values == null) return null;
  return values.find((v) => isEqual(v.value, value)) ?? null;
}

// Which sets of properties can be used as ConceptAddress keys for a concept type?
function keySetsForConceptType(conceptType: ConceptKnowledgeRef) {
  const appStore = useAppStore();
  const resolutions = getMapClausesWhere(
    appStore.map,
    MapSectionKey.Resolutions,
    (c) => c.type === conceptType
  );
  return Object.values(resolutions).map((r) => r.on);
}
