import { defineComponent as _defineComponent } from 'vue'
import { FinComputedCell, FinComputedRow } from "../FinancialReport.vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Icon from "@/common/components/Icon.vue";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FinancialSubreport',
  props: {
    rows: { type: Array, required: true },
    columns: { type: Array, required: true },
    depth: { type: Number, required: true },
    expandedMap: { type: Set, required: true }
  },
  emits: ["update-expanded"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const { depth, expandedMap } = toRefs(props);

const emits = __emit;

function cells(row: FinComputedRow): FinComputedCell[] {
  return props.columns.map((_, index) => row.cells[index]);
}

function expandMap(rowLabel: string) {
  emits("update-expanded", rowLabel);
}

const indentStyle = computed(() => ({ marginLeft: `${15 * depth.value}px` }));

const __returned__ = { props, depth, expandedMap, emits, cells, expandMap, indentStyle, GraphValueComponent, Icon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})