import { defineComponent as _defineComponent } from 'vue'
import { isNil } from "lodash";
import { useNavigation } from "../composables/useNavigation";
import { environment } from "../environments/environmentLoader";
import { httpClient } from "../http/http";
import { useWorkspaceStore } from "../stores/workspaceStore";
import TextButton from "./TextButton.vue";
import { onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import IconButtonV2 from "./IconButtonV2.vue";

interface QuickBooksConnection {
  company_name: string;
  realm_id: string;
  access_token: string | undefined;
  refresh_token: string | undefined;
  created_by: string;
  modified_by: string;
  created: string;
  modified: string;
}

interface QuickBooksPreAuthResponse {
  code: string;
}

interface GetQuickBooksConnectionsResponse {
  connections: QuickBooksConnection[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'QuickBooksTab',
  setup(__props, { expose: __expose }) {
  __expose();

const { currentWorkspaceId } = storeToRefs(useWorkspaceStore());

const connections = ref<QuickBooksConnection[]>([]);

async function connectToQuickBooks() {
  const connectUrl = environment.require("QUICKBOOKS_CONNECT_URL");
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  // Preauthorize first
  const body = {
    workspace_id: workspaceId,
    redirect_url: window.location.href,
  };
  const response = await httpClient.post<QuickBooksPreAuthResponse>(
    `/api/quickbooks/${workspaceId}/authorize`,
    body
  );
  const code = response.data.code;
  return useNavigation().goToUrl(`${connectUrl}?request_code=${code}`);
}

async function getQuickBooksConnections(workspaceId: string) {
  const response = await httpClient.get<GetQuickBooksConnectionsResponse>(
    `/api/quickbooks/${workspaceId}/connections`
  );
  return response.data.connections;
}

async function refreshConnection(connection: QuickBooksConnection) {
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  await httpClient.post(`/api/quickbooks/${workspaceId}/realms/${connection.realm_id}/refresh`);
  connections.value = await getQuickBooksConnections(workspaceId);
}

async function disconnect(connection: QuickBooksConnection) {
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  await httpClient.post(`/api/quickbooks/${workspaceId}/realms/${connection.realm_id}/disconnect`);
  connections.value = await getQuickBooksConnections(workspaceId);
}

watch(currentWorkspaceId, async (workspaceId) => {
  if (isNil(workspaceId)) {
    connections.value = [];
  } else {
    connections.value = await getQuickBooksConnections(workspaceId);
  }
});

onMounted(async () => {
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  connections.value = await getQuickBooksConnections(workspaceId);
});

const __returned__ = { currentWorkspaceId, connections, connectToQuickBooks, getQuickBooksConnections, refreshConnection, disconnect, TextButton, IconButtonV2 }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})