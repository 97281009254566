import { capitalize, isString, last } from "lodash";
import pluralize from "pluralize";
import useKnowledge from "../composables/useKnowledge";
import { COMPOSITE_PROPERTY_VALUE_TYPE, PropertyKnowledgeRef, PropertyType } from "./knowledge";
import { QueryPathNode } from "./query";
import { GraphValue, GraphValueType } from "./value";

// Derived knowledge types for the fetch API

export enum PropertyOpType {
  Sum = "sum",
  Max = "max",
  Min = "min",
  Avg = "avg",
  Median = "median",
  Percentile = "percentile",
  Add = "add",
  Subtract = "subtract",
  DateDiff = "datetime_diff",
  DateTrunc = "date_trunc",
  Divide = "divide",
  Multiply = "multiply",
  Count = "count",
  Ntile = "ntile",
  Map = "map",
  Property = "property",
}

export const AGGREGATE_OP_TYPES = [
  PropertyOpType.Sum,
  PropertyOpType.Max,
  PropertyOpType.Min,
  PropertyOpType.Avg,
  PropertyOpType.Median,
  PropertyOpType.Percentile,
  PropertyOpType.Ntile,
  PropertyOpType.Count,
];

export interface BaseDerivedPropertyType {
  op: PropertyOpType;
}

export interface PropertyReferenceType extends BaseDerivedPropertyType {
  op: PropertyOpType.Property;
  property_type: PropertyKnowledgeRef;
  on_tag?: string;
}

export interface SumPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Sum;
  term: DerivedPropertyTerm;
}

export interface AvgPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Avg;
  term: DerivedPropertyTerm;
}

export interface MedianPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Median;
  term: DerivedPropertyTerm;
}

export interface PercentilePropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Percentile;
  term: DerivedPropertyTerm;
  percentage: number;
  approx: boolean;
}

export interface MaxPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Max;
  term: DerivedPropertyTerm;
}

export interface MinPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Min;
  term: DerivedPropertyTerm;
}

export interface AddPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Add;
  terms: DerivedPropertyTerm[];
}

export interface SubtractPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Subtract;
  terms: DerivedPropertyTerm[];
}

export interface DividePropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Divide;
  divisor: DerivedPropertyTerm;
  dividend: DerivedPropertyTerm;
}

export enum TimeUnit {
  Microsecond = "MICROSECOND",
  Milisecond = "MILLISECOND",
  Second = "SECOND",
  Minute = "MINUTE",
  Hour = "HOUR",
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
  Quarter = "QUARTER",
  Year = "YEAR",
}

export interface DateDiffPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.DateDiff;
  unit: TimeUnit;
  start: DerivedPropertyTerm;
  end: DerivedPropertyTerm;
}

export interface MultiplyPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Multiply;
  factors: DerivedPropertyTerm[];
}

export interface CountPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Count;
  approx: boolean;
  term?: DerivedPropertyTerm;
  on_tag?: string;
}

export interface NtilePropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Ntile;
  term: DerivedPropertyTerm;
  n: number;
}

export interface DateTruncPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.DateTrunc;
  term: DerivedPropertyTerm;
  bucket_size: TimeUnit;
}

export interface MapPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Map;
  term: DerivedPropertyTerm;
  mappings: Array<{ from_value: GraphValue; to_value: GraphValue }>;
}

export type DerivedPropertyType =
  | SumPropertyType
  | AvgPropertyType
  | MedianPropertyType
  | PercentilePropertyType
  | MaxPropertyType
  | MinPropertyType
  | AddPropertyType
  | SubtractPropertyType
  | DividePropertyType
  | DateDiffPropertyType
  | DateTruncPropertyType
  | MultiplyPropertyType
  | CountPropertyType
  | NtilePropertyType
  | MapPropertyType
  | PropertyReferenceType;

export type DerivedPropertyTerm = DerivedPropertyType | PropertyKnowledgeRef;

// Mines the raw property type knowledge references from a derived property
export function underlyingPropertyTypes(dpt: DerivedPropertyTerm): PropertyKnowledgeRef[] {
  if (isString(dpt)) return [dpt];
  switch (dpt.op) {
    case PropertyOpType.Sum:
    case PropertyOpType.Avg:
    case PropertyOpType.Median:
    case PropertyOpType.Percentile:
    case PropertyOpType.Min:
    case PropertyOpType.Max:
    case PropertyOpType.Ntile:
    case PropertyOpType.DateTrunc:
      return underlyingPropertyTypes(dpt.term);
    case PropertyOpType.Property:
      return underlyingPropertyTypes(dpt.property_type);
    case PropertyOpType.Map:
      return underlyingPropertyTypes(dpt.term);
    case PropertyOpType.Add:
    case PropertyOpType.Subtract:
      return dpt.terms.flatMap(underlyingPropertyTypes);
    case PropertyOpType.Multiply:
      return dpt.factors.flatMap(underlyingPropertyTypes);
    case PropertyOpType.Divide:
      return [dpt.dividend, dpt.divisor].flatMap(underlyingPropertyTypes);
    case PropertyOpType.DateDiff:
      return [dpt.start, dpt.end].flatMap(underlyingPropertyTypes);
    case PropertyOpType.Count:
      return [];
  }
}

// Most derived property ops return values of the same type as that of the
// operand property types. Some (count, stddev, etc.) will return a fixed value
// type instead. This function will return null if the derived property value
// type matches the operand. Otherwise, it'll return a value type.
export function valueTypeOfDerivedProperty(dpt: DerivedPropertyType): GraphValueType | null {
  switch (dpt.op) {
    case PropertyOpType.Sum:
    case PropertyOpType.Percentile:
    case PropertyOpType.Min:
    case PropertyOpType.Max:
    case PropertyOpType.Add:
    case PropertyOpType.Subtract:
    case PropertyOpType.Multiply:
    case PropertyOpType.Ntile:
    case PropertyOpType.Property:
      return null;
    case PropertyOpType.Divide:
    case PropertyOpType.Avg:
    case PropertyOpType.Median:
      return GraphValueType.Float;
    case PropertyOpType.Count:
    case PropertyOpType.DateDiff:
      return GraphValueType.Integer;
    case PropertyOpType.DateTrunc:
      return GraphValueType.Datetime;
    case PropertyOpType.Map:
      if (dpt.mappings.length == 0) return null;
      return dpt.mappings[0].to_value._type;
  }
}

export function validDerivedPropertyTermType(
  op: PropertyOpType,
  valueType: GraphValueType | typeof COMPOSITE_PROPERTY_VALUE_TYPE
): boolean {
  if (valueType === COMPOSITE_PROPERTY_VALUE_TYPE) return false;
  switch (op) {
    case PropertyOpType.Sum:
    case PropertyOpType.Avg:
    case PropertyOpType.Median:
    case PropertyOpType.Percentile:
    case PropertyOpType.Add:
    case PropertyOpType.Subtract:
    case PropertyOpType.Multiply:
    case PropertyOpType.Divide:
    case PropertyOpType.Ntile:
      return [GraphValueType.Float, GraphValueType.Integer].includes(valueType);
    case PropertyOpType.Min:
    case PropertyOpType.Max:
      return [
        GraphValueType.Float,
        GraphValueType.Integer,
        GraphValueType.Datetime,
        GraphValueType.Date,
        GraphValueType.Time,
      ].includes(valueType);
    case PropertyOpType.DateDiff:
    case PropertyOpType.DateTrunc:
      return [GraphValueType.Datetime, GraphValueType.Date].includes(valueType);
    case PropertyOpType.Count:
    case PropertyOpType.Map:
    case PropertyOpType.Property:
      return true;
  }
}

export function propertyName(
  propType: DerivedPropertyTerm,
  path?: QueryPathNode[],
  displayName?: string
): string {
  const { typeLabel } = useKnowledge();
  let name: string;
  if (displayName) {
    return displayName;
  } else if (isString(propType)) {
    name = typeLabel(propType);
  } else {
    switch (propType.op) {
      case PropertyOpType.Property:
        name = propertyName(propType.property_type);
        break;
      case PropertyOpType.Sum:
      case PropertyOpType.Avg:
      case PropertyOpType.Median:
      case PropertyOpType.Min:
      case PropertyOpType.Max: {
        const termName = propertyName(propType.term);
        const opName = {
          [PropertyOpType.Sum]: "Total",
          [PropertyOpType.Avg]: "Average",
          [PropertyOpType.Median]: "Median",
          [PropertyOpType.Min]: "Minimum",
          [PropertyOpType.Max]: "Maximum",
        }[propType.op];
        if (isString(propType.term)) {
          name = `${opName} ${termName}`;
        } else {
          name = `${opName} of (${termName})`;
        }
        break;
      }
      case PropertyOpType.Percentile:
        if (isString(propType.term)) {
          name = `${propType.percentage} Percentile ${propertyName(propType.term)}`;
        } else {
          name = `${propType.percentage} Percentile of (${propertyName(propType.term)})`;
        }
        break;
      case PropertyOpType.Add:
        name = propType.terms.map((p) => propertyName(p)).join(" + ");
        break;
      case PropertyOpType.Subtract:
        name = propType.terms.map((p) => propertyName(p)).join(" - ");
        break;
      case PropertyOpType.Multiply:
        name = propType.factors.map((p) => propertyName(p)).join(" × ");
        break;
      case PropertyOpType.Divide:
        name = `${propertyName(propType.dividend)} / ${propertyName(propType.divisor)}`;
        break;
      case PropertyOpType.DateDiff:
        name = `${capitalize(propType.unit)}s(${propertyName(propType.end)} - ${propertyName(propType.start)})`;
        break;
      case PropertyOpType.DateTrunc:
        name = `${propertyName(propType.term)} ${capitalize(propType.bucket_size)}`;
        break;
      case PropertyOpType.Count:
        if (propType.term != null) {
          name = `Distinct ${pluralize(propertyName(propType.term))}`;
        } else {
          name = "Count";
        }
        break;
      case PropertyOpType.Ntile:
        name = `${propertyName(propType.term)} ${propType.n}tile`;
        break;
      case PropertyOpType.Map:
        name = `${propertyName(propType.term)} (Mapped)`;
        break;
    }
  }
  if (path != null) {
    const neighborConcept = last(path)!.concept_type;
    return `${typeLabel(neighborConcept)} ${name}`;
  }
  return name;
}

export function propertyValueType(dpt: DerivedPropertyTerm) {
  // TODO This should dig deeper into nested derived properties.
  const { getKnowledgeItem } = useKnowledge();
  const underlyingProps = underlyingPropertyTypes(dpt);
  if (underlyingProps.length == 0) return GraphValueType.Integer; // Handle counts
  const prop = underlyingProps[0];
  if (isString(dpt)) {
    return (getKnowledgeItem(prop) as PropertyType).value_type!;
  } else {
    return valueTypeOfDerivedProperty(dpt) ?? (getKnowledgeItem(prop) as PropertyType).value_type!;
  }
}

// Suggests the value type for filters created on the underlying property
export function filterValueType(dpt: DerivedPropertyTerm) {
  const { getKnowledgeItem } = useKnowledge();
  const prop = underlyingPropertyTypes(dpt)[0];
  return (getKnowledgeItem(prop) as PropertyType).value_type!;
}
