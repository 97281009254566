<template>
  <div class="input peer flex w-full flex-col items-start self-stretch">
    <label
      v-if="label && (hasSelectedValues || placeholder)"
      class="text-s pb-5 pr-0 pt-5 font-bold text-gray-500 peer-focus:text-orange"
    >
      {{ label }}<span v-if="required" class="text-orange"> *</span>
    </label>
    <v-select
      class="dropdown-options self-stretch"
      :options="options"
      :model-value="delaySave ? selectedValues : modelValue"
      @update:modelValue="handleUpdate"
      :placeholder="placeholder || label"
      :label="isString(optionLabel) ? optionLabel : undefined"
      :get-option-label="isFunction(optionLabel) ? optionLabel : undefined"
      :reduce="optionValue"
      :disabled="disabled"
      :clearable="clearable"
      :multiple="multiple"
      :filter="filter"
    >
      <template v-slot:option="option: any">
        <slot name="option-data" v-bind="option"></slot>
      </template>
      <template v-slot:selected-option="option: any">
        <slot
          v-if="slots['selected-option-data']"
          name="selected-option-data"
          v-bind="option"
        ></slot>
        <slot v-else name="option-data" v-bind="option"></slot>
      </template>
    </v-select>

    <div v-if="delaySave || clearAll" class="flex w-full justify-between pt-5">
      <div v-if="hasSelectedValues && clearAll" class="flex">
        <TextButton label="Clear All" @click="clearSelection" :secondary="true" />
      </div>

      <div v-if="delaySave && hasChanges" class="ml-auto flex">
        <TextButton label="Cancel" @click="resetSelection" :secondary="true" />
        <TextButton label="Apply" @click="commitSelection" />
      </div>
    </div>

    <div class="pt-5 text-xs text-gray-500" v-if="helperText">{{ helperText }}</div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRefs, useSlots, watch } from "vue";
import vSelect, { VueSelectInstance } from "vue-select";
import { isString, isFunction, isEqual, isNil } from "lodash";
import { DropdownOption } from "../lib/comparators";
import TextButton from "@/common/components/TextButton.vue";

type SelectValue = string | object[] | string[] | object | null;

const props = withDefaults(
  defineProps<{
    label?: string;
    modelValue?: SelectValue;
    placeholder?: string;
    disabled?: boolean;
    options: (string | object)[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    optionLabel?: string | ((option: any) => string);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    optionValue?: (o: any) => string;
    required?: boolean;
    clearable?: boolean;
    multiple?: boolean;
    filter?: (
      this: VueSelectInstance,
      options: Array<DropdownOption>,
      search: string
    ) => DropdownOption[];
    helperText?: string;
    delaySave?: boolean;
    clearAll?: boolean;
  }>(),
  {
    clearable: true,
    multiple: false,
    delaySave: false,
    clearAll: false,
  }
);
const { modelValue, required } = toRefs(props);

const emit = defineEmits(["update:modelValue"]);
const slots = useSlots();

const handleUpdate = (newVal: SelectValue) => {
  if (props.delaySave) {
    selectedValues.value = newVal;
  } else {
    emit("update:modelValue", newVal);
  }
};

const selectedValues = ref(getValue(props.modelValue));

const hasSelectedValues = computed(() => {
  if (props.delaySave) {
    return Array.isArray(selectedValues.value) && selectedValues.value.length > 0;
  } else {
    return Array.isArray(modelValue.value) && modelValue.value.length > 0;
  }
});

const commitSelection = () => {
  emit("update:modelValue", selectedValues.value);
};

const resetSelection = () => {
  selectedValues.value = getValue(props.modelValue);
};

const clearSelection = () => {
  selectedValues.value = props.multiple ? [] : null;
  if (!props.delaySave) {
    commitSelection();
  }
};

const hasChanges = computed(() => {
  if (!props.delaySave) return false; // No delay save → no tracking needed
  return !isEqual(props.modelValue, selectedValues.value);
});

function getValue(value: SelectValue | undefined): SelectValue {
  if (isNil(value)) {
    return props.multiple ? [] : null;
  }
  return value;
}

watch(
  () => props.modelValue,
  (newVal) => {
    if (props.delaySave) {
      selectedValues.value = getValue(newVal);
    }
  }
);
</script>
