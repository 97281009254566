<template>
  <section class="relative mb-15">
    <IconButtonV2
      name="md:hub"
      color="white"
      class="absolute right-0 top-[-7px]"
      @click="toggleMetagraph"
      size="l"
    />

    <div class="mb-10 text-lg font-medium text-gray-500">Perspective</div>
    <ConceptSelector
      :concept-ids="conceptTypes"
      :model-value="exploreStore.query?.root_concept_type"
      :include-adhoc="true"
      @update:model-value="exploreStore.setRootConceptType"
      placeholder="Choose concept type"
      data-test="concept-select"
      icon-color="gray2"
    />
  </section>

  <template v-if="exploreStore.query != null">
    <section class="mb-15">
      <ListBuilder
        title="Sort by"
        :model-value="exploreStore.query.order_by"
        @update:model-value="(values) => exploreStore.setOrderBy(compact(values))"
        v-slot="{ item, updateItem }"
      >
        <OrderBy :model-value="item" @update:model-value="updateItem" />
      </ListBuilder>
    </section>

    <section class="relative mb-20">
      <template v-if="exploreStore.query!.group_by === GROUP_BY_ALL">
        <div class="mb-10 text-lg font-medium text-gray-500">Group By</div>
        <div
          class="flex items-center justify-between rounded-full bg-gray-200 pl-15 pr-5 dark:bg-gray-900"
        >
          Summarize All
          <IconButtonV2 name="delete" @click="exploreStore.setGroupBy([])" />
        </div>
      </template>
      <ListBuilder
        v-else
        title="Group by"
        data-test="add-group-by"
        :model-value="exploreStore.groupByColumns"
        @update:model-value="(values) => exploreStore.setGroupBy(compact(values))"
        v-slot="{ item, updateItem }"
      >
        <GroupBy :model-value="item" @update:model-value="updateItem" data-test="group-by" />
      </ListBuilder>
    </section>

    <section class="relative mb-20">
      <div class="mb-10 flex items-center justify-between">
        <div class="text-lg font-medium text-gray-500">Included data</div>
        <Toggle v-model="exploreStore.hideUnusedProperties" label="Hide Unused" />
      </div>
      <Metatree />
    </section>
  </template>
</template>

<script lang="ts" setup>
import { useExploreStore } from "@/reader/stores/explore";
import { useExploreMetagraphStore } from "@/reader/stores/exploreMetagraph";
import { computed } from "vue";
import { compact } from "lodash";
import OrderBy from "./OrderBy.vue";
import GroupBy from "./GroupBy.vue";
import Metatree from "./metatree/Metatree.vue";
import ListBuilder from "@/common/components/ListBuilder.vue";
import Toggle from "@/common/components/Toggle.vue";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";
import { GROUP_BY_ALL } from "@/common/lib/fetchApi";
import ConceptSelector from "@/common/components/ConceptSelector.vue";

const exploreStore = useExploreStore();
const exploreMetagraphStore = useExploreMetagraphStore();

const conceptTypes = computed(() => exploreStore.metagraph.concepts.map((concept) => concept.type));

function toggleMetagraph() {
  exploreMetagraphStore.visible = !exploreMetagraphStore.visible;
}
</script>
