import { resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "pl-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createBlock($setup["Dropdown"], { placement: "auto" }, {
    popper: _withCtx(() => [
      _withDirectives(_createVNode($setup["Menu"], { items: $setup.menuItems }, null, 8 /* PROPS */, ["items"]), [
        [_directive_close_popper]
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["flex cursor-pointer items-baseline", {
        'justify-end': $props.quantitative,
        underline: $setup.hasConceptPage,
      }])
      }, [
        ($setup.color)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              style: _normalizeStyle({ backgroundColor: $setup.color }),
              class: "mr-4 h-10 w-10 rounded-full"
            }, null, 4 /* STYLE */))
          : _createCommentVNode("v-if", true),
        _createVNode($setup["GraphValueComponent"], {
          value: $setup.value.formattedValue
        }, null, 8 /* PROPS */, ["value"]),
        ($setup.hasBarChart)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode($setup["MiniBar"], {
                value: $setup.value.originalValue as FloatValue,
                max: $setup.max
              }, null, 8 /* PROPS */, ["value", "max"])
            ]))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }))
}