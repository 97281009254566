<template>
  <div class="my-5 flex rounded-md bg-gray-300 dark:bg-gray-900">
    <div
      class="flex basis-full cursor-pointer items-center justify-center whitespace-nowrap rounded-md px-10 text-gray-500"
      :class="{
        'border border-gray-500 bg-white font-bold text-orange dark:border-black dark:bg-gray-800':
          value === model,
        'hover:text-orange': value !== model,
      }"
      v-for="{ label, value } in options"
      :key="String(value)"
      @click="model = value"
    >
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts" setup>
type Value = string | number | symbol | boolean;

interface Option {
  label: string;
  value: Value;
}

defineProps<{ options: Option[] }>();

const model = defineModel<Value>({ required: true });
</script>
