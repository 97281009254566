import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { PropertyOpType, SumPropertyType } from "@/common/lib/derived";
import { Ref, ref, watchEffect } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";


export default /*@__PURE__*/_defineComponent({
  __name: 'SumPropertyDef',
  props: {
    "modelValue": { type: [Object, null], ...{ required: true } },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {
  __expose();

const OP = PropertyOpType.Sum;

const term: Ref<PropertyKnowledgeRef | null> = ref(null);

const model = _useModel<SumPropertyType | null>(__props, "modelValue");

watchEffect(function () {
  if (term.value == null) {
    model.value = null;
  } else {
    model.value = { op: OP, term: term.value };
  }
});

const __returned__ = { OP, term, model, DerivedPropertyDef }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})