import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import IconButton from "@/common/components/IconButtonV2.vue";
import Menu from "@/common/components/Menu.vue";
import { Dropdown, Tooltip } from "floating-vue";
import { computed, Ref, inject } from "vue";
import { useUserModuleStore, Bookmark } from "@/common/stores/userModuleStore";
import { useExploreStore } from "../../stores/explore";
import { AsyncStatus } from "@/common/lib/async";
import { DarkMode } from "@/common/lib/keys";
import { isEmpty } from "lodash";
import { Query } from "@/common/lib/query";
import { validateQuery } from "@/common/lib/queryValidator";
import { environment } from "@/common/environments/environmentLoader";
import { UserAction } from "@/common/lib/userAction";
interface submenuItem {
  key: string;
  label: string;
  action?: () => void;
}

interface BookmarkItem {
  label: string;
  key: string;
  disabled?: boolean;
  action?: () => void;
  submenu: submenuItem[];
}

export default /*@__PURE__*/_defineComponent({
  __name: 'BookmarksList',
  props: {
    projectId: { type: String, required: true }
  },
  emits: ["updateBookmark", "displayError", "deleteBookmark"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();


const emit = __emit;
const userModuleStore = useUserModuleStore();
const exploreStore = useExploreStore();

const bookmarks = computed(() => {
  const items: BookmarkItem[] = [];
  const bookmarks = userModuleStore.bookmarks;
  if (bookmarks.status === AsyncStatus.Succeeded) {
    bookmarks.result.forEach((bookmark) => {
      const errors = validateQuery(bookmark.state, exploreStore.metagraph);
      items.push({
        key: bookmark.id,
        label: bookmark.metadata.name,
        disabled: !isEmpty(errors),
        action: () => openBookmark(bookmark.state),
        submenu: menuItems(bookmark, errors),
      });
    });
  } else if (bookmarks.status === AsyncStatus.Failed) {
    return bookmarks.message;
  } else if (isEmpty(items)) {
    return "No bookmarks yet.";
  }
  return items;
});

function select(item: BookmarkItem) {
  if (!item.disabled) {
    item.action?.();
  }
}

function openBookmark(query: Query) {
  exploreStore.loadQuery(query, UserAction.Load_Bookmark);
}

const darkMode = inject(DarkMode) as Ref<boolean>;
const iconColor = computed(() => {
  if (darkMode.value) {
    return "light-gray";
  } else {
    return "gray2";
  }
});

function menuItems(bookmark: Bookmark, errors: string[]) {
  const items = [
    {
      key: "rename",
      label: "Rename",
      action: () => emit("updateBookmark", bookmark),
    },
    {
      key: "rename",
      label: "Delete",
      action: () => emit("deleteBookmark", bookmark),
    },
  ];
  if (environment.requireBoolean("BOOKMARKS_SHOW_COPY_JSON")) {
    items.push({
      key: "get-json",
      label: "Copy JSON",
      action: () => copyJson(bookmark),
    });
  }
  if (!isEmpty(errors)) {
    items.push({
      key: "errors",
      label: "Error details",
      action: () => emit("displayError", bookmark, errors),
    });
  }

  return items;
}

async function copyJson(bookmark: Bookmark) {
  await navigator.clipboard.writeText(JSON.stringify(bookmark.state, undefined, 2));
}

const __returned__ = { emit, userModuleStore, exploreStore, bookmarks, select, openBookmark, darkMode, iconColor, menuItems, copyJson, Icon, IconButton, Menu, get Dropdown() { return Dropdown }, get Tooltip() { return Tooltip } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})