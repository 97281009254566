import { defineComponent as _defineComponent } from 'vue'
import { useExploreStore } from "@/reader/stores/explore";
import { useExploreMetagraphStore } from "@/reader/stores/exploreMetagraph";
import { computed } from "vue";
import { compact } from "lodash";
import OrderBy from "./OrderBy.vue";
import GroupBy from "./GroupBy.vue";
import Metatree from "./metatree/Metatree.vue";
import ListBuilder from "@/common/components/ListBuilder.vue";
import Toggle from "@/common/components/Toggle.vue";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";
import { GROUP_BY_ALL } from "@/common/lib/fetchApi";
import ConceptSelector from "@/common/components/ConceptSelector.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueryBasics',
  setup(__props, { expose: __expose }) {
  __expose();

const exploreStore = useExploreStore();
const exploreMetagraphStore = useExploreMetagraphStore();

const conceptTypes = computed(() => exploreStore.metagraph.concepts.map((concept) => concept.type));

function toggleMetagraph() {
  exploreMetagraphStore.visible = !exploreMetagraphStore.visible;
}

const __returned__ = { exploreStore, exploreMetagraphStore, conceptTypes, toggleMetagraph, get compact() { return compact }, OrderBy, GroupBy, Metatree, ListBuilder, Toggle, IconButtonV2, get GROUP_BY_ALL() { return GROUP_BY_ALL }, ConceptSelector }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})