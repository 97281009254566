<template>
  <table>
    <tbody>
      <tr
        v-for="item of itemDetails"
        :key="item.key"
        class="group/legenditem whitespace-nowrap"
        :class="{ 'cursor-pointer': item.identifier != null }"
        @click="if (item.identifier != null) emit('select', item.identifier);"
      >
        <td class="py-[3px] pr-4">
          <div
            :style="{ backgroundColor: item.color, outlineColor: item.color }"
            class="h-15 w-15 rounded-full outline-2"
            :class="{ 'group-hover/legenditem:outline': item.identifier != null }"
          ></div>
        </td>
        <td
          class="max-w-[100px] overflow-hidden text-ellipsis"
          :class="{ 'font-semibold': item.identifier != null }"
          :title="item.label"
        >
          {{ item.label }}
        </td>
        <td v-if="item.value" class="max-w-[80px] overflow-hidden text-ellipsis pl-5 text-right">
          {{ item.value }}
        </td>
      </tr>
      <tr v-if="total">
        <td></td>
        <td class="py-4 text-right font-semibold">Total</td>
        <td class="max-w-[80px] overflow-hidden text-ellipsis pl-5 text-right font-semibold">
          {{ totalString }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import { ValueWithFormattedValue } from "@/common/lib/format";
import { GraphValue, stringifyValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";

export interface LegendItem {
  label: ValueWithFormattedValue;
  identifier?: GraphValue;
  value?: ValueWithFormattedValue;
  color: string;
}

const props = defineProps<{ items: LegendItem[]; total?: ValueWithFormattedValue }>();
const { items, total } = toRefs(props);

const emit = defineEmits<{ select: [value: unknown] }>();

const itemDetails = computed(function () {
  return items.value.map(function (item, index) {
    return {
      key: index,
      label: stringifyValue(item.label.formattedValue),
      value: item.value ? stringifyValue(item.value.formattedValue) : null,
      identifier: item.identifier,
      color: item.color,
    };
  });
});

const totalString = computed(() => (total.value ? stringifyValue(total.value.formattedValue) : ""));
</script>
