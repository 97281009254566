import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, toRefs, useSlots, watch } from "vue";
import vSelect, { VueSelectInstance } from "vue-select";
import { isString, isFunction, isEqual, isNil } from "lodash";
import { DropdownOption } from "../lib/comparators";
import TextButton from "@/common/components/TextButton.vue";

type SelectValue = string | object[] | string[] | object | null;


export default /*@__PURE__*/_defineComponent({
  __name: 'Select',
  props: {
    label: { type: String, required: false },
    modelValue: { type: [String, Array, Object, null], required: false },
    placeholder: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    options: { type: Array, required: true },
    optionLabel: { type: [String, Function], required: false },
    optionValue: { type: Function, required: false },
    required: { type: Boolean, required: false },
    clearable: { type: Boolean, required: false, default: true },
    multiple: { type: Boolean, required: false, default: false },
    filter: { type: Function, required: false },
    helperText: { type: String, required: false },
    delaySave: { type: Boolean, required: false, default: false },
    clearAll: { type: Boolean, required: false, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const { modelValue, required } = toRefs(props);

const emit = __emit;
const slots = useSlots();

const handleUpdate = (newVal: SelectValue) => {
  if (props.delaySave) {
    selectedValues.value = newVal;
  } else {
    emit("update:modelValue", newVal);
  }
};

const selectedValues = ref(getValue(props.modelValue));

const hasSelectedValues = computed(() => {
  if (props.delaySave) {
    return Array.isArray(selectedValues.value) && selectedValues.value.length > 0;
  } else {
    return Array.isArray(modelValue.value) && modelValue.value.length > 0;
  }
});

const commitSelection = () => {
  emit("update:modelValue", selectedValues.value);
};

const resetSelection = () => {
  selectedValues.value = getValue(props.modelValue);
};

const clearSelection = () => {
  selectedValues.value = props.multiple ? [] : null;
  if (!props.delaySave) {
    commitSelection();
  }
};

const hasChanges = computed(() => {
  if (!props.delaySave) return false; // No delay save → no tracking needed
  return !isEqual(props.modelValue, selectedValues.value);
});

function getValue(value: SelectValue | undefined): SelectValue {
  if (isNil(value)) {
    return props.multiple ? [] : null;
  }
  return value;
}

watch(
  () => props.modelValue,
  (newVal) => {
    if (props.delaySave) {
      selectedValues.value = getValue(newVal);
    }
  }
);

const __returned__ = { props, modelValue, required, emit, slots, handleUpdate, selectedValues, hasSelectedValues, commitSelection, resetSelection, clearSelection, hasChanges, getValue, get vSelect() { return vSelect }, get isString() { return isString }, get isFunction() { return isFunction }, TextButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})