import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bar-track relative h-[10px] w-[100px] overflow-hidden rounded-sm border border-gray-200 bg-white dark:border-0 dark:bg-gray-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "bar absolute bottom-0 left-0 top-0",
      style: _normalizeStyle({ width: $setup.width, backgroundColor: $setup.color })
    }, null, 4 /* STYLE */)
  ]))
}