import { cloneDeep, isArray, isEqual, isObject, isString, mapValues } from "lodash";
import { CombiningQuery, isCombiningQuery } from "./combiningQuery";
import { PropertyOpType } from "./derived";
import { allQueryBranches, Query } from "./query";

export function modernizeQuery(oldQuery: Query | CombiningQuery): Query | CombiningQuery {
  if (isCombiningQuery(oldQuery)) {
    const combiningQuery = oldQuery as CombiningQuery;
    return {
      ...combiningQuery,
      queries: mapValues(combiningQuery.queries, modernizeQuery) as Record<string, Query>,
    };
  }

  const newQuery = cloneDeep(oldQuery) as Query;

  // Change explicit group_by property defs to column references
  for (const branch of allQueryBranches(newQuery)) {
    if (isArray(branch.group_by)) {
      branch.group_by = branch.group_by.map(function (gb) {
        if (isString(gb)) return gb; // Nothing to do - already up to date
        const oldGroupBy = gb as unknown as Record<string, unknown>;
        const matchingCol = branch.columns.find(
          (col) =>
            isEqual(col.path, oldGroupBy["path"]) &&
            isEqual(col.property_type, oldGroupBy["property_type"])
        );
        if (matchingCol == null) throw "Could not find matching column for group_by";
        return matchingCol.alias;
      });
    }
  }

  // Change most instances of "property_type" to "term" in property definitions
  function unbreakPropertyDefPart(part: unknown) {
    if (isObject(part)) {
      const obj = part as Record<string, unknown>;
      if (
        Object.hasOwn(obj, "property_type") &&
        Object.hasOwn(obj, "op") &&
        obj["op"] != PropertyOpType.Property
      ) {
        obj["term"] = obj["property_type"];
        delete obj["property_type"];
      }
      for (const subpart of Object.values(obj)) unbreakPropertyDefPart(subpart);
    } else if (isArray(part)) {
      for (const subpart of part) unbreakPropertyDefPart(subpart);
    }
  }
  for (const branch of allQueryBranches(newQuery)) {
    for (const col of branch.columns) unbreakPropertyDefPart(col.property_type);
  }

  return newQuery;
}
