import useGraph from "@/common/composables/useGraph";
import { environment } from "@/common/environments/environmentLoader";
import { httpClient as axios } from "@/common/http/http";
import {
  Async,
  asyncFailed,
  asyncInProgress,
  asyncNotStarted,
  AsyncStatus,
  asyncSucceeded,
} from "@/common/lib/async";
import { convertRequestFormat } from "@/common/lib/derivedV2";
import { FailureType } from "@/common/lib/failure";
import { FetchNProblem, FetchNResponse, GROUP_BY_ALL } from "@/common/lib/fetchApi";
import { emptyGraph, Graph } from "@/common/lib/graph";
import { ConceptKnowledgeRef } from "@/common/lib/knowledge";
import { CTMap } from "@/common/lib/map";
import {
  allPathsInQuery,
  emptyQuery,
  filterIsComplete,
  pathsEquivalent,
  Query,
  QueryColumn,
  QueryFilter,
  QueryGroupBy,
  QueryOrderBy,
  QueryPathNode,
  validateQuery,
} from "@/common/lib/query";
import { expandQuery } from "@/common/lib/queryToFetch";
import { useFailureStore } from "@/common/stores/failureStore";
import { AxiosResponse } from "axios";
import { isEqual, pick, reject, some, uniqWith, without } from "lodash";
import { defineStore } from "pinia";
import {
  AskLLMRefusalError,
  AskResponse,
  AskValidationError,
  buildAskRequest,
  processAskResponse,
} from "../lib/ask";
import { ConceptAddress } from "../lib/concept";
import {
  buildExploreTable,
  calculateColumnStats,
  ExploreColumnStats,
  ExploreTable,
  initialColumnSet,
  initialOrderBy,
} from "../lib/explore";
import { ExploreTreePath, treePathSteps } from "../lib/exploreTree";
import { StoredExploreState } from "../lib/storage";
import { scrubVisualizationConfig } from "../lib/visualizationConfig";
import { VISUALIZATION_CONFIG_DEFS, VisualizationType } from "../lib/visualizationTypes";
import { useExploreMetagraphStore } from "./exploreMetagraph";

export enum Mode {
  Table = "table",
  Visualization = "visualization",
  SQL = "sql",
}

export enum Tool {
  Insights = "insights",
  Concept = "concept",
}

export enum ExploreContext {
  Embedded = "embedded",
  Standalone = "standalone",
}

export interface ExploreState {
  module?: string;
  query?: Query;
  table: Async<ExploreTable>;
  columnStats: Async<Record<string, ExploreColumnStats>>;
  problems: Async<FetchNProblem[]>;
  mode: Mode;
  map?: CTMap;
  conceptColors: Record<string, string>;
  metagraph: Graph;
  hideUnusedProperties: boolean;
  expandedPaths: QueryPathNode[][];
  sqlData: Async<string>;
  creatingCalculation?: ExploreTreePath;
  currentLoadTable: Promise<unknown> | undefined;
  currentLoadSql: Promise<unknown> | undefined;
  conceptPage?: ConceptAddress;
  visualizationType?: VisualizationType;
  visualizationConfig?: Record<string, unknown>;
  toolsVisible: boolean;
  activeTool: Tool;
  askResponse: Async<AskResponse>;
  showSidebars: () => boolean;
  context: ExploreContext | undefined;
  llmProvider: string;
}

export const useExploreStore = defineStore("reader-explore", {
  state: (): ExploreState => ({
    module: undefined,
    query: undefined,
    table: asyncNotStarted(),
    columnStats: asyncNotStarted(),
    problems: asyncNotStarted(),
    mode: Mode.Table,
    map: undefined,
    conceptColors: {},
    metagraph: emptyGraph(),
    hideUnusedProperties: false,
    expandedPaths: [],
    sqlData: asyncNotStarted(),
    currentLoadTable: undefined,
    currentLoadSql: undefined,
    creatingCalculation: undefined,
    conceptPage: undefined,
    visualizationType: undefined,
    visualizationConfig: undefined,
    toolsVisible: false,
    activeTool: Tool.Insights,
    askResponse: asyncNotStarted(),
    showSidebars: () => true,
    context: undefined,
    llmProvider: environment.require("LLM_PROVIDER_DEFAULT"),
  }),
  getters: {
    columnByAlias(state) {
      return (alias: string) => (state.query?.columns ?? []).find((col) => col.alias === alias);
    },
    filterByAlias(state) {
      return (alias: string) => (state.query?.filters ?? []).find((f) => f.alias === alias);
    },
    isPathExpanded(state) {
      return (path: QueryPathNode[]) => some(state.expandedPaths, (p) => pathsEquivalent(p, path));
    },
    columnSortState(state) {
      return function (alias: string) {
        const order_by = state.query?.order_by ?? [];
        const index = order_by.findIndex((ob) => ob.on === alias);
        if (index === -1) return undefined;
        return { index, asc: order_by[index].asc };
      };
    },
  },
  actions: {
    // I am not thrilled with this imperative cascade/repetition of
    // initialization, and want to look into alternate models. -jstreufert
    boot(module: string) {
      this.module = module;
      this.reset();
    },
    reset() {
      const exploreMetagraphStore = useExploreMetagraphStore();
      this.query = undefined;
      this.expandedPaths = [];
      this.table = asyncNotStarted();
      this.columnStats = asyncNotStarted();
      this.problems = asyncNotStarted();
      this.sqlData = asyncNotStarted();
      this.mode = Mode.Table;
      this.conceptPage = undefined;
      this.visualizationType = undefined;
      this.visualizationConfig = undefined;
      exploreMetagraphStore.initializeLayout();
      exploreMetagraphStore.visible = environment.requireBoolean("AUTO_SHOW_READER_METAGRAPH");
    },
    setMode(mode: Mode) {
      this.mode = mode;
      this.load(false);
    },
    setRootConceptType(conceptType: ConceptKnowledgeRef) {
      this.query = emptyQuery(conceptType);
      this.query.columns = initialColumnSet();
      this.expandedPaths = [];
      this.visualizationType = undefined;
      this.visualizationConfig = undefined;
      useExploreMetagraphStore().visible = false;
      this.load();
    },
    restoreState(storedState: StoredExploreState) {
      // Refuse to load a query that doesn't match the metagraph (quietly, for now)
      if (validateQuery(storedState.query, this.metagraph).length > 0) return;
      this.loadQuery(storedState.query);
      if (storedState.visualizationType != null && storedState.visualizationConfig != null) {
        this.visualizationType = storedState.visualizationType;
        this.visualizationConfig = scrubVisualizationConfig(
          storedState.visualizationConfig,
          VISUALIZATION_CONFIG_DEFS[this.visualizationType],
          this.query!
        );
        this.mode = Mode.Visualization;
      } else {
        this.mode = Mode.Table;
      }
    },
    loadQuery(bookmark: Query) {
      // The pick guards against stuff getting added to Query in other parts of the
      // app that we don't handle in Explore (currently that's just "size")
      this.query = pick(
        bookmark,
        "root_concept_type",
        "columns",
        "filters",
        "order_by",
        "group_by"
      );
      if (this.query.columns.length === 0) this.query.columns = initialColumnSet();
      useExploreMetagraphStore().visible = false;
      this.expandedPaths = uniqWith(
        allPathsInQuery(bookmark).flatMap((p) => treePathSteps(p)),
        pathsEquivalent
      );
      this.visualizationType = undefined;
      this.visualizationConfig = undefined;
      this.load();
    },
    pivot(conceptType: ConceptKnowledgeRef, filter: QueryFilter) {
      this.query = emptyQuery(conceptType);
      this.query.columns = initialColumnSet();
      this.expandedPaths = [];
      this.visualizationType = undefined;
      this.visualizationConfig = undefined;
      this.addFilter(filter); // this load()s
    },
    setOrderBy(order_by: QueryOrderBy[] = []) {
      if (this.query == null) return;
      this.query.order_by = order_by;
      this.load();
    },
    setGroupBy(group_by: QueryGroupBy[] | typeof GROUP_BY_ALL = []) {
      if (this.query == null) return;
      this.query.group_by = group_by;
      this.query.columns = initialColumnSet();
      this.query.order_by = initialOrderBy();
      this.scrubVisualizationConfig();
      this.load();
    },
    renameColumn(columnAlias: string, newName: string) {
      this.columnByAlias(columnAlias)!.displayName = newName;
    },
    addColumn(column: QueryColumn) {
      if (this.query == null) return;
      this.query.columns = [...this.query.columns, column];
      this.load();
    },
    setColumnOrder(newOrder: QueryColumn[]) {
      if (this.query == null) return;
      this.query.columns = newOrder;
    },
    removeColumn(columnAlias: string) {
      if (this.query == null) return;
      const column = this.columnByAlias(columnAlias)!;
      this.query.columns = without(this.query.columns, column);
      this.query.order_by = reject(this.query.order_by ?? [], (ob) => ob.on === column.alias);
      this.scrubVisualizationConfig();
      this.load();
    },
    addFilter(filter: QueryFilter, loadIfFilterComplete = true) {
      if (this.query == null) return;
      this.query.filters.push(filter);
      if (filter.path != null) {
        for (const path of treePathSteps(filter.path)) {
          if (!this.isPathExpanded(path)) this.expandedPaths.push(path);
        }
      }
      if (loadIfFilterComplete && filterIsComplete(filter)) this.load();
    },
    removeFilter(alias: string) {
      if (this.query == null) return;
      this.query.filters = reject(this.query.filters, { alias });
      this.load();
    },
    toggleFilterNegated(alias: string) {
      const filter = this.filterByAlias(alias);
      if (filter != null) {
        filter.negated = !filter.negated;
        this.load();
      }
    },
    togglePathExpanded(path: ExploreTreePath) {
      if (this.isPathExpanded(path)) {
        this.expandedPaths = this.expandedPaths.filter((p) => !isEqual(p, path));
      } else {
        this.expandedPaths.push(path);
      }
    },
    showConceptPage(address: ConceptAddress) {
      this.conceptPage = address;
      this.toolsVisible = true;
      this.activeTool = Tool.Concept;
    },
    scrubVisualizationConfig() {
      // After making changes to the query (so far, just columns), ensures there
      // are no invalid references in the visualization configuration
      if (this.visualizationConfig != null) {
        if (this.query == null) {
          this.visualizationConfig = undefined;
        } else {
          this.visualizationConfig = scrubVisualizationConfig(
            this.visualizationConfig,
            VISUALIZATION_CONFIG_DEFS[this.visualizationType!],
            this.query
          );
        }
      }
    },
    async load(reload = true, options?: { testName?: string }) {
      this.conceptPage = undefined;
      if (reload) {
        this.table = asyncInProgress("Loading your data...");
        this.columnStats = asyncInProgress("Loading your data...");
        this.problems = asyncInProgress("Loading your data...");
        this.sqlData = asyncInProgress("Loading your data...");
        this.currentLoadTable = undefined;
        this.currentLoadSql = undefined;
      }
      if (this.mode === Mode.SQL && this.sqlData.status !== AsyncStatus.Succeeded) {
        return await this.loadSql();
      } else if (this.mode === Mode.Table && this.table.status !== AsyncStatus.Succeeded) {
        return await this.loadTable(options);
      }
    },
    async loadTable(options?: { testName?: string }) {
      let response: AxiosResponse<FetchNResponse>;
      const [query, idMap] = expandQuery(this.query!);
      const params = options?.testName ? { create_named_test: options.testName } : {};
      const queryV2 = convertRequestFormat(query);
      this.table = asyncInProgress("Loading your data...");
      this.columnStats = asyncInProgress("Loading your data...");
      const loadTableResponse = axios.post(`/api/projects/${this.module!}/query`, queryV2, {
        params,
      });
      try {
        this.currentLoadTable = loadTableResponse;
        response = await loadTableResponse;
        if (loadTableResponse !== this.currentLoadTable) {
          return;
        }
      } catch (error) {
        if (loadTableResponse !== this.currentLoadTable) {
          return;
        }
        this.handleError("Failed to load table", error);
        this.table = asyncFailed("We couldn't load your data.");
        return;
      }

      this.table = asyncSucceeded(buildExploreTable(response.data, idMap));
      this.columnStats = asyncSucceeded(calculateColumnStats());
      this.problems = asyncSucceeded(response.data.problems);
    },
    async loadSql() {
      const [query] = expandQuery(this.query!);
      this.sqlData = asyncInProgress("Loading your data...");
      const queryV2 = convertRequestFormat(query);
      const params = { mode: "sql" };
      const loadSqlResponse = axios.post(`/api/projects/${this.module!}/query`, queryV2, {
        params,
      });
      try {
        this.currentLoadSql = loadSqlResponse;
        const response: AxiosResponse<string> = await loadSqlResponse;
        if (this.currentLoadSql !== loadSqlResponse) {
          return;
        }
        this.sqlData = asyncSucceeded(response.data);
      } catch (error) {
        if (this.currentLoadSql !== loadSqlResponse) {
          return;
        }
        this.handleError("Failed to load SQL", error);
        this.sqlData = asyncFailed("We couldn't load your data.");
        return;
      }
    },
    async downloadExcel() {
      const [query] = expandQuery(this.query!);
      const queryV2 = convertRequestFormat(query);
      const response = await axios.post(`/api/projects/${this.module!}/export/excel`, queryV2, {
        responseType: "blob",
      });
      // Let's create a link in the document that we'll
      // programmatically 'click'.
      const link = document.createElement("a");

      // Tell the browser to associate the response data to
      // the URL of the link we created above.
      link.href = window.URL.createObjectURL(new Blob([response.data]));

      // Tell the browser to download, not render, the file.
      link.setAttribute("download", environment.require("EXCEL_EXPORT_DEFAULT_FILENAME"));

      // Place the link in the DOM.
      document.body.appendChild(link);

      // Make the magic happen!
      link.click();
    },
    async askQuestion(question: string, prevError?: AskValidationError) {
      this.askResponse = asyncInProgress();
      const request = buildAskRequest(question, prevError);
      const generalExcuse =
        "We couldn't generate a valid query from your question. " +
        "Please try again, or reword your request.";
      try {
        const response: AxiosResponse<AskResponse> = await axios.post(
          `/api/projects/${this.module!}/llm`,
          request
        );
        this.askResponse = asyncSucceeded(response.data);
      } catch (error) {
        this.handleError(generalExcuse, error);
        this.askResponse = asyncFailed(generalExcuse);
        return;
      }
      let query: Query;
      try {
        query = processAskResponse(this.askResponse.result);
      } catch (error) {
        if (error instanceof AskValidationError) {
          if (prevError == null) {
            this.askQuestion(question, error); // The LLM gets one more crack at this!
            return;
          } else {
            this.askResponse = asyncFailed(generalExcuse);
            return;
          }
        } else if (error instanceof AskLLMRefusalError) {
          this.askResponse = asyncFailed(error.message);
          return;
        } else {
          this.askResponse = asyncFailed(generalExcuse);
          this.handleError(generalExcuse, error);
          return;
        }
      }
      this.loadQuery(query);
    },
    configure(
      context: ExploreContext,
      map: CTMap,
      metagraph: Graph,
      conceptColors: Record<string, string>
    ) {
      // Later we'll do something a little less destructive, comparing the new
      // and old configurations and keeping as much state as possible
      this.context = context;
      this.map = map;
      const { metagraphWithoutRecords } = useGraph(() => metagraph);
      this.metagraph = metagraphWithoutRecords();
      this.conceptColors = conceptColors;
      this.reset();
    },
    handleError(message: string, error: unknown) {
      useFailureStore().backendFail({
        type: FailureType.Explorer,
        description: message,
        error,
        hideUndo: true,
      });
    },
    async clearQueryCaches(module_id: string, page_id?: string, widget_key?: string) {
      const body = { module_id, page_id, widget_key };
      await axios.post(`/api/clear-query-cache`, body);
    },
  },
  debounce: {
    loadTable: environment.requireNumber("EXPLORER_DEBOUNCE_MILLISECONDS"),
    loadSql: environment.requireNumber("EXPLORER_DEBOUNCE_MILLISECONDS"),
  },
});
