import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "absolute inset-0 z-0 overflow-auto",
  "data-test": "table"
}
const _hoisted_2 = { class: "z-10 border-collapse" }
const _hoisted_3 = { class: "sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline" }
const _hoisted_4 = { class: "flex h-[25px] items-center justify-end whitespace-nowrap border-b border-r border-white px-10 text-right" }
const _hoisted_5 = { class: "mx-auto" }
const _hoisted_6 = {
  key: 0,
  class: "sticky top-0 z-10 bg-gray-200 p-0"
}
const _hoisted_7 = { class: "flex h-[25px] items-center justify-end whitespace-nowrap border-b border-r border-white px-10 text-right" }
const _hoisted_8 = { class: "mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _cache[1] || (_cache[1] = _createElementVNode("th", { class: "sticky top-0 z-10 bg-gray-200 p-0" }, [
            _createElementVNode("div", { class: "flex h-[25px] border-b border-r border-white px-10" })
          ], -1 /* HOISTED */)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.columns, (colName, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              _createElementVNode("th", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(colName), 1 /* TEXT */)
                ])
              ]),
              (index > 0)
                ? (_openBlock(), _createElementBlock("th", _hoisted_6, [...(_cache[0] || (_cache[0] = [
                    _createElementVNode("div", { class: "flex h-[25px] border-b border-r border-white px-10" }, null, -1 /* HOISTED */)
                  ]))]))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.trailingColumns, (col, index) => {
            return (_openBlock(), _createElementBlock("th", {
              key: index,
              class: _normalizeClass(["sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline", { 'border-l-[8px] border-solid border-l-finance-highlight': index === 0 }])
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(col.name), 1 /* TEXT */)
              ])
            ], 2 /* CLASS */))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _createElementVNode("tbody", null, [
        _createVNode($setup["FinancialSubtable"], {
          rows: $setup.table,
          columns: $setup.columns,
          trailingColumns: $setup.trailingColumns.map((col) => col.name),
          depth: 0,
          "expanded-map": $setup.expandedMap,
          onUpdateExpanded: $setup.updateExpanded
        }, null, 8 /* PROPS */, ["rows", "columns", "trailingColumns", "expanded-map"])
      ])
    ])
  ]))
}