import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "overflow-hidden rounded border border-gray-700 border-opacity-50 bg-gray-700 bg-opacity-10 dark:border-gray-500 dark:border-opacity-50 dark:bg-gray-300 dark:bg-opacity-10" }
const _hoisted_2 = { class: "selection" }
const _hoisted_3 = {
  key: 0,
  class: "px-10 py-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["vSelect"], {
        modelValue: $setup.term,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.term) = $event)),
        options: $setup.termOptions,
        appendToBody: true,
        class: "border-none bg-white dark:bg-gray-900"
      }, null, 8 /* PROPS */, ["modelValue", "options"])
    ]),
    ($setup.propertyDefComponent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.propertyDefComponent), {
            modelValue: $setup.anyComponentValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.anyComponentValue) = $event))
          }, null, 8 /* PROPS */, ["modelValue"]))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}