import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-around" }
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = { class: "max-w-[400px] p-5" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "hidden whitespace-nowrap text-base group-focus-within:block"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 3,
  class: "opacity-25 group-focus-within:hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Tooltip"], {
      shown: $setup.errorMessage != null,
      triggers: [],
      placement: "bottom",
      class: "max-w-4xl flex-none basis-full"
    }, {
      popper: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString($setup.errorMessage), 1 /* TEXT */),
          _createVNode($setup["TextButton"], {
            label: "OK",
            onClick: $setup.prepareForNewQuestion
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["group flex h-[35px] items-center rounded-full border border-gray-700 px-10 text-xl", {
          'bg-white dark:bg-gray-900': $setup.inProgress || $setup.succeeded,
          'focus-within:border-orange focus-within:bg-white dark:focus-within:bg-gray-900':
            !$setup.inProgress,
        }])
        }, [
          _createVNode($setup["Icon"], {
            name: "ct-chat",
            size: "xl",
            color: "orange"
          }),
          _withDirectives(_createElementVNode("input", {
            ref: "askBar",
            class: "basis-full border-none bg-transparent p-5 outline-none",
            placeholder: "Ask a question about your data",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.inputText) = $event)),
            disabled: $setup.inProgress,
            onKeyup: _withKeys($setup.submitQuestion, ["enter"]),
            onFocus: $setup.prepareForNewQuestion,
            "data-test": "explore-llm-textbox"
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4), [
            [_vModelText, $setup.inputText]
          ]),
          ($setup.hasInput && !$setup.inProgress)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[1] || (_cache[1] = [
                _createTextVNode(" Press "),
                _createElementVNode("span", { class: "rounded-sm border border-gray-500 px-[4px] py-2" }, "Enter", -1 /* HOISTED */),
                _createTextVNode(" to query ")
              ])))
            : _createCommentVNode("v-if", true),
          ($setup.inProgress)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode($setup["Spinner"])
              ]))
            : _createCommentVNode("v-if", true),
          ($setup.succeeded)
            ? (_openBlock(), _createBlock($setup["Icon"], {
                key: 2,
                name: "check",
                size: "xl",
                color: "orange"
              }))
            : _createCommentVNode("v-if", true),
          (!$setup.hasInput && !$setup.inProgress)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($setup.askBarKeyCommand.format()), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["shown"])
  ]))
}