import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Select"], {
    label: $setup.control.label,
    options: $setup.columns,
    modelValue: $setup.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
    "option-label": $setup.columnLabel,
    required: !$setup.control.optional,
    "helper-text": $setup.control.description,
    placeholder: "Select a column"
  }, null, 8 /* PROPS */, ["label", "options", "modelValue", "required", "helper-text"]))
}