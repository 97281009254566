import { isString } from "lodash";
import {
  AddPropertyType,
  AvgPropertyType,
  CountPropertyType,
  DateDiffPropertyType,
  DateTruncPropertyType,
  DividePropertyType,
  MapPropertyType,
  MaxPropertyType,
  MedianPropertyType,
  MinPropertyType,
  MultiplyPropertyType,
  NtilePropertyType,
  PercentilePropertyType,
  PropertyOpType,
  PropertyReferenceType,
  SubtractPropertyType,
  SumPropertyType,
} from "./derived";
import { PropertyKnowledgeRef } from "./knowledge";
import { QueryColumn, QueryNeighborAddress } from "./query";

export type QueryPropertyTerm = QueryDerivedPropertyType | PropertyKnowledgeRef;

export type QueryDerivedPropertyType =
  | QueryPropertyKnowledgeRef
  | SumPropertyType
  | AvgPropertyType
  | MedianPropertyType
  | PercentilePropertyType
  | MaxPropertyType
  | MinPropertyType
  | AddPropertyType
  | SubtractPropertyType
  | DividePropertyType
  | DateDiffPropertyType
  | DateTruncPropertyType
  | MultiplyPropertyType
  | QueryCountPropertyType
  | NtilePropertyType
  | MapPropertyType;

export interface QueryCountPropertyType extends Omit<CountPropertyType, "on_tag"> {
  on_neighbor?: QueryNeighborAddress;
}

export interface QueryPropertyKnowledgeRef extends Omit<PropertyReferenceType, "on_tag"> {
  on_neighbor?: QueryNeighborAddress;
}

// Takes a column and returns a QueryPropertyTerm with fully qualified properties
// that can be used as part of another term at a different path
export function relocatablePropertyTerm(column: QueryColumn): QueryDerivedPropertyType {
  const neighborAddress = column.path == null ? undefined : { path: column.path };

  function processTerm(
    term: QueryDerivedPropertyType | PropertyKnowledgeRef
  ): QueryDerivedPropertyType {
    if (isString(term))
      return {
        op: PropertyOpType.Property,
        property_type: term,
        on_neighbor: neighborAddress,
      };

    switch (term.op) {
      case PropertyOpType.Count:
      case PropertyOpType.Property: {
        return {
          ...term,
          on_neighbor: term.on_neighbor ?? neighborAddress,
        };
      }

      case PropertyOpType.Sum:
      case PropertyOpType.Avg:
      case PropertyOpType.Median:
      case PropertyOpType.Percentile:
      case PropertyOpType.Max:
      case PropertyOpType.Min:
      case PropertyOpType.Ntile:
      case PropertyOpType.DateTrunc:
      case PropertyOpType.Map:
        return { ...term, term: processTerm(term.term) };

      case PropertyOpType.Add:
      case PropertyOpType.Subtract:
        return { ...term, terms: term.terms.map((t) => processTerm(t)) };

      case PropertyOpType.Multiply:
        return { ...term, factors: term.factors.map((t) => processTerm(t)) };

      case PropertyOpType.Divide:
        return {
          ...term,
          divisor: processTerm(term.divisor),
          dividend: processTerm(term.dividend),
        };

      case PropertyOpType.DateDiff:
        return {
          ...term,
          start: processTerm(term.start),
          end: processTerm(term.end),
        };
    }
  }

  return processTerm(column.property_type);
}
