<template>
  <template v-for="(row, rowIndex) in rows" :key="rowIndex">
    <!-- Header -->
    <tr
      :class="{
        'odd:bg-gray-100': row.contents == null && !row.highlight && !expandedMap.has(row.label),
        'bg-finance-row_even odd:bg-finance-row_odd': row.highlight && !expandedMap.has(row.label),
        uppercase: row.isTotal,
      }"
      class="h-[34px] hover:bg-finance-highlight"
    >
      <td class="sticky cursor-pointer" @click="row.contents != null && expandMap(row.label)">
        <div class="flex flex-row items-center text-nowrap font-bold" :style="indentStyle">
          <Icon
            v-if="row.contents != null"
            :name="
              expandedMap.has(row.label) ? 'md:keyboard_arrow_down' : 'md:keyboard_arrow_right'
            "
            color="medium-gray"
            size="xl"
          />
          <div v-else class="w-[25px]"></div>
          {{ row.label }}
        </div>
      </td>
      <template v-for="(cell, colIndex) in cells(row)" :key="colIndex">
        <!-- Value -->
        <td class="p-10 align-baseline">
          <div v-if="!expandedMap.has(row.label)" :style="colIndex == 0 ? indentStyle : undefined">
            <div class="select-text text-nowrap text-right" v-if="cell.value != null">
              <GraphValueComponent :value="cell.value.formattedValue" />
            </div>
            <div class="text-right opacity-50" v-else>-</div>
          </div>
        </td>
      </template>
    </tr>
    <!-- Contents -->
    <FinancialSubreport
      v-if="row.contents != null && expandedMap.has(row.label)"
      :rows="row.contents"
      :columns="columns"
      :depth="depth + 1"
      :expandedMap="expandedMap"
      @update-expanded="(rowLabel) => emits('update-expanded', rowLabel)"
    />
    <!-- Footer -->
    <tr
      v-if="expandedMap.has(row.label)"
      :class="{
        'odd:bg-gray-100': row.contents == null && !row.highlight,
        'bg-finance-row_even odd:bg-finance-row_odd': row.highlight,
      }"
      class="h-[34px] cursor-pointer hover:bg-finance-highlight"
    >
      <td class="sticky">
        <div
          class="flex flex-row items-center text-nowrap font-bold"
          :style="indentStyle"
          :class="{ uppercase: row.isTotal }"
        >
          <div class="w-[25px]"></div>
          Total {{ row.label }}
        </div>
      </td>
      <template v-for="(cell, colIndex) in cells(row)" :key="colIndex">
        <!-- Value -->
        <td class="p-10 align-baseline">
          <div :style="colIndex == 0 ? indentStyle : undefined">
            <div class="select-text text-nowrap text-right" v-if="cell.value != null">
              <GraphValueComponent :value="cell.value.formattedValue" />
            </div>
            <div class="text-right opacity-50" v-else>-</div>
          </div>
        </td>
      </template>
    </tr>
  </template>
</template>

<style lang="scss" scoped>
%column-styles {
  @apply bg-finance-highlight;
}
@for $i from 2 to 64 {
  table:has(tr > *:nth-child(#{$i}):hover) tr > *:nth-child(#{$i}) {
    @extend %column-styles;
  }
}
</style>

<script lang="ts" setup>
import { FinComputedCell, FinComputedRow } from "../FinancialReport.vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Icon from "@/common/components/Icon.vue";
import { computed, toRefs } from "vue";

const props = defineProps<{
  rows: FinComputedRow[];
  columns: string[];
  depth: number;
  expandedMap: Set<string>;
}>();
const { depth, expandedMap } = toRefs(props);

const emits = defineEmits(["update-expanded"]);

function cells(row: FinComputedRow): FinComputedCell[] {
  return props.columns.map((_, index) => row.cells[index]);
}

function expandMap(rowLabel: string) {
  emits("update-expanded", rowLabel);
}

const indentStyle = computed(() => ({ marginLeft: `${15 * depth.value}px` }));
</script>
