import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "absolute inset-0 z-0 overflow-auto",
  "data-test": "table"
}
const _hoisted_2 = { class: "z-10 border-collapse" }
const _hoisted_3 = { class: "sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline" }
const _hoisted_4 = { class: "flex h-[25px] items-center justify-start whitespace-nowrap border-b border-r border-white px-10 text-right" }
const _hoisted_5 = { class: "mx-10" }
const _hoisted_6 = { class: "my-10 flex gap-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _cache[2] || (_cache[2] = _createElementVNode("th", { class: "sticky top-0 z-10 bg-gray-200 p-0" }, [
            _createElementVNode("div", { class: "flex h-[25px] border-b border-r border-white px-10" })
          ], -1 /* HOISTED */)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.columns, (colName, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              _createCommentVNode(" Value "),
              _createElementVNode("th", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(colName), 1 /* TEXT */)
                ])
              ])
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _createElementVNode("tbody", null, [
        _createVNode($setup["FinancialSubreport"], {
          rows: $setup.table,
          columns: $setup.columns,
          depth: 0,
          "expanded-map": $setup.expandedMap,
          onUpdateExpanded: $setup.updateExpanded
        }, null, 8 /* PROPS */, ["rows", "columns", "expanded-map"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode($setup["TextButton"], {
        label: "CSV Export",
        secondary: true,
        onClick: _cache[0] || (_cache[0] = () => $setup.exportData($setup.ExportType.CSV))
      }),
      _createVNode($setup["TextButton"], {
        label: "Excel Export",
        secondary: true,
        onClick: _cache[1] || (_cache[1] = () => $setup.exportData($setup.ExportType.Excel))
      })
    ])
  ]))
}