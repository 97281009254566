<template>
  <div
    class="bar-track relative h-[10px] w-[100px] overflow-hidden rounded-sm border border-gray-200 bg-white dark:border-0 dark:bg-gray-700"
  >
    <div
      class="bar absolute bottom-0 left-0 top-0"
      :style="{ width, backgroundColor: color }"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { FloatValue, IntegerValue, toNative } from "@/common/lib/value";
import { computed, inject, Ref, toRefs } from "vue";
import { visualizationTheme } from "../lib/visualization";
import { DarkMode } from "@/common/lib/keys";

const props = defineProps<{ value: FloatValue | IntegerValue; max: number }>();
const { value, max } = toRefs(props);
const darkMode = inject(DarkMode) as Ref<boolean>;

const width = computed(function () {
  const thisValue = toNative(value.value);
  const percentage = (thisValue / max.value) * 100;
  return `${percentage}%`;
});

const color = computed(() => visualizationTheme(darkMode.value).datum);
</script>
