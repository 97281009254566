<template>
  <Dropdown placement="right" v-model:shown="isEditing" @apply-show="focusFieldIfPresent">
    <template #popper>
      <FilterEditor
        :label="formLabel"
        @cancel="isEditing = false"
        @commit="commit"
        :valid="isValid"
      >
        <Textbox
          v-if="valueType === GraphValueType.String"
          label="Values"
          helper-text="Enter one value per line"
          textarea
          v-model="stringValueUnderEdit"
          ref="valueInputEl"
        />
        <SelectStrip
          v-if="valueType === GraphValueType.Bool"
          :options="[
            { label: 'True', value: true },
            { label: 'False', value: false },
          ]"
          v-model="booleanValueUnderEdit"
          class="mb-5 h-[28px]"
        />
      </FilterEditor>
    </template>
    <div
      class="cursor-pointer border-b border-b-transparent hover:border-b-gray-500"
      @click="startEditing"
    >
      {{ opDescription }}
      <span class="font-bold">{{ valueList }}</span>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
import { propertyName, propertyValueType } from "@/common/lib/derived";
import { EqualityFilter } from "@/common/lib/fetchApi";
import { columnName, QueryFilter } from "@/common/lib/query";
import { GraphValueType, stringifyValue, toNative, toValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, onMounted, Ref, ref, toRefs } from "vue";
import { Dropdown } from "floating-vue";
import FilterEditor from "../FilterEditor.vue";
import Textbox from "@/common/components/Textbox.vue";
import SelectStrip from "@/editor/components/SelectStrip.vue";
import { UserAction } from "@/common/lib/userAction";

const props = defineProps<{ filter: QueryFilter<EqualityFilter> }>();
const { filter } = toRefs(props);

const isEditing: Ref<boolean> = ref(false);
const valueUnderEdit: Ref<string | boolean | undefined> = ref(undefined);
const stringValueUnderEdit = valueUnderEdit as Ref<string | undefined>;
const booleanValueUnderEdit = valueUnderEdit as Ref<boolean>;
const valueInputEl: Ref<HTMLInputElement | null> = ref(null);

const exploreStore = useExploreStore();

function negatedDescription() {
  return filter.value.negated ? "is not" : "is";
}

const valueType = computed(function () {
  if (filter.value.property_type != null) {
    return propertyValueType(filter.value.property_type) as GraphValueType;
  } else {
    return propertyValueType(
      exploreStore.columnByAlias(filter.value.on!)!.property_type
    ) as GraphValueType;
  }
});

function subjectName() {
  if (filter.value.property_type != null) {
    return propertyName(filter.value.property_type);
  } else {
    return columnName(exploreStore.columnByAlias(filter.value.on!)!);
  }
}

const opDescription = computed(function () {
  let desc = negatedDescription();
  if (filter.value.values.length > 1) desc = desc + " any of";
  return desc;
});

const formLabel = computed(function () {
  const bits = [subjectName(), negatedDescription()];
  if (valueType.value === GraphValueType.String) bits.push("any of");
  return bits.join(" ");
});

const valueList = computed(() =>
  filter.value.values.length === 0
    ? "(click to set)"
    : filter.value.values.map((v) => stringifyValue(v.value)).join(", ")
);

const isValid = computed(
  () => valueType.value === GraphValueType.Bool || cleanStringInput().length > 0
);

function startEditing() {
  if (valueType.value === GraphValueType.Bool) {
    if (filter.value.values.length > 0) {
      valueUnderEdit.value = toNative(filter.value.values[0].value) as boolean;
    } else {
      valueUnderEdit.value = true; // Arbitrary default
    }
  } else {
    valueUnderEdit.value = filter.value.values.map((f) => f.value.value).join("\n");
  }
  isEditing.value = true;
}

function cleanStringInput() {
  return stringValueUnderEdit
    .value!.split("\n")
    .map((v) => v.trim())
    .filter((f) => f.length > 0);
}

function commit() {
  if (valueType.value === GraphValueType.Bool) {
    filter.value.values = [{ value: toValue(booleanValueUnderEdit.value) }];
  } else {
    filter.value.values = cleanStringInput().map((v) => ({ value: toValue(v) }));
  }
  isEditing.value = false;
  exploreStore.load(UserAction.Update_Eq_Filter);
}

function focusFieldIfPresent() {
  setTimeout(() => valueInputEl.value?.focus(), 50); // Boy do I hate this
}

onMounted(function () {
  if (filter.value.values.length === 0) startEditing();
});
</script>
