/**
 * User-initiated actions for logging purposes.
 */
export enum UserAction {
  Add_Column = "Add_Column",
  Add_Concept = "Add_Concept",
  Add_Enrichment = "Add_Enrichment",
  Add_Filter = "Add_Filter",
  Add_Knowledge_Clear_Replan = "Add_Knowledge_Clear_Replan",
  Add_Link = "Add_Link",
  Add_Merge = "Add_Merge",
  Add_Parser = "Add_Parser",
  Add_Resolution = "Add_Resolution",
  Add_Shape = "Add_Shape",
  Add_Tables = "Add_Tables",
  Add_Transformation = "Add_Transformation",
  Ask_Question = "Ask_Question",
  Assign_Property = "Assign_Property",
  Change_Concept_Type = "Change_Concept_Type",
  Change_Parser = "Change_Parser",
  Change_Property_Type = "Change_Property_Type",
  Change_Query_Limit = "Change_Query_Limit",
  Clear_and_Replan = "Clear_and_Replan",
  Clear_Cache_and_Reload = "Clear_Cache_and_Reload",
  Clear_Table_Cache_and_Replan = "Clear_Table_Cache_and_Replan",
  Create_Explorer_Test = "Create_Explorer_Test",
  Create_Restored_Properties = "Create_Restored_Properties",
  Ignore_Field = "Ignore_Field",
  Ignore_Fields = "Ignore_Fields",
  Load_Bookmark = "Load_Bookmark",
  Load_Concept_Page = "Load_Concept_Page",
  Load_Module = "Load_Module",
  Load_Neighborhood_Summary = "Load_Neighborhood_Summary",
  Load_Visualization = "Load_Visualization",
  Load_Widget = "Load_Widget",
  Map_Field_to_New_Property = "Map_Field_to_New_Property",
  Move_Properties = "Move_Properties",
  Move_Property = "Move_Property",
  Reassign_Property = "Reassign_Property",
  Remap_Field_to_Property = "Remap_Field_to_Property",
  Remove_Column = "Remove_Column",
  Remove_Concepts = "Remove_Concepts",
  Remove_Dataset = "Remove_Dataset",
  Remove_Enrichment = "Remove_Enrichment",
  Remove_Filter = "Remove_Filter",
  Remove_Link = "Remove_Link",
  Remove_Merge = "Remove_Merge",
  Remove_Resolution = "Remove_Resolution",
  Replan = "Replan",
  Restore_All_Fields = "Restore_All_Fields",
  Restore_Field = "Restore_Field",
  Restore_State = "Restore_State",
  Set_Explorer_Mode_SQL = "Set_Explorer_Mode_SQL",
  Set_Explorer_Mode_Table = "Set_Explorer_Mode_Table",
  Set_Explorer_Mode_Visualization = "Set_Explorer_Mode_Visualization",
  Set_Group_By = "Set_Group_By",
  Set_Order_By = "Set_Order_By",
  Set_Root_Concept_Type = "Set_Root_Concept_Type",
  Toggle_Negate_Filter = "Toggle_Negate_Filter",
  Unassign_Properties = "Unassign_Properties",
  Undo_Redo = "Undo_Redo",
  Update_Eq_Filter = "Update_Eq_Filter",
  Update_Range_Filter = "Update_Range_Filter",
  Update_Text_Filter = "Update_Text_Filter",
  Upload_Map_File = "Upload_Map_File",
}
