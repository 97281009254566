import { defineComponent as _defineComponent } from 'vue'
import {
  colorizer,
  emptyFormattedValue,
  generatePropertyValue,
  generateValueSets,
  generatorAlias,
} from "@/reader/lib/visualization";
import { PieChartVisualization } from "@/reader/lib/visualizationTypes";
import { ComputedRef, computed, ref, toRefs } from "vue";
import * as vega from "vega";
import Chart from "@/common/components/Chart.vue";
import { FloatValue, GraphValue, stringifyValue, toNative, toValue } from "@/common/lib/value";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { GraphCompoundValue, stringifyValueOrCompositeValue } from "@/common/lib/graph";
import Legend, { LegendItem } from "../page/Legend.vue";
import { ResizeObserver } from "vue-resize";
import { pick, sum } from "lodash";
import { combinedQuerySignature } from "@/common/lib/combiningQuery";

interface Datum {
  category: GraphValue | GraphCompoundValue | null;
  categoryId: string | null;
  value: number;
  tooltip: Record<string, string>;
  color: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PieChart',
  props: {
    visualization: { type: Object, required: true },
    results: { type: Array, required: true },
    width: { type: Number, required: true },
    height: { type: Number, required: true }
  },
  emits: ["select"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const legendWidth = ref(0);

const props = __props;
const { visualization, results, width, height } = toRefs(props);

const emit = __emit;

const query = () => combinedQuerySignature(visualization.value.query);
const generators = () => pick(visualization.value.config, "category", "value", "category_name");

const colors = computed(() => colorizer(query(), generators(), false, "category", true));
const valueSets = computed(() =>
  generateValueSets(generators(), results.value, query(), ["value"])
);

const data = computed(function () {
  return valueSets.value.map(function (values): Datum {
    const categoryId = stringifyValueOrCompositeValue(values.category?.originalValue);
    const categoryName = stringifyValue((values.category_name ?? values.category)?.formattedValue);
    return {
      category: values.category?.originalValue ?? null,
      categoryId,
      value: toNative(values.value!.originalValue as FloatValue),
      tooltip: { [categoryName]: stringifyValue(values.value!.formattedValue) },
      color: colors.value(values),
    };
  });
});

const legend = computed(function () {
  const config = visualization.value.config;
  return valueSets.value.map(function (values): LegendItem {
    return {
      identifier: values.category?.originalValue as GraphValue | undefined,
      label: values.category_name ?? values.category ?? emptyFormattedValue(),
      value: config.show_legend_values ? values.value! : undefined,
      color: colors.value(values),
    };
  });
});

const total = computed(function () {
  if (!visualization.value.config.show_total) return undefined;
  const query = combinedQuerySignature(visualization.value.query);
  const totalNum = sum(
    valueSets.value.map((vs) => toNative(vs.value!.originalValue as GraphValue))
  );
  return generatePropertyValue(visualization.value.config.value, toValue(totalNum), query);
});

const spec: ComputedRef<vega.Spec> = computed(function () {
  const chartWidth = (width.value ?? 370) - legendWidth.value - 10;
  const chartHeight = (height.value ?? 300) - 10;
  const outerRadius = Math.min(chartWidth, chartHeight) / 2;
  const innerRadius = outerRadius * 0.66;
  const spec: vega.Spec = {
    width: chartWidth,
    height: chartHeight,
    padding: 5,
    autosize: "fit",
    data: [
      {
        name: "table",
        values: data.value,
        transform: [
          {
            type: "pie",
            field: "value",
          },
        ],
      },
    ],
    signals: [
      {
        name: "selection",
        value: null,
        on: [{ events: "@arc:click", update: "datum.category" }],
      },
    ],
    marks: [
      {
        name: "arc",
        type: "arc",
        from: { data: "table" },
        encode: {
          enter: {
            fill: { signal: "datum.color" },
            x: { value: outerRadius },
            y: { value: chartHeight / 2 },
            startAngle: { field: "startAngle" },
            endAngle: { field: "endAngle" },
            cursor: { value: "pointer" },
            tooltip: { signal: "datum.tooltip" },
          },
          update: {
            innerRadius: { value: innerRadius },
            outerRadius: { value: outerRadius },
          },
          hover: {
            innerRadius: { value: innerRadius - 5 },
            outerRadius: { value: outerRadius + 5 },
          },
        },
      },
    ],
  };
  return spec;
});

function handleSelect(category: unknown) {
  const alias = generatorAlias(visualization.value.config.category);
  if (alias) emit("select", alias, category as GraphValue | null);
}

function handleLegendResize({ width: x }: { width: number }) {
  legendWidth.value = x;
}

const __returned__ = { legendWidth, props, visualization, results, width, height, emit, query, generators, colors, valueSets, data, legend, total, spec, handleSelect, handleLegendResize, Chart, Legend, get ResizeObserver() { return ResizeObserver } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})