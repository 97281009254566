<template>
  <div class="absolute inset-0 z-0 overflow-auto" data-test="table">
    <table class="z-10 border-collapse">
      <thead>
        <tr>
          <th class="sticky top-0 z-10 bg-gray-200 p-0">
            <div class="flex h-[25px] border-b border-r border-white px-10"></div>
          </th>
          <template v-for="(colName, index) in columns" :key="index">
            <template
              v-if="!visualization.config.skip_months || index >= visualization.config.skip_months"
            >
              <!-- Value -->
              <th class="sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline">
                <div
                  class="flex h-[25px] items-center justify-start whitespace-nowrap border-b border-r border-white px-10 text-right"
                >
                  <div class="mx-10">{{ colName }}</div>
                </div>
              </th>
              <th v-if="index > 0" class="sticky top-0 z-10 bg-gray-200 p-0">
                <div class="flex h-[25px] border-b border-r border-white px-10"></div>
              </th>
              <th v-if="index > 0" class="sticky top-0 z-10 bg-gray-200 p-0">
                <div class="flex h-[25px] border-b border-r border-white px-10"></div>
              </th>
            </template>
          </template>
          <template v-for="(col, index) in trailingColumns" :key="index">
            <th
              class="sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline"
              :class="{ 'border-l-[8px] border-solid border-l-finance-highlight': index === 0 }"
            >
              <div
                class="flex h-[25px] items-center justify-end whitespace-nowrap border-b border-r border-white px-10 text-right"
              >
                <div class="mx-auto">{{ col.name }}</div>
              </div>
            </th>
            <th class="sticky top-0 z-10 bg-gray-200 p-0">
              <div class="flex h-[25px] border-b border-r border-white px-10"></div>
            </th>
          </template>
          <th class="sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline">
            <div
              class="flex h-[25px] items-center justify-end whitespace-nowrap border-b border-r border-white px-10 text-right"
            >
              <div class="mx-auto">Variance</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <FinancialSubtable
          :rows="table"
          :columns="columns"
          :trailingColumns="trailingColumns.map((col) => col.name)"
          :depth="0"
          :expanded-map="expandedMap"
          @update-expanded="updateExpanded"
          :skip-months="visualization.config.skip_months"
        />
      </tbody>
    </table>
    <div class="my-10 flex gap-10">
      <TextButton label="CSV Export" :secondary="true" @click="() => exportData(ExportType.CSV)" />
      <TextButton
        label="Excel Export"
        :secondary="true"
        @click="() => exportData(ExportType.Excel)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
%column-styles {
  @apply bg-finance-header_hover;
}
@for $i from 2 to 64 {
  table:has(tr > *:nth-child(#{$i}):hover) tr > *:nth-child(#{$i}) {
    @extend %column-styles;
  }
}
</style>

<script lang="ts" setup>
import { generateValue, generatorAlias } from "@/reader/lib/visualization";
import {
  FinancialStatementRow,
  FinancialStatementVisualization,
} from "@/reader/lib/visualizationTypes";
import { computed, onMounted, ref, toRefs } from "vue";
import {
  GraphValue,
  GraphValueType,
  isValue,
  stringifyValue,
  toNative,
  toValue,
} from "@/common/lib/value";
import FinancialSubtable from "./financial-statement/FinancialSubtable.vue";
import { TRANSFORMERS, ValueWithFormattedValue } from "@/common/lib/format";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { drop, isNil, isNumber, last, min, range, take } from "lodash";
import { GraphCompoundValue } from "@/common/lib/graph";
import { DateTime } from "luxon";
import { toggle } from "@/common/lib/set";
import { getCookie, setCookie } from "@/common/lib/cookie";
import { combinedQuerySignature } from "@/common/lib/combiningQuery";
import { excelExport, ExportType } from "@/common/lib/export";
import TextButton from "@/common/components/TextButton.vue";
import ExcelJS from "exceljs";
import { useUserStore } from "@/common/stores/userStore";
import { environment } from "@/common/environments/environmentLoader";

const props = defineProps<{
  visualization: FinancialStatementVisualization;
  results: UseQueryResult[];
  width: number;
  height: number;
}>();

defineEmits<{ select: [alias: string, value: GraphValue | null] }>();

const { visualization, results } = toRefs(props);
const trailingMonths = computed(function () {
  return visualization.value.config.trailing_months ?? [];
});
const ytdTrailingMonths = computed<number>(function () {
  const numCol = results.value.length;
  const value = columnValues.value[numCol - 1];
  if (
    value?.originalValue._type !== GraphValueType.Date &&
    value?.originalValue._type !== GraphValueType.Datetime
  ) {
    return numCol;
  }
  const date = toNative(value?.originalValue);
  const month = date.month;
  return min([month, numCol]) ?? numCol;
});

const columnValues = computed(function () {
  const query = combinedQuerySignature(visualization.value.query);
  const gen = visualization.value.config.columns;
  return results.value.map(function (period) {
    return generateValue(gen, period, query);
  });
});

const expandedMap = ref(new Set<string>());

function updateExpanded(rowLabel: string) {
  toggle(expandedMap.value, rowLabel);
  const maxAge = environment.requireNumber("FINANCIAL_REPORT_EXPANSION_MAX_AGE_SECONDS");
  setCookie("financial-statement-selection", JSON.stringify(Array.from(expandedMap.value)), maxAge);
}

export interface FinComputedCell {
  value?: ValueWithFormattedValue;
  overComparisonValue?: ValueWithFormattedValue;
  overPreviousPeriod?: ValueWithFormattedValue;
  asset: number;
}

export interface FinComputedRow {
  label: string;
  cells: FinComputedCell[];
  contents?: FinComputedRow[];
  highlight: boolean;
  variance?: ValueWithFormattedValue;
}

const columns = computed(function () {
  return columnValues.value.map(function (value) {
    return value ? stringifyValue(value.formattedValue) : "???";
  });
});

const trailingColumns = computed(function () {
  const trailingColumnNames = trailingMonths.value
    .filter((month) => isTrailingAvailable(month))
    .map((month) => {
      return { name: `T${month}M`, months: month };
    });
  if (showYTD()) {
    return [{ name: "YTD", months: ytdTrailingMonths.value }, ...trailingColumnNames];
  }
  return trailingColumnNames;
});

function showYTD() {
  return visualization.value.config.ytd && isTrailingAvailable(ytdTrailingMonths.value);
}

function areConsecutiveMonths(dates: DateTime[]) {
  dates.sort((a, b) => a.year - b.year || a.month - b.month);

  for (let i = 1; i < dates.length; i++) {
    const prev = dates[i - 1];
    const current = dates[i];

    const monthDifference = (current.year - prev.year) * 12 + (current.month - prev.month);
    if (monthDifference !== 1) {
      return false;
    }
  }
  return true;
}

function isTrailingAvailable(months: number): boolean {
  if (columnValues.value.length < months) {
    return false;
  }
  const dates = columnValues.value
    .slice(-months)
    .map((value) => value?.originalValue)
    .filter((value) => !isNil(value))
    .flatMap((value) => (value._type === GraphValueType.Datetime ? [toNative(value)] : []));
  return areConsecutiveMonths(dates);
}

function buildRow(
  rowDef: FinancialStatementRow,
  comparisonValues?: (GraphValue | GraphCompoundValue | undefined)[]
): FinComputedRow {
  const cells = buildCells(rowDef, comparisonValues);
  const query = combinedQuerySignature(visualization.value.query);
  const alias = generatorAlias(rowDef.value) ?? "???";

  const additionalSums: FinComputedCell[] = trailingColumns.value.map((col, row) => {
    const sum = cells.slice(-col.months).reduce((total, cell) => {
      const originalValue = cell.value?.originalValue?.value;
      const numericValue = Number(originalValue) || 0;
      return total + numericValue;
    }, 0);

    const result = {
      valuesByAlias: {
        [alias]: [{ _type: GraphValueType.Float, value: sum.toString() }],
      },
    } as UseQueryResult;
    const generatedValue = generateValue(rowDef.value, result, query) ?? undefined;

    const overComparisonValue = compareValues(
      generatedValue?.originalValue,
      comparisonValues?.[cells.length + row],
      false
    );

    return { value: generatedValue, overComparisonValue, asset: 0 };
  });

  const extendedCells = [...cells, ...additionalSums];
  const variance = subtractValues(
    additionalSums[additionalSums.length - 1].overComparisonValue?.originalValue,
    additionalSums[additionalSums.length - 2].overComparisonValue?.originalValue
  );

  return {
    label: rowDef.label,
    cells: extendedCells,
    contents:
      rowDef.contents == null ? undefined : buildSubtable(rowDef.contents, comparisonValues),
    highlight: rowDef.highlight ?? false,
    variance,
  };
}

function buildSubtable(
  rowDefs: FinancialStatementRow[],
  comparisonValues?: (GraphValue | GraphCompoundValue | undefined)[]
): FinComputedRow[] {
  return rowDefs.map((rowDef) => buildRow(rowDef, comparisonValues));
}

function buildCells(
  rowDef: FinancialStatementRow,
  comparisonValues?: (GraphValue | GraphCompoundValue | undefined)[]
): FinComputedCell[] {
  const query = combinedQuerySignature(visualization.value.query);
  const cells: FinComputedCell[] = [];

  for (const index of range(results.value.length)) {
    const period = results.value[index];
    const value = generateValue(rowDef.value, period, query) ?? undefined;

    const overComparisonValue = compareValues(
      value?.originalValue,
      comparisonValues?.[index],
      false
    );

    const overPreviousPeriod = compareValues(
      last(cells)?.value?.originalValue,
      value?.originalValue,
      true
    );
    cells.push({
      value,
      overComparisonValue,
      overPreviousPeriod,
      asset: rowDef.asset === true ? 1 : -1,
    });
  }
  return cells;
}

function compareValues(
  val1: GraphValue | GraphCompoundValue | undefined,
  val2: GraphValue | GraphCompoundValue | undefined,
  isChange: boolean
): ValueWithFormattedValue | undefined {
  if (!isValue(val1) || !isValue(val2)) return undefined;
  const num1 = Number(val1?.value) ?? 0;
  const num2 = Number(val2?.value) ?? 0;
  let ratio;
  if (isChange) {
    if (num1 <= 0 || num2 < 0) return undefined;
    ratio = (num2 - num1) / num1;
  } else {
    if (num2 === 0) return undefined;
    ratio = num1 / num2;
  }
  return {
    originalValue: toValue(ratio),
    formattedValue: TRANSFORMERS[isChange ? "percentChange" : "percent"](toValue(ratio)),
  };
}

function subtractValues(
  val1: GraphValue | GraphCompoundValue | undefined,
  val2: GraphValue | GraphCompoundValue | undefined
): ValueWithFormattedValue | undefined {
  if (!isValue(val1) || !isValue(val2)) return undefined;
  const num1 = Number(val1?.value) ?? 0;
  const num2 = Number(val2?.value) ?? 0;
  const value = num2 - num1;
  return {
    originalValue: toValue(value),
    formattedValue: TRANSFORMERS["percentChange"](toValue(value)),
  };
}

function formatValue(value: ValueWithFormattedValue | undefined) {
  if (isNil(value)) {
    return undefined;
  }
  if (!isValue(value.originalValue)) {
    return toNative(value.formattedValue);
  }
  const result = toNative(value.originalValue);
  if (isNumber(result)) {
    return Math.floor(result * 100) / 100;
  }
  return result;
}

async function exportData(exportType: ExportType) {
  const userName = useUserStore().user?.name;

  const skipMonths = visualization.value.config.skip_months ?? 0;
  const colsInRange = drop(columns.value, skipMonths);
  const rowsToProcess = table.value;
  const data: unknown[][] = [];
  while (rowsToProcess.length > 0) {
    const row = rowsToProcess.shift()!;
    const dataRow: unknown[] = [];
    dataRow.push(row.label);
    const cellsInRange = drop(row.cells, skipMonths);
    const baseCells = take(cellsInRange, colsInRange.length);
    const totalCells = drop(cellsInRange, colsInRange.length);
    for (const cell of baseCells) {
      dataRow.push(formatValue(cell.value));
      dataRow.push(formatValue(cell.overComparisonValue));
      dataRow.push(formatValue(cell.overPreviousPeriod));
    }
    for (const cell of totalCells) {
      dataRow.push(formatValue(cell.value));
      dataRow.push(formatValue(cell.overComparisonValue));
    }
    dataRow.push(formatValue(row.variance));

    if (row.contents) {
      rowsToProcess.unshift(...row.contents);
    }
    data.push(dataRow);
  }
  const expandedColumns = [""];

  for (const col of colsInRange) {
    expandedColumns.push(col);
    expandedColumns.push("");
    expandedColumns.push("");
  }
  for (const col of trailingColumns.value) {
    expandedColumns.push(col.name);
    expandedColumns.push("");
  }
  expandedColumns.push("Variance");

  data.unshift(expandedColumns);

  const currencyColumn: Partial<ExcelJS.Column> = {
    width: 14,
    style: {
      numFmt: '"$"#,##0.00;[Red]-"$"#,##0.00',
    },
  };
  const percentageColumn: Partial<ExcelJS.Column> = {
    width: 8,
    style: {
      numFmt: "0%",
    },
  };
  const exportColumns: Partial<ExcelJS.Column>[] = [
    { width: 24, style: { font: { bold: true } } }, // Label
    currencyColumn,
    percentageColumn,
    percentageColumn,
    currencyColumn,
    percentageColumn,
    percentageColumn,
    currencyColumn,
    percentageColumn,
    percentageColumn,
    currencyColumn, // T3M
    percentageColumn,
    currencyColumn, // T12M
    percentageColumn,
    percentageColumn, // Variance
  ];

  await excelExport(data, {
    filename: "operational_scorecard",
    worksheetTitle: "Operational Scorecard",
    exportType,
    author: userName,
    columns: exportColumns,
  });
}

const table = computed(() => {
  const firstRow = buildRow(visualization.value.config.rows[0]);
  const comparisonValues = firstRow.cells.map((cell) => cell.value?.originalValue);
  trailingColumns;
  return buildSubtable(visualization.value.config.rows, comparisonValues);
});

onMounted(() => {
  const jsonValue = getCookie("financial-statement-selection");
  if (jsonValue) {
    const value = JSON.parse(jsonValue);
    expandedMap.value = new Set(value);
  }
});
</script>
