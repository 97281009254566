import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col items-center justify-around"
}
const _hoisted_3 = { class: "flex flex-row items-center justify-end gap-10 pt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    title: "Edit project details",
    icon: "md:edit_note",
    "skip-default-footer": true
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        ($setup.state !== $setup.State.Saving)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode($setup["TextButton"], {
                label: "Cancel",
                secondary: true,
                onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.emit('closed')))
              }),
              ($setup.state === $setup.State.Ok)
                ? (_openBlock(), _createBlock($setup["TextButton"], {
                    key: 0,
                    label: "Update",
                    onClick: $setup.saveProjectDetails,
                    "data-test": "success-button"
                  }))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createBlock($setup["SpinnerButton"], {
              key: 1,
              label: "Updating…"
            }))
      ])
    ]),
    default: _withCtx(() => [
      ($setup.state === $setup.State.Ok || $setup.state === $setup.State.Saving)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode($setup["Textbox"], {
              label: "Description",
              modelValue: $setup.projectDescription,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.projectDescription) = $event)),
              textarea: true,
              "min-height": "10em",
              disabled: $setup.state === $setup.State.Saving
            }, null, 8 /* PROPS */, ["modelValue", "disabled"]),
            _createVNode($setup["Textbox"], {
              label: "Additional LLM prompt",
              modelValue: $setup.projectLLMPrompt,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.projectLLMPrompt) = $event)),
              textarea: true,
              "min-height": "10em",
              disabled: $setup.state === $setup.State.Saving
            }, null, 8 /* PROPS */, ["modelValue", "disabled"])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.state === $setup.State.Loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[3] || (_cache[3] = _createElementVNode("span", null, "Loading…", -1 /* HOISTED */)),
            _createVNode($setup["Spinner"])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.error)
        ? (_openBlock(), _createBlock($setup["MessageBar"], {
            key: 2,
            mode: "error"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.error), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}