<template>
  <Checkbox :label="control.label" v-model="model" />
  <div v-if="control.description" class="text-xs text-gray-500">{{ control.description }}</div>
</template>

<script lang="ts" setup>
import { CheckboxVisualizationConfigControl } from "@/reader/lib/visualizationConfig";
import { Query } from "@/common/lib/query";
import { toRefs } from "vue";
import Checkbox from "@/common/components/Checkbox.vue";

const props = defineProps<{ control: CheckboxVisualizationConfigControl; query: Query }>();
const { control } = toRefs(props);

const model = defineModel<boolean>({ default: false });
</script>
