import { defineComponent as _defineComponent } from 'vue'
import Dialog from "@/common/components/Dialog.vue";
import SpinnerButton from "@/common/components/SpinnerButton.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { useKeyHandler } from "@/common/composables/useKeyHandler";
import { KeyCommand } from "@/common/lib/modifierKeys";
import { useUserModuleStore } from "@/common/stores/userModuleStore";
import { onMounted, ref } from "vue";
import Spinner from "./Spinner.vue";
import MessageBar from "./MessageBar.vue";

enum State {
  Loading = "loading",
  Saving = "saving",
  Fatal = "fatal",
  Ok = "ok",
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EditProjectDetails',
  props: {
    projectId: { type: String, required: true }
  },
  emits: ["closed"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;
const userModuleStore = useUserModuleStore();

const state = ref(State.Loading);

const error = ref<string | undefined>();
const projectDescription = ref("");
const projectLLMPrompt = ref("");

async function saveProjectDetails() {
  state.value = State.Saving;
  error.value = undefined;
  try {
    const description = projectDescription.value.trim();
    const llmPrompt = projectLLMPrompt.value.trim();
    await userModuleStore.updateModule(props.projectId, {
      description,
      app_state: { llmPrompt },
    });
    emit("closed");
  } catch (e) {
    error.value = String(e);
  } finally {
    state.value = State.Ok;
  }
}

const keyHandler = useKeyHandler();
keyHandler.registerHandler(new KeyCommand("Escape"), () => emit("closed"), true, false);
keyHandler.registerHandler(new KeyCommand("Enter"), () => saveProjectDetails(), true, true);

onMounted(async () => {
  try {
    const moduleResponse = await userModuleStore.loadModule(props.projectId);
    projectDescription.value = moduleResponse.module.manifest.description ?? "";
    projectLLMPrompt.value = moduleResponse.module.app_state?.llmPrompt ?? "";
    state.value = State.Ok;
  } catch (e) {
    state.value = State.Fatal;
    error.value = String(e);
  }
});

const __returned__ = { props, emit, userModuleStore, State, state, error, projectDescription, projectLLMPrompt, saveProjectDetails, keyHandler, Dialog, SpinnerButton, Textbox, TextButton, Spinner, MessageBar }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})