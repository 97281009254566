<template>
  <Dropdown placement="right" v-model:shown="isEditing" @apply-show="focusFirstField">
    <template #popper>
      <FilterEditor
        :label="formLabel"
        @cancel="isEditing = false"
        @commit="commit"
        :valid="isEditValid"
      >
        <SelectStrip
          v-if="valueType === GraphValueType.Datetime"
          :model-value="specifyTimeOfDay"
          @update:model-value="(v) => setSpecifyTimeOfDay(v as boolean)"
          :options="[
            { label: 'Date', value: false },
            { label: 'Date & Time', value: true },
          ]"
          class="mb-5 h-[28px]"
        />
        <Textbox
          :type="inputType"
          v-model="lowerBoundUnderEdit"
          ref="lowerBoundInputEl"
          :label="inputType === 'date' ? 'Start date' : 'Minimum value'"
          class="w-full"
        />
        <Textbox
          :type="inputType"
          v-model="upperBoundUnderEdit"
          :label="inputType === 'date' ? 'End date' : 'Maximum value'"
          class="w-full"
        />
      </FilterEditor>
    </template>
    <div
      @click="startEditing"
      class="cursor-pointer border-b border-b-transparent hover:border-b-gray-500"
    >
      {{ opDescription }}
      <template v-if="filter.values.length > 0">
        <template v-if="isExact">
          exactly
          <span class="font-bold">{{ stringifyValue(lowerBound!) }}</span>
        </template>
        <template v-else>
          <template v-if="upperBound == null">
            at least
            <span class="font-bold">{{ stringifyValue(lowerBound!) }}</span>
          </template>
          <template v-else-if="lowerBound == null">
            at most
            <span class="font-bold">{{ stringifyValue(upperBound) }}</span>
          </template>
          <template v-else>
            between
            <span class="font-bold">{{ stringifyValue(lowerBound) }}</span>
            and
            <span class="font-bold">{{ stringifyValue(upperBound) }}</span>
          </template>
        </template>
      </template>
      <template v-else>(click to set)</template>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
import { stringifyValue, toNative, toTypedValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { isNumber, isString } from "lodash";
import { computed, nextTick, onMounted, Ref, ref, toRefs } from "vue";
import { GraphValueType } from "@/common/lib/value";
import { propertyName, propertyValueType } from "@/common/lib/derived";
import { QueryFilter } from "@/common/lib/query";
import { RangeFilter } from "@/common/lib/fetchApi";
import { Dropdown } from "floating-vue";
import FilterEditor from "../FilterEditor.vue";
import Textbox from "@/common/components/Textbox.vue";
import { DateTime } from "luxon";
import SelectStrip from "@/editor/components/SelectStrip.vue";

const props = defineProps<{ filter: QueryFilter<RangeFilter> }>();
const { filter } = toRefs(props);

const exploreStore = useExploreStore();

const lowerBoundUnderEdit: Ref<number | string | "" | undefined> = ref(undefined);
const upperBoundUnderEdit: Ref<number | string | "" | undefined> = ref(undefined);
const lowerBoundInputEl: Ref<HTMLInputElement | null> = ref(null);
const isEditing: Ref<boolean> = ref(false);
const specifyTimeOfDay: Ref<boolean> = ref(false);

const valueType = computed(() => propertyValueType(filter.value.property_type) as GraphValueType);
const lowerBound = computed(() => filter.value.values[0]?.gte ?? filter.value.values[0]?.gt);
const upperBound = computed(() => filter.value.values[0]?.lte ?? filter.value.values[0]?.lt);
const isExact = computed(() => lowerBound.value?.value === upperBound.value?.value);

const opDescription = computed(() => (filter.value.negated ? "is not" : "is"));
const formLabel = computed(
  () => `${propertyName(filter.value.property_type)} ${opDescription.value} between`
);

const inputType = computed(() => {
  switch (valueType.value) {
    case GraphValueType.Date:
      return "date";
    case GraphValueType.Datetime:
      if (specifyTimeOfDay.value) {
        return "datetime-local";
      } else {
        return "date";
      }
    default:
      return "number";
  }
});

function isDate() {
  return [GraphValueType.Date, GraphValueType.Datetime].includes(valueType.value);
}

function dateToInputString(dt: DateTime) {
  return specifyTimeOfDay.value
    ? dt.toISO({ includeOffset: false, suppressMilliseconds: true })!
    : dt.toISODate()!;
}

function startEditing() {
  if (isDate()) {
    specifyTimeOfDay.value =
      valueType.value === GraphValueType.Datetime &&
      (lowerBound.value?._type === GraphValueType.Datetime ||
        upperBound.value?._type === GraphValueType.Datetime);
    lowerBoundUnderEdit.value =
      lowerBound.value == null ? "" : dateToInputString(toNative(lowerBound.value) as DateTime);
    upperBoundUnderEdit.value =
      upperBound.value == null ? "" : dateToInputString(toNative(upperBound.value) as DateTime);
  } else {
    lowerBoundUnderEdit.value = lowerBound.value ? (toNative(lowerBound.value) as number) : "";
    upperBoundUnderEdit.value = upperBound.value ? (toNative(upperBound.value) as number) : "";
  }
  isEditing.value = true;
}

function setSpecifyTimeOfDay(newValue: boolean) {
  const lower = (lowerBoundUnderEdit.value ?? "") as string;
  const upper = (upperBoundUnderEdit.value ?? "") as string;
  specifyTimeOfDay.value = newValue;
  nextTick(function () {
    // After input types change, convert dates to datetimes or vice versa
    const convert = (ds: string) => dateToInputString(DateTime.fromISO(ds));
    if (lower.length > 0) lowerBoundUnderEdit.value = convert(lower);
    if (upper.length > 0) upperBoundUnderEdit.value = convert(upper);
  });
}

function focusFirstField() {
  setTimeout(() => lowerBoundInputEl.value?.focus(), 50); // Boy do I hate this
}

function commit() {
  const rangeFilter: Partial<RangeFilter> = {};
  if (isNumber(lowerBoundUnderEdit.value)) {
    rangeFilter.gte = toTypedValue(lowerBoundUnderEdit.value, valueType.value);
  }
  if (isNumber(upperBoundUnderEdit.value)) {
    rangeFilter.lte = toTypedValue(upperBoundUnderEdit.value, valueType.value);
  }
  if (isString(lowerBoundUnderEdit.value) && lowerBoundUnderEdit.value !== "") {
    rangeFilter.gte = {
      _type: inputType.value === "date" ? GraphValueType.Date : GraphValueType.Datetime,
      value: lowerBoundUnderEdit.value,
    };
  }
  if (isString(upperBoundUnderEdit.value) && upperBoundUnderEdit.value !== "") {
    rangeFilter.lte = {
      _type: inputType.value === "date" ? GraphValueType.Date : GraphValueType.Datetime,
      value: upperBoundUnderEdit.value,
    };
  }
  filter.value.values = [rangeFilter];
  isEditing.value = false;
  exploreStore.load();
}

const isEditValid = computed(function () {
  // At least one bound must be present
  let lowerIsSet, upperIsSet;
  if (isDate()) {
    lowerIsSet = !!lowerBoundUnderEdit.value;
    upperIsSet = !!upperBoundUnderEdit.value;
  } else {
    lowerIsSet = isNumber(lowerBoundUnderEdit.value);
    upperIsSet = isNumber(upperBoundUnderEdit.value);
  }
  if (!lowerIsSet && !upperIsSet) return false;

  // If both bounds are present, bounds must not be inverted
  if (lowerIsSet && upperIsSet) {
    if (isDate()) {
      const lowerDate = DateTime.fromISO(lowerBoundUnderEdit.value as string);
      const upperDate = DateTime.fromISO(upperBoundUnderEdit.value as string);
      if (lowerDate > upperDate) return false;
    } else {
      if (lowerBoundUnderEdit.value! > upperBoundUnderEdit.value!) return false;
    }
  }

  return true;
});

onMounted(function () {
  if (filter.value.values.length === 0) startEditing();
});
</script>
