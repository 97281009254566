import { defineComponent as _defineComponent } from 'vue'
import useKnowledge from "@/common/composables/useKnowledge";
import { computed, toRefs } from "vue";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { conceptPropertyValueDisplay } from "@/reader/lib/concept";


export default /*@__PURE__*/_defineComponent({
  __name: 'ValueGroup',
  props: {
    values: { type: Array, required: true },
    conceptType: { type: null, required: true },
    propertyType: { type: null, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { values, propertyType, conceptType } = toRefs(props);

const { typeLabel } = useKnowledge();

const propTypeLabel = computed(() => typeLabel(propertyType.value));

const valueDetails = computed(function () {
  return values.value.map(function (value) {
    const display = conceptPropertyValueDisplay(
      conceptType.value,
      propertyType.value,
      value.originalValue
    );
    return {
      value: value.formattedValue,
      color: display?.color ?? null,
    };
  });
});

const __returned__ = { props, values, propertyType, conceptType, typeLabel, propTypeLabel, valueDetails, GraphValueComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})