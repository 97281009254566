import { compact, isString } from "lodash";
import { propertyName } from "./derived";
import {
  BasePropertyFilter,
  EqualityFilter,
  ExistenceFilter,
  FilterType,
  FilterValue,
  GROUP_BY_ALL,
  PropertyFilter,
  RangeFilter,
} from "./fetchApi";
import { LinkDescriptor } from "./graph";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "./knowledge";
import { QueryPropertyTerm } from "./queryProperties";
import { GraphValue } from "./value";

// This is the high level "only one way to do it" query interface, built on top
// of the low-level interface available at fetchApi.ts. It's designed to be
// easier to display as a UX and to be written by the LLM

export interface Query extends BaseQuery {
  root_concept_type: ConceptKnowledgeRef;
}

export interface QueryBranch extends BaseQuery {
  path: QueryPathNode[];
  alias: string;
}

export interface BaseQuery {
  columns: QueryColumn[];
  filters: (QueryFilter | ConcreteQueryFilter)[];
  order_by: QueryOrderBy[];
  group_by: string[] | typeof GROUP_BY_ALL; // The array is of column aliases
  size?: number;
  branches?: QueryBranch[];
}

export interface QueryColumn {
  alias: string;
  property_type: QueryPropertyTerm;
  path?: QueryPathNode[]; // If no path, this property lives on the root concept.
  displayName?: string;
}

export interface QueryPathNode {
  link_descriptor?: LinkDescriptor;
  concept_type: ConceptKnowledgeRef;
}

/**
 * These are redundant types that help with JSON schema validation.
 * Without them, the validation seems to get confused about the generic version of QueryFilter
 * and fails to validate otherwise valid JSON.
 */
export type ConcreteQueryFilter =
  | RangeQueryFilter
  | EqualityQueryFilter
  | ExistenceQueryFilter
  | TextQueryFilter;

export interface RangeQueryFilter extends QueryFilter<RangeFilter> {
  type: FilterType.Range;
  values: FilterValue<RangeFilter>[];
}
export interface EqualityQueryFilter extends QueryFilter<EqualityFilter> {
  type: FilterType.Equality;
  values: FilterValue<EqualityFilter>[];
}
export interface ExistenceQueryFilter extends QueryFilter<ExistenceFilter> {
  type: FilterType.Exists;
  values: FilterValue<ExistenceFilter>[];
}
export interface TextQueryFilter extends QueryFilter<TextFilter> {
  type: FilterType.Text;
  values: FilterValue<TextFilter>[];
}

export interface QueryFilter<T extends PropertyFilter = PropertyFilter> {
  alias: string;
  path?: QueryPathNode[];
  type: T["type"];
  property_type?: PropertyKnowledgeRef;
  on?: string; // a column alias, in lieu of a property_type
  values: FilterValue<T>[];
  negated: boolean;
}

export interface QueryOrderBy {
  on: string; // a column alias
  asc: boolean;
}

// Uniquely identifies a neighbor concept within a query
export interface QueryNeighborAddress {
  path: QueryPathNode[]; // Relative to the branch root
  branch?: string; // ...or if no branch, to the query root concept
}

export function filterIsComplete(filter: QueryFilter) {
  return filter.type === FilterType.Exists || filter.values.length > 0;
}

export function emptyQuery(rootConceptType: ConceptKnowledgeRef): Query {
  return {
    root_concept_type: rootConceptType,
    order_by: [],
    group_by: [],
    filters: [],
    columns: [],
  };
}

export function findDeepColumnByAlias(query: BaseQuery, columnAlias: string) {
  const branches = allQueryBranches(query);
  for (const branch of branches) {
    const column = branch.columns.find((c) => c.alias === columnAlias);
    if (column) return column;
  }
  return undefined;
}

export function allQueryBranches(query: BaseQuery) {
  const branches: BaseQuery[] = [];
  function enumerateBranches(branch: BaseQuery) {
    branches.push(branch);
    branch.branches?.forEach((b) => enumerateBranches(b));
  }
  enumerateBranches(query);
  return branches;
}

// For convenience, if you don't pass an alias, you'll get the root query back
export function findQueryBranchByAlias(query: BaseQuery, aliasSought?: string) {
  function findBranch(branch: BaseQuery): QueryBranch | null {
    for (const subbranch of branch.branches ?? []) {
      if (subbranch.alias === aliasSought) return subbranch;
      const result = findBranch(subbranch);
      if (result != null) return result;
    }
    return null;
  }
  if (aliasSought == null) return query;
  return findBranch(query);
}

export function allPathsInQuery(query: Query) {
  return compact([...query.columns.map((c) => c.path), ...query.filters.map((f) => f.path)]);
}

export function pathsEquivalent(path1: QueryPathNode[], path2: QueryPathNode[]) {
  if (path1.length !== path2.length) return false;
  for (let i = 0; i != path1.length; i++) {
    if (path1[i].concept_type !== path2[i].concept_type) return false;
    if (path1[i].link_descriptor !== path2[i].link_descriptor) return false;
  }
  return true;
}

// Returns true if the second path is the same as the first path, or is deeper within the first
export function pathIsWithin(container: QueryPathNode[], possiblyContained: QueryPathNode[]) {
  if (possiblyContained.length < container.length) return false;
  const prefix = possiblyContained.slice(0, container.length);
  return pathsEquivalent(container, prefix);
}

export function columnName(column: QueryColumn): string {
  return propertyName(column.property_type, column.path, column.displayName);
}

export function columnIsDerived(column: QueryColumn) {
  return !isString(column.property_type);
}

export enum TextFilterMatch {
  Start = "start",
  End = "end",
  Contain = "contain",
  Full = "full",
}

export const textFilterMatchOptions: TextFilterMatch[] = [
  TextFilterMatch.Contain,
  TextFilterMatch.Start,
  TextFilterMatch.End,
  TextFilterMatch.Full,
];

export interface TextFilter extends BasePropertyFilter {
  type: FilterType.Text;
  value: GraphValue;
  match: TextFilterMatch;
  case_sensitive: boolean;
}
