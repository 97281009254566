import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_3 = { class: "flex flex-col gap-5" }
const _hoisted_4 = {
  key: 0,
  class: "text-500 italic"
}
const _hoisted_5 = {
  key: 2,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_6 = { class: "flex flex-col items-start gap-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-xl" }, "Name", -1 /* HOISTED */)),
          _createElementVNode("div", null, _toDisplayString($setup.user.name), 1 /* TEXT */),
          _createElementVNode("div", null, [
            _createVNode($setup["TextButton"], {
              label: "Update name",
              onClick: $setup.editUserName
            })
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-xl" }, "Email", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString($setup.user.email), 1 /* TEXT */),
            ($setup.user.email_verified)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "verified"))
              : _createCommentVNode("v-if", true)
          ]),
          _createVNode($setup["TextButton"], {
            label: "Resync",
            onClick: $setup.resync
          })
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-xl" }, "Password", -1 /* HOISTED */)),
          _createElementVNode("div", null, [
            _createVNode($setup["TextButton"], {
              label: "Change password",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.editingChangePassword = { newPassword: '', confirmNewPassword: '' }))
            })
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_6, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-xl" }, "Session", -1 /* HOISTED */)),
      _createVNode($setup["TextButton"], {
        label: "Logout",
        onClick: $setup.logout
      })
    ]),
    ($setup.editingUserName !== undefined)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 3,
          title: "Update name",
          "success-label": "Update",
          onSucceeded: $setup.finishEditUserName,
          onCancelled: _cache[2] || (_cache[2] = ($event: any) => ($setup.editingUserName = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Textbox"], {
              label: "Name",
              modelValue: $setup.editingUserName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.editingUserName) = $event)),
              autoselect: true
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.editingChangePassword !== undefined)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 4,
          title: "Change password",
          "success-label": "Update",
          onSucceeded: $setup.finishChangePassword,
          onCancelled: _cache[4] || (_cache[4] = ($event: any) => ($setup.editingChangePassword = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Textbox"], {
              label: "New password",
              modelValue: $setup.editingChangePassword.newPassword,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.editingChangePassword.newPassword) = $event)),
              autoselect: true,
              autocomplete: "new-password"
            }, null, 8 /* PROPS */, ["modelValue"]),
            ($setup.editingChangePassword.error)
              ? (_openBlock(), _createBlock($setup["MessageBar"], {
                  key: 0,
                  mode: "error"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString($setup.editingChangePassword.error), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}