import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import {
  DateTruncPropertyType,
  propertyName,
  PropertyOpType,
  propertyValueType,
  validDerivedPropertyTermType,
} from "@/common/lib/derived";
import {
  FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE,
  FilterType,
  GROUP_BY_ALL,
} from "@/common/lib/fetchApi";
import { GraphCompoundValue } from "@/common/lib/graph";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { MenuItem } from "@/common/lib/menus";
import { columnIsDerived, QueryColumn, QueryFilter, QueryPathNode } from "@/common/lib/query";
import { GraphValue, GraphValueType, isValue, stringifyValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { capitalize, isEqual, last, omit, sortBy } from "lodash";
import pluralize from "pluralize";
import { v4 as uuid } from "uuid";
import { TimeUnit } from "./../../common/lib/derived";
import { uniquelyIdentifiesConcept } from "./concept";
import {
  availablePivots,
  buildCountColumn,
  buildSimpleColumn,
  filterWithDefaults,
  findCurrentColumn,
  pathConceptType,
  SIMPLE_COLUMN_OPS,
} from "./explore";
import { expandTreePath, ExploreTreePath } from "./exploreTree";

export enum ExploreMenuSubject {
  PropertyType,
  ConceptType,
  Column,
}

export interface ExploreMenuContext {
  conceptPath?: ExploreTreePath;
  propertyType?: PropertyKnowledgeRef;
  columnAlias?: string;
}

export function exploreMenu(subjectType: ExploreMenuSubject, context: ExploreMenuContext) {
  const items: MenuItem[] = [];
  switch (subjectType) {
    case ExploreMenuSubject.PropertyType: {
      items.push(...propertyTypeMenu(context.conceptPath!, context.propertyType!));
      // If the non-aggregated column already exists, include a column menu for it
      const basicColumn = buildSimpleColumn(context.conceptPath!, context.propertyType!);
      const basicColumnAlias = findCurrentColumn(basicColumn);
      if (basicColumnAlias != null) {
        items.push(...columnMenu(basicColumnAlias));
      }
      break;
    }

    case ExploreMenuSubject.ConceptType:
      items.push(...conceptTypeMenu(context.conceptPath!));
      break;

    case ExploreMenuSubject.Column:
      items.push(...columnMenu(context.columnAlias!));
      break;
  }
  return items;
}

function conceptTypeMenu(treePath: ExploreTreePath) {
  const path = expandTreePath(treePath);
  const exploreStore = useExploreStore();
  const items: MenuItem[] = [];
  if (path != null || exploreStore.query!.group_by) {
    const column = buildCountColumn(path);
    if (findCurrentColumn(column) == null) {
      items.push({
        key: "add-concept-count",
        label: "Add Count",
        action: () => exploreStore.addColumn(column),
      });
    }
  }
  if (path == null) {
    items.push({
      key: "summarize-all",
      label: "Summarize All",
      action: () => exploreStore.setGroupBy(GROUP_BY_ALL),
    });
  }
  items.push({
    key: "add-calculation",
    label: "Add Calculation",
    action: () => (exploreStore.creatingCalculation = treePath),
  });

  items.push({
    key: "add-all",
    label: "Add all columns",
    action: () => selectAllColumns(treePath),
  });

  items.push({
    key: "remove-all",
    label: "Remove all columns",
    action: () => removeAllColumns(treePath),
  });
  return items;
}

function propertyTypeMenu(treePath: ExploreTreePath, propertyType: PropertyKnowledgeRef) {
  const exploreStore = useExploreStore();
  const exploreQuery = exploreStore.query!;
  const path = expandTreePath(treePath);
  const items: MenuItem[] = [];
  const valueType = propertyValueType(propertyType);
  const column = buildSimpleColumn(path, propertyType);
  for (const op of [undefined, ...SIMPLE_COLUMN_OPS]) {
    const opCol = buildSimpleColumn(path, propertyType, op);
    const name = propertyName(opCol.property_type);
    if (
      findCurrentColumn(opCol) == null &&
      (op == null || validDerivedPropertyTermType(op, valueType)) &&
      (op != null || exploreQuery.group_by !== GROUP_BY_ALL)
    ) {
      items.push({
        key: `add-${op ?? "basic"}`,
        label: `Add ${name} Column`,
        action: () => exploreStore.addColumn(opCol),
      });
    }
  }
  const validFilterTypes = FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE[valueType];
  const filterType = addableFilterType(validFilterTypes);
  if (filterType !== undefined) {
    items.push({
      key: "filter",
      label: "Add Filter",
      action: () =>
        exploreStore.addFilter(
          filterWithDefaults({
            type: filterType,
            property_type: propertyType,
            path: column.path,
          })
        ),
    });
  }
  if (validFilterTypes.includes(FilterType.Exists)) {
    items.push({
      key: "filter-exists",
      label: "Add Exists Filter",
      action: () =>
        exploreStore.addFilter(
          filterWithDefaults({
            type: FilterType.Exists,
            property_type: propertyType,
            path: column.path,
          })
        ),
    });
    items.push({
      key: "filter-not-exists",
      label: "Add Not Exists Filter",
      action: () =>
        exploreStore.addFilter(
          filterWithDefaults({
            type: FilterType.Exists,
            property_type: propertyType,
            path: column.path,
            negated: true,
          })
        ),
    });
  }
  items.push({
    key: "group",
    label: "Group by",
    action: function () {
      const newGroupBy = { alias: uuid(), property_type: propertyType, path: column.path };
      if (exploreQuery.group_by === GROUP_BY_ALL) {
        exploreStore.setGroupBy([newGroupBy]);
      } else {
        exploreStore.setGroupBy([...exploreStore.groupByColumns, newGroupBy]);
      }
    },
  });

  if (valueType === GraphValueType.Datetime || valueType === GraphValueType.Date) {
    const createGroupByAction = (bucketSize: string) => () => {
      const newGroupBy = {
        alias: uuid(),
        property_type: {
          op: PropertyOpType.DateTrunc,
          term: propertyType,
          bucket_size: bucketSize,
        } as DateTruncPropertyType,
        path: column.path,
      };
      if (exploreQuery.group_by === GROUP_BY_ALL) {
        exploreStore.setGroupBy([newGroupBy]);
      } else {
        exploreStore.setGroupBy([...exploreStore.groupByColumns, newGroupBy]);
      }
    };

    const groupByTimeOptions = [
      TimeUnit.Year,
      TimeUnit.Month,
      TimeUnit.Week,
      TimeUnit.Day,
      TimeUnit.Hour,
      TimeUnit.Minute,
      TimeUnit.Second,
    ];

    items.push({
      key: "group-by-time",
      label: "Group by time unit...",
      submenu: groupByTimeOptions.map((unit) => ({
        key: `group-by-${unit}`,
        label: capitalize(unit),
        action: createGroupByAction(unit),
      })),
    });
  }

  return items;
}

function columnMenu(columnAlias: string) {
  const exploreStore = useExploreStore();
  const column = exploreStore.columnByAlias(columnAlias)!;
  const items: MenuItem[] = [];
  items.push({
    key: "sort-asc",
    label: "Sort Ascending",
    action: () => exploreStore.setOrderBy([{ on: columnAlias, asc: true }]),
  });
  items.push({
    key: "sort-desc",
    label: "Sort Descending",
    action: () => exploreStore.setOrderBy([{ on: columnAlias, asc: false }]),
  });
  if (columnIsDerived(column)) {
    const valueType = propertyValueType(column.property_type);
    if (valueType != null) {
      const validFilterTypes = FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE[valueType];
      const filterType = addableFilterType(validFilterTypes);
      if (filterType !== undefined) {
        items.push({
          key: "add-column-filter",
          label: "Add Filter",
          action: () =>
            exploreStore.addFilter(
              filterWithDefaults({ type: filterType, path: column.path, on: columnAlias })
            ),
        });
      }
    }
  }
  items.push({
    key: "remove",
    label: "Remove Column",
    action: () => exploreStore.removeColumn(columnAlias),
  });
  return items;
}

function selectAllColumns(treePath: ExploreTreePath) {
  const exploreStore = useExploreStore();
  const queryPath = expandTreePath(treePath);
  const { getConceptsOfType } = useGraph(() => exploreStore.metagraph);
  treePath.forEach((path) => {
    const properties = getConceptsOfType(path.concept_type)[0].properties ?? [];
    properties.forEach((property) => {
      const currentColumn = findCurrentColumn({ property_type: property.type, path: queryPath });
      if (currentColumn == null) {
        exploreStore.addColumn(buildSimpleColumn(queryPath, property.type));
      }
    });
  });
}

export function propertyValueMenu(
  value: GraphValue | GraphCompoundValue,
  formattedValue: GraphValue,
  columnDef?: QueryColumn
) {
  const { typeLabel } = useKnowledge();
  const exploreStore = useExploreStore();
  const items: MenuItem[] = [];
  if (columnDef !== undefined && !columnIsDerived(columnDef) && isValue(value)) {
    const propertyType = columnDef.property_type as PropertyKnowledgeRef;
    // Can we construct a ConceptAddress from this?
    const conceptType = pathConceptType(columnDef.path);
    if (uniquelyIdentifiesConcept(conceptType, propertyType)) {
      items.push({
        key: "concept-page",
        label: `View ${typeLabel(conceptType)}`,
        action: () =>
          exploreStore.showConceptPage({
            conceptType,
            keys: { [propertyType]: value as GraphValue },
          }),
      });
    }
    // Add filter(s)
    const colName = propertyName(columnDef.property_type);
    const baseFilter = {
      property_type: propertyType,
      path: columnDef.path,
    };
    let filter: QueryFilter | undefined;
    const validFilterTypes = FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE[(value as GraphValue)._type];
    if (validFilterTypes.includes(FilterType.Range)) {
      filter = filterWithDefaults({
        ...baseFilter,
        type: FilterType.Range,
        values: [
          {
            lte: value,
            gte: value,
          },
        ],
      });
    } else if (validFilterTypes.includes(FilterType.Equality)) {
      filter = filterWithDefaults({
        ...baseFilter,
        type: FilterType.Equality,
        values: [{ value }],
      });
    }
    if (filter !== undefined) {
      items.push({
        key: "filter",
        label: `Filter: Show only this ${colName}`,
        action: () => exploreStore.addFilter(filter),
      });
      items.push({
        key: "filter-negated",
        label: `Filter: Don't show this ${colName}`,
        action: () => exploreStore.addFilter({ ...filter, negated: true }),
      });
      if (filter.type === FilterType.Range) {
        items.push({
          key: "filter-lte",
          label: `Filter: Show this ${colName} or higher`,
          action: () =>
            exploreStore.addFilter({ ...filter, values: [omit(filter.values[0], "lte")] }),
        });
        items.push({
          key: "filter-gte",
          label: `Filter: Show this ${colName} or lower`,
          action: () =>
            exploreStore.addFilter({ ...filter, values: [omit(filter.values[0], "gte")] }),
        });
      }
      // Add pivots
      const pivotItems: MenuItem[] = [];
      for (const pivot of availablePivots(columnDef)) {
        const concept: QueryPathNode = columnDef.path
          ? last(columnDef.path)!
          : { concept_type: exploreStore.query!.root_concept_type };
        const conceptAndPropName = propertyName(columnDef.property_type, [concept]);
        pivotItems.push({
          key: `pivot-${pivot.to}`,
          label: `${pluralize(typeLabel(pivot.to))} with this ${conceptAndPropName}`,
          action: () => exploreStore.pivot(pivot.to, { ...filter, path: pivot.path }),
        });
      }
      sortBy(pivotItems, (p) => p.label);
      if (pivotItems.length <= 1) {
        items.push(...pivotItems);
      } else {
        items.push({
          key: "pivot-sub",
          label: "Pivot to",
          submenu: pivotItems,
        });
      }
    }
    items.push({
      key: "copy",
      label: "Copy value",
      action: () => navigator.clipboard.writeText(stringifyValue(formattedValue)),
    });
  }
  return items;
}

function addableFilterType(validFilterTypes: FilterType[]) {
  if (validFilterTypes.includes(FilterType.Range)) {
    return FilterType.Range;
  } else if (validFilterTypes.includes(FilterType.Text)) {
    return FilterType.Text;
  } else if (validFilterTypes.includes(FilterType.Equality)) {
    return FilterType.Equality;
  }
  return undefined;
}

function removeAllColumns(treePath: ExploreTreePath) {
  const exploreStore = useExploreStore();
  const queryPath = expandTreePath(treePath);
  const cols = exploreStore.query!.columns.filter((column) => isEqual(column.path, queryPath));
  cols.forEach((col) => {
    exploreStore.removeColumn(col.alias);
  });
}
