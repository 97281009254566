import { defineComponent as _defineComponent } from 'vue'
import Dialog from "@/common/components/Dialog.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import Select from "./Select.vue";
import { environment } from "../environments/environmentLoader";

interface LLMOption {
  key: string;
  label: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserPreferences',
  setup(__props, { expose: __expose }) {
  __expose();

const editingHomePage = ref<string | undefined>();
const editingDefaultLLM = ref<string | undefined>();

const userStore = useUserStore();
const { user, userSettings } = storeToRefs(userStore);

const LLM_OPTIONS: { [key: string]: string } = environment.requireJson("LLM_PROVIDER_MAP");
const llmMenuItems = computed<LLMOption[]>(() => {
  return Object.entries(LLM_OPTIONS).map(([key, label]) => ({
    key,
    label,
  }));
});

const currentDefaultLLMLabel = computed(() => {
  const currentId = userSettings?.value?.default_llm;
  return currentId ? LLM_OPTIONS[currentId] : undefined;
});

function editHomePage() {
  editingHomePage.value = userSettings?.value?.default_homepage ?? "";
}

function editDefaultLLM() {
  editingDefaultLLM.value =
    userSettings?.value?.default_llm ?? environment.require("LLM_PROVIDER_DEFAULT");
}

async function finishEditHomePage() {
  userSettings.value ??= {};
  userSettings.value.default_homepage =
    editingHomePage.value?.trim() === "" ? undefined : editingHomePage.value;
  const success = await userStore.updateUserSettings(userSettings.value);
  if (!success) {
    return;
  }
  editingHomePage.value = undefined;
}

async function finishEditDefaultLLM() {
  userSettings.value ??= {};
  userSettings.value.default_llm =
    editingDefaultLLM.value?.trim() === "" ? undefined : editingDefaultLLM.value;
  const success = await userStore.updateUserSettings(userSettings.value);
  if (!success) {
    return;
  }
  editingDefaultLLM.value = undefined;
}

const __returned__ = { editingHomePage, editingDefaultLLM, userStore, user, userSettings, LLM_OPTIONS, llmMenuItems, currentDefaultLLMLabel, editHomePage, editDefaultLLM, finishEditHomePage, finishEditDefaultLLM, Dialog, Textbox, TextButton, Select }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})