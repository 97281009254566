import useGraph from "@/common/composables/useGraph";
import { asyncValue } from "@/common/lib/async";
import { AGGREGATE_OP_TYPES, PropertyOpType, propertyValueType } from "@/common/lib/derived";
import {
  AliasLocations,
  FetchNOrderBy,
  FetchNResponse,
  GROUP_BY_ALL,
  RootAndNeighborRefs,
} from "@/common/lib/fetchApi";
import { formatValue, ValueWithFormattedValue } from "@/common/lib/format";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { getMapClausesWhere, MapSectionKey, propertySorter } from "@/common/lib/map";
import { columnIsDerived, QueryColumn, QueryFilter, QueryPathNode } from "@/common/lib/query";
import { QueryDerivedPropertyType, QueryPropertyTerm } from "@/common/lib/queryProperties";
import { FloatValue, GraphValue, GraphValueType, toNative, toValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { compact, flatten, fromPairs, isEqual, isObject, last } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useAppStore } from "../stores/app";

export interface ExploreColumnStats {
  max: GraphValue;
}

export interface ExploreCell {
  values: ValueWithFormattedValue[];
  isTruncated: boolean;
}

export type ExploreRow = Record<string, ExploreCell>;
export type ExploreTable = ExploreRow[];

export function buildExploreTable(
  response: FetchNResponse,
  aliasLocations: AliasLocations
): ExploreTable {
  const columnDefs = useExploreStore().query!.columns;
  return response.paths.map(function (refs: RootAndNeighborRefs) {
    const rootConcept = response.data[refs.root_id];
    return fromPairs(
      columnDefs.map(function (columnDef) {
        const mapping = aliasLocations[columnDef.alias];
        let concepts;
        if (mapping) {
          const neighborhoods = (refs[mapping.neighborhood] as string[][]) ?? [];
          concepts = neighborhoods.map((neigh) => response.data[neigh[mapping.position]]);
        } else {
          concepts = [rootConcept];
        }
        const props = concepts.flatMap((concept) => concept?.properties[columnDef.alias] ?? []);
        const values = compact(props).map((prop) => formatValue(columnDef.property_type, prop));
        const isTruncated = rootConcept.truncated?.includes(columnDef.alias);
        return [columnDef.alias, { values, isTruncated }];
      })
    );
  });
}

export function rootColumns(): QueryColumn[] {
  const exploreStore = useExploreStore();
  const { getConceptsOfType } = useGraph(() => exploreStore.metagraph);
  const appState = useAppStore();
  const rootConceptType = exploreStore.query!.root_concept_type;
  const rootMetaconcept = getConceptsOfType(rootConceptType)[0];
  const props = propertySorter(
    appState.map,
    rootConceptType,
    rootMetaconcept.properties ?? [],
    (p) => p.type
  );
  return props.map((metaprop) => ({
    alias: metaprop.id,
    property_type: metaprop.type,
  }));
}

export function buildSimpleColumn(
  conceptPath: QueryPathNode[] | undefined,
  propertyType: PropertyKnowledgeRef,
  op?: PropertyOpType
): QueryColumn {
  let ourPropType: QueryPropertyTerm;
  if (op) {
    ourPropType = {
      op,
      term: propertyType,
    } as QueryPropertyTerm;
  } else {
    ourPropType = propertyType;
  }
  return {
    alias: uuidv4(),
    property_type: ourPropType,
    path: conceptPath,
  };
}

export function buildCountColumn(path: QueryPathNode[] | undefined): QueryColumn {
  return {
    alias: uuidv4(),
    property_type: { op: PropertyOpType.Count, approx: false },
    path,
  };
}

export function initialColumnSet(
  group_by: QueryColumn[] | typeof GROUP_BY_ALL = []
): QueryColumn[] {
  if (group_by === GROUP_BY_ALL) {
    return [buildCountColumn(undefined)];
  } else if (group_by.length > 0) {
    return [...group_by, buildCountColumn(undefined)];
  } else {
    return rootColumns(); // for now!
  }
}

export function initialOrderBy() {
  const exploreQuery = useExploreStore().query!;
  const ordering: FetchNOrderBy[] = [];
  if (exploreQuery.group_by === GROUP_BY_ALL || exploreQuery.group_by.length > 0) {
    const countCol = exploreQuery.columns.find(
      (c) => isObject(c.property_type) && c.property_type.op === PropertyOpType.Count
    );
    if (countCol != null) ordering.push({ on: countCol.alias, asc: false });
  }
  return ordering;
}

export function availablePivots(column: QueryColumn) {
  const exploreStore = useExploreStore();
  const exploreQuery = exploreStore.query!;
  const metagraph = useGraph(() => exploreStore.metagraph).metagraphWithoutRecords();
  const { getMetaPath } = useGraph(() => metagraph);
  const pivotFrom = pathConceptType(column.path); // What concept is our filter property on?
  // Generate paths to all the other concepts
  // Ignore ones that don't have properties. Is that a good heuristic? Who knows,
  // but it looks good right now as it hides empty roles
  const candidates = metagraph.concepts
    .filter((mc) => mc.type !== exploreQuery.root_concept_type && (mc.properties ?? []).length > 0)
    .map((mc) => mc.type);
  return compact(
    candidates.map(function (pivotTo) {
      const path = getMetaPath(pivotTo, pivotFrom);
      if (path == null) return null;
      return { to: pivotTo, path: path.length === 0 ? undefined : path };
    })
  );
}

export function pathConceptType(path?: QueryPathNode[]) {
  if (path == null) return useExploreStore().query!.root_concept_type;
  return last(path)!.concept_type;
}

export function calculateColumnStats() {
  const ALLOWED_VALUE_TYPES = [GraphValueType.Integer, GraphValueType.Float];
  const exploreStore = useExploreStore();
  const columns: Record<string, ExploreColumnStats> = {};
  const table = asyncValue(exploreStore.table)!;
  for (const column of exploreStore.query!.columns) {
    if (columnIsDerived(column)) {
      const term = column.property_type as QueryDerivedPropertyType;
      if (
        AGGREGATE_OP_TYPES.includes(term.op) &&
        ALLOWED_VALUE_TYPES.includes(propertyValueType(term) as GraphValueType)
      ) {
        const values = flatten(table.map((row) => row[column.alias].values)).map((v) =>
          toNative(v.originalValue as FloatValue)
        );
        columns[column.alias] = { max: toValue(Math.max(...values)) };
      }
    }
  }
  return columns;
}

export function findCurrentColumn(column: Pick<QueryColumn, "property_type" | "path">) {
  const exploreStore = useExploreStore();
  const col = exploreStore.query!.columns.find(
    (candidate) =>
      isEqual(candidate.property_type, column.property_type) && isEqual(candidate.path, column.path)
  );
  return col?.alias;
}

// These are quick-selectable from a menu as they require no config
export const SIMPLE_COLUMN_OPS = [
  PropertyOpType.Sum,
  PropertyOpType.Min,
  PropertyOpType.Max,
  PropertyOpType.Avg,
  PropertyOpType.Median,
  PropertyOpType.Count,
];

// Which sets of properties can be used as ConceptAddress keys for a concept type?
export function keySetsForConcept(conceptType: ConceptKnowledgeRef) {
  const exploreStore = useExploreStore();
  const resolutions = getMapClausesWhere(
    exploreStore.map!,
    MapSectionKey.Resolutions,
    (c) => c.type === conceptType
  );
  return Object.values(resolutions).map((r) => r.on);
}

export function filterWithDefaults(
  filter: Partial<QueryFilter> & Pick<QueryFilter, "type" | "property_type">
): QueryFilter {
  return {
    alias: uuidv4(),
    values: [],
    negated: false,
    ...filter,
  };
}
