import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative mb-15" }
const _hoisted_2 = { class: "mb-15" }
const _hoisted_3 = { class: "relative mb-20" }
const _hoisted_4 = { class: "flex items-center justify-between rounded-full bg-gray-200 pl-15 pr-5 dark:bg-gray-900" }
const _hoisted_5 = { class: "relative mb-20" }
const _hoisted_6 = { class: "mb-10 flex items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createVNode($setup["IconButtonV2"], {
        name: "md:hub",
        color: "white",
        class: "absolute right-0 top-[-7px]",
        onClick: $setup.toggleMetagraph,
        size: "l"
      }),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mb-10 text-lg font-medium text-gray-500" }, "Perspective", -1 /* HOISTED */)),
      _createVNode($setup["ConceptSelector"], {
        "concept-ids": $setup.conceptTypes,
        "model-value": $setup.exploreStore.query?.root_concept_type,
        "include-adhoc": true,
        "onUpdate:modelValue": $setup.exploreStore.setRootConceptType,
        placeholder: "Choose concept type",
        "data-test": "concept-select",
        "icon-color": "gray2"
      }, null, 8 /* PROPS */, ["concept-ids", "model-value", "onUpdate:modelValue"])
    ]),
    ($setup.exploreStore.query != null)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("section", _hoisted_2, [
            _createVNode($setup["ListBuilder"], {
              title: "Sort by",
              "model-value": $setup.exploreStore.query.order_by,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = (values) => $setup.exploreStore.setOrderBy($setup.compact(values)))
            }, {
              default: _withCtx(({ item, updateItem }) => [
                _createVNode($setup["OrderBy"], {
                  "model-value": item,
                  "onUpdate:modelValue": updateItem
                }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["model-value"])
          ]),
          _createElementVNode("section", _hoisted_3, [
            ($setup.exploreStore.query!.group_by === $setup.GROUP_BY_ALL)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mb-10 text-lg font-medium text-gray-500" }, "Group By", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_4, [
                    _cache[5] || (_cache[5] = _createTextVNode(" Summarize All ")),
                    _createVNode($setup["IconButtonV2"], {
                      name: "delete",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.exploreStore.setGroupBy([])))
                    })
                  ])
                ], 64 /* STABLE_FRAGMENT */))
              : (_openBlock(), _createBlock($setup["ListBuilder"], {
                  key: 1,
                  title: "Group by",
                  "data-test": "add-group-by",
                  "model-value": $setup.exploreStore.groupByColumns,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = (values) => $setup.exploreStore.setGroupBy($setup.compact(values)))
                }, {
                  default: _withCtx(({ item, updateItem }) => [
                    _createVNode($setup["GroupBy"], {
                      "model-value": item,
                      "onUpdate:modelValue": updateItem,
                      "data-test": "group-by"
                    }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["model-value"]))
          ]),
          _createElementVNode("section", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-lg font-medium text-gray-500" }, "Included data", -1 /* HOISTED */)),
              _createVNode($setup["Toggle"], {
                modelValue: $setup.exploreStore.hideUnusedProperties,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.exploreStore.hideUnusedProperties) = $event)),
                label: "Hide Unused"
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _createVNode($setup["Metatree"])
          ])
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}