import { defineComponent as _defineComponent } from 'vue'
import {
  generateValueSets,
  generatorAlias,
  generatorName,
  ValueGenerator,
  ValueGeneratorType,
  visualizationTheme,
} from "@/reader/lib/visualization";
import { ComputedRef, Ref, computed, inject, toRefs } from "vue";
import * as vega from "vega";
import Chart from "@/common/components/Chart.vue";
import { DarkMode } from "@/common/lib/keys";
import { FloatValue, GraphValue, stringifyValue, toNative } from "@/common/lib/value";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { GraphCompoundValue, stringifyValueOrCompositeValue } from "@/common/lib/graph";
import { groupBy, isString } from "lodash";
import { DiscreteDistributionVisualization } from "@/reader/lib/visualizationTypes";

interface Datum {
  index: number;
  category: GraphValue | GraphCompoundValue | null;
  categoryId: string;
  categoryName: [string, string];
  subcategory: GraphValue | GraphCompoundValue | null;
  subcategoryId: string;
  value: number;
  tooltip: Record<string, string>;
  labelTooltip: Record<string, string | number> | string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DiscreteDistribution',
  props: {
    visualization: { type: Object, required: true },
    results: { type: Array, required: true },
    width: { type: Number, required: false }
  },
  emits: ["select"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const { visualization, results, width } = toRefs(props);

const emit = __emit;

const darkMode = inject(DarkMode) as Ref<boolean>;

const data = computed(function () {
  const viz = visualization.value;
  const categoryLabel = generatorName(viz.config.category_name ?? viz.config.category, viz.query);
  const subcatLabel = viz.config.subcategory && generatorName(viz.config.subcategory, viz.query);
  const valueLabel = generatorName(viz.config.value, viz.query);
  const valueSets = generateValueSets(viz.config, results.value, viz.query, ["category", "value"]);

  let categorySums: Record<string, number>;
  if (viz.config.subcategory) {
    const categoryTotals = groupBy(valueSets, (valueSet) => {
      return valueSet.category?.originalValue?.value ?? "Unknown";
    });
    categorySums = Object.keys(categoryTotals).reduce(
      (totals, categoryId) => {
        const sum = categoryTotals[categoryId].reduce(
          (acc, values) => acc + toNative(values.value!.originalValue as FloatValue),
          0
        );
        totals[categoryId] = sum;
        return totals;
      },
      {} as Record<string, number>
    );
  }

  return valueSets.map(function (values, index): Datum {
    function getLabelTooltip(): Record<string, string | number> | string {
      if (values.subcategory != null) {
        return {
          Name: categoryName,
          Total: categorySums[categoryId] || 0,
        };
      } else {
        return categoryName;
      }
    }
    const categoryId = stringifyValueOrCompositeValue(values.category?.originalValue);
    const categoryName = stringifyValue((values.category_name ?? values.category)?.formattedValue);
    const subcategoryId = stringifyValueOrCompositeValue(values.subcategory?.originalValue);
    const tooltip: Record<string, string> = { [categoryLabel]: categoryName };
    if (values.subcategory != null) {
      tooltip[subcatLabel!] = subcategoryId;
    }
    tooltip[valueLabel] = stringifyValue(values.value!.formattedValue);
    return {
      index,
      category: values.category?.originalValue ?? null,
      categoryId,
      categoryName: [categoryName, categoryId],
      subcategory: values.subcategory?.originalValue ?? null,
      subcategoryId,
      value: toNative(values.value!.originalValue as FloatValue),
      tooltip,
      labelTooltip: getLabelTooltip(),
    };
    // Why does categoryName include the id as well? Because both the domain and
    // range of the scale mapping categoryId to categoryName must be unique, so
    // without this, duplicate categoryNames cause brokenness
  });
});

const height = computed(
  () => 30 + Object.keys(groupBy(data.value, (d) => d.categoryId)).length * 25
);

function tickTransformer() {
  const generator = visualization.value.config.value;
  if (!isString(generator) && generator.type === ValueGeneratorType.Property) {
    return generator.transformer;
  }
  return undefined;
}

const spec: ComputedRef<vega.Spec> = computed(function () {
  const theme = visualizationTheme(darkMode.value);
  const isStacked = visualization.value.config.subcategory != null;
  const isMulticolor = visualization.value.config.multicolor;
  let rectAttrs, rectHoverAttrs;
  if (isStacked || isMulticolor) {
    rectAttrs = {
      fill: isStacked
        ? { scale: "color", field: "subcategoryId" }
        : { scale: "color_unstacked", field: "categoryId" },
    };
    rectHoverAttrs = { opacity: { value: 1.0 } };
  } else {
    rectAttrs = { fill: { value: theme.datum } };
    rectHoverAttrs = { fill: { value: theme.selectedDatum } };
  }
  const spec: vega.Spec = {
    width: (width.value ?? 370) - 10,
    height: height.value,
    padding: 5,
    autosize: "fit",
    data: [
      {
        name: "table",
        values: data.value,
        transform: [
          {
            type: "stack",
            groupby: ["categoryId"],
            field: "value",
            as: ["x0", "x1"],
          },
        ],
      },
    ],
    signals: [
      {
        name: "selection",
        value: null,
        on: [{ events: "@bar:click", update: "datum.category" }],
      },
    ],
    scales: [
      {
        name: "x",
        type: "linear",
        domain: { data: "table", field: "x1" },
        range: "width",
        nice: true,
      },
      {
        name: "y",
        type: "band",
        domain: { data: "table", field: "categoryId" },
        range: "height",
        padding: 0.2,
      },
      {
        name: "y_names",
        type: "ordinal",
        domain: { data: "table", field: "categoryId" },
        range: { data: "table", field: "categoryName" },
      },
      {
        name: "color",
        type: "ordinal",
        range: { scheme: "category10" },
        domain: { data: "table", field: "subcategoryId" },
      },
      {
        name: "color_unstacked",
        type: "ordinal",
        range: { scheme: "category10" },
        domain: { data: "table", field: "categoryId" },
      },
    ],
    axes: [
      {
        orient: "bottom",
        format: "s",
        scale: "x",
        tickCount: 5,
        labelColor: theme.label,
        labelFontSize: 12,
        encode: {
          labels: tickTransformer()
            ? {
                update: {
                  text: { signal: `transform(datum.value, "${tickTransformer()}")` },
                },
              }
            : undefined,
        },
      },
      {
        scale: "y",
        orient: "left",
        labelColor: theme.label,
        labelLimit: 120,
        domain: false,
        ticks: false,
        labelPadding: 5,
        encode: {
          labels: {
            update: {
              text: { signal: "scale('y_names', datum.value)[0]" },
            },
          },
        },
      },
    ],
    marks: [
      {
        name: "bar",
        type: "rect",
        from: { data: "table" },
        encode: {
          update: {
            ...rectAttrs,
            x: { scale: "x", field: "x0" },
            x2: { scale: "x", field: "x1" },
            y: { scale: "y", field: "categoryId" },
            height: { scale: "y", band: 1 },
            tooltip: { signal: "datum.tooltip" },
          },
          hover: {
            ...rectHoverAttrs,
            cursor: { value: "pointer" },
          },
        },
      },
      {
        type: "rect",
        from: { data: "table" },
        encode: {
          enter: {
            x: {
              value: -50,
            },
            x2: { value: 0 },
            y: { scale: "y", field: "categoryId" },
            height: { scale: "y", band: 1 },
            fill: { value: "transparent" },
            tooltip: { signal: "datum.labelTooltip" },
          },
          update: {
            fillOpacity: { value: 0 },
          },
          hover: {
            fillOpacity: { value: 0.1 },
          },
        },
      },
    ],
    legends: isStacked
      ? [
          {
            fill: "color",
            encode: {
              symbols: {
                enter: {
                  strokeWidth: { value: 2 },
                  size: { value: 200 },
                },
              },
              labels: {
                update: {
                  fontSize: { value: 12 },
                },
              },
            },
          },
        ]
      : [],
  };
  return spec;
});

function handleSelect(category: unknown) {
  const alias = generatorAlias(visualization.value.config.category);
  if (alias) emit("select", alias, category as GraphValue | null);
}

const __returned__ = { props, visualization, results, width, emit, darkMode, data, height, tickTransformer, spec, handleSelect, Chart }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})