import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full bg-gray-200 dark:bg-gray-800" }
const _hoisted_2 = { class: "user-details" }
const _hoisted_3 = { class: "flex bg-gray-100 dark:bg-gray-900" }
const _hoisted_4 = { class: "m-30 flex flex-col gap-30 text-gray-800 dark:text-gray-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["TabSelect"], {
          tabs: $setup.availableTabs,
          modelValue: $setup.selectedTab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedTab) = $event))
        }, null, 8 /* PROPS */, ["tabs", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        ($setup.selectedTab === 'user-settings')
          ? (_openBlock(), _createBlock($setup["UserSettings"], { key: 0 }))
          : _createCommentVNode("v-if", true),
        ($setup.selectedTab === $setup.SelectedTab.Connections)
          ? (_openBlock(), _createBlock($setup["ConnectionSettings"], { key: 1 }))
          : _createCommentVNode("v-if", true),
        ($setup.selectedTab === $setup.SelectedTab.Preferences)
          ? (_openBlock(), _createBlock($setup["UserPreferences"], { key: 2 }))
          : _createCommentVNode("v-if", true),
        ($setup.selectedTab === $setup.SelectedTab.Admin)
          ? (_openBlock(), _createBlock($setup["AdminPage"], { key: 3 }))
          : _createCommentVNode("v-if", true),
        ($setup.selectedTab === $setup.SelectedTab.QuickBooks)
          ? (_openBlock(), _createBlock($setup["QuickBooksTab"], { key: 4 }))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}