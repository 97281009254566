<template>
  <template v-for="(row, rowIndex) in rows" :key="rowIndex">
    <tr
      :class="{
        'odd:bg-gray-100': row.contents == null && !row.highlight,
        'bg-finance-row_even odd:bg-finance-row_odd': row.highlight,
      }"
      class="cursor-pointer hover:bg-finance-highlight"
      @click="row.contents != null && expandMap(row.label)"
    >
      <td class="sticky">
        <div class="flex flex-row items-center text-nowrap font-bold" :style="indentStyle">
          <Icon
            v-if="row.contents != null"
            :name="
              expandedMap.has(row.label) ? 'md:keyboard_arrow_down' : 'md:keyboard_arrow_right'
            "
            color="medium-gray"
            size="xl"
          />
          <div v-else class="w-[25px]"></div>
          {{ row.label }}
        </div>
      </td>
      <template v-for="(cell, colIndex) in cells(row)" :key="colIndex">
        <template v-if="!skipMonths || colIndex >= skipMonths">
          <!-- Value -->
          <td class="p-10 align-baseline">
            <div :style="colIndex == 0 ? indentStyle : undefined">
              <div class="select-text text-nowrap text-right" v-if="cell.value != null">
                <GraphValueComponent :value="cell.value.formattedValue" />
              </div>
              <div class="text-right opacity-50" v-else>-</div>
            </div>
          </td>
          <!-- Over comparison value -->
          <td v-if="colIndex > 0" class="p-10 align-baseline">
            <div
              class="select-text text-nowrap text-right opacity-75"
              v-if="
                cell.overComparisonValue != null &&
                cell.overComparisonValue.formattedValue.value !== '100%'
              "
            >
              <GraphValueComponent :value="cell.overComparisonValue?.formattedValue" />
            </div>
            <div class="text-right opacity-50" v-else>-</div>
          </td>
          <!-- Over previous period -->
          <td v-if="colIndex > 0" class="p-10 align-baseline">
            <div
              class="select-text text-nowrap text-right opacity-75"
              :class="{
                'text-red-500': isAsset(cell) < 0,
                'text-green-500': isAsset(cell) > 0,
              }"
              v-if="cell.overPreviousPeriod != null"
            >
              <GraphValueComponent :value="cell.overPreviousPeriod?.formattedValue" />
            </div>
            <div class="text-right opacity-50" v-else>-</div>
          </td>
        </template>
      </template>

      <template v-for="(colName, colIndex) in trailingColumns" :key="colName">
        <td
          class="p-10 align-baseline"
          :class="{ 'border-l-[8px] border-solid border-l-finance-highlight': colIndex === 0 }"
        >
          <div :style="colIndex == 0 ? indentStyle : undefined">
            <div
              class="bg-red select-text text-nowrap text-right"
              v-if="row.cells[colIndex + columns.length].value?.formattedValue"
            >
              <GraphValueComponent
                :value="row.cells[colIndex + columns.length].value!.formattedValue"
              />
            </div>
            <div class="text-right opacity-50" v-else>-</div>
          </div>
        </td>
        <!-- Over comparison value -->
        <td class="p-10 align-baseline">
          <div
            class="select-text text-nowrap text-right opacity-75"
            v-if="
              row.cells[colIndex + columns?.length].overComparisonValue != null &&
              row.cells[colIndex + columns?.length].overComparisonValue!.formattedValue.value !==
                '100%'
            "
          >
            <GraphValueComponent
              :value="row.cells[colIndex + columns?.length].overComparisonValue!.formattedValue"
            />
          </div>
          <div class="text-right opacity-50" v-else>-</div>
        </td>
      </template>
      <!-- Variance -->
      <td class="p-10 align-baseline">
        <div
          class="select-text text-nowrap text-right font-bold"
          v-if="row.variance != null"
          :class="{
            'text-red-500': isAssetVariance(row.variance, row.cells[0].asset) < 0,
            'text-green-500': isAssetVariance(row.variance, row.cells[0].asset) > 0,
          }"
        >
          <GraphValueComponent :value="row.variance?.formattedValue" />
        </div>
        <div class="text-right opacity-50" v-else>-</div>
      </td>
    </tr>
    <FinancialSubtable
      v-if="row.contents != null && expandedMap.has(row.label)"
      :rows="row.contents"
      :columns="columns"
      :depth="depth + 1"
      :trailingColumns="trailingColumns"
      :expandedMap="expandedMap"
      @update-expanded="(rowLabel) => emits('update-expanded', rowLabel)"
      :skip-months="skipMonths"
    />
  </template>
</template>

<style lang="scss" scoped>
%column-styles {
  @apply bg-finance-highlight;
}
@for $i from 2 to 64 {
  table:has(tr > *:nth-child(#{$i}):hover) tr > *:nth-child(#{$i}) {
    @extend %column-styles;
  }
}
</style>

<script lang="ts" setup>
import { FinComputedCell, FinComputedRow } from "../FinancialStatement.vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Icon from "@/common/components/Icon.vue";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { isNumeric, isValue, toNative } from "@/common/lib/value";
import { computed, toRefs } from "vue";

const props = defineProps<{
  rows: FinComputedRow[];
  columns: string[];
  trailingColumns: string[];
  depth: number;
  expandedMap: Set<string>;
  skipMonths?: number;
}>();
const { depth, expandedMap } = toRefs(props);

const emits = defineEmits(["update-expanded"]);

function cells(row: FinComputedRow): FinComputedCell[] {
  return props.columns.map((_, index) => row.cells[index]);
}

function isAsset(cell: FinComputedCell): number {
  const originalValue = cell.overPreviousPeriod?.originalValue;
  if (!isValue(originalValue) || !isNumeric(originalValue)) {
    return 0;
  }
  return cell.asset * toNative(originalValue);
}

function isAssetVariance(value: ValueWithFormattedValue | undefined, asset: number): number {
  const originalValue = value?.originalValue;
  if (!isValue(originalValue) || !isNumeric(originalValue)) {
    return 0;
  }
  return asset * toNative(originalValue);
}

function expandMap(rowLabel: string) {
  emits("update-expanded", rowLabel);
}

const indentStyle = computed(() => ({ marginLeft: `${15 * depth.value}px` }));
</script>
