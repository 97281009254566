import { httpClient } from "@/common/http/http";
import {
  Async,
  asyncFailed,
  asyncInProgress,
  asyncNotStarted,
  AsyncStatus,
  asyncSucceeded,
  asyncValue,
} from "@/common/lib/async";
import { AxiosResponse } from "axios";
import { isNil } from "lodash";
import { defineStore } from "pinia";
import { useNavigation } from "../composables/useNavigation";

/*
What operations does this store perform?

- Are we connected to workspace X?
- Get all workspaces
- Get info for workspace X
- Get current workspace
- Load workspace X
- Show warehouses for workspace X
- Get current warehouse
 */

interface State {
  connected?: boolean;
  _initialized: boolean;
  workspaces: Async<Workspace[]>;
  currentWorkspace: Async<Workspace | undefined>;
  warehouses: Async<Warehouse[]>;
  currentWarehouse?: Warehouse;
}

interface WorkspaceStatus {
  connected: boolean;
  warehouse?: Warehouse;
}

export interface Workspace {
  id: string;
  nickname: string;
  connected: boolean;
  current_warehouse_id?: string;
  provider: ProviderType;
}

export interface FullWorkspace {
  metadata: Workspace;
  credentials: unknown;
}

export enum ProviderType {
  Databricks = "dbx",
  Snowflake = "snowflake",
  MySQL = "mysql",
  StarRocks = "starrocks",
}

export interface Warehouse {
  id: string;
  name: string;
  cluster_size: string;
  state: "DELETED" | "DELETING" | "RUNNING" | "STARTING" | "STOPPED" | "STOPPING";
}

interface ListWarehousesResponse {
  warehouses: Warehouse[];
}

export enum WorkspacesState {
  // Current workspace is loading
  Loaded = "LOADED",
  // There are workspaces, but no current workspace is selected
  NoCurrent = "NO_CURRENT",
  // Currently loading workspaces state
  Loading = "LOADING",
  // There are no workspaces configured
  NoWorkspaces = "NO_WORKSPACES",
  // Error loading workspaces
  Error = "ERROR",
}

export const useWorkspaceStore = defineStore("workspace", {
  state: (): State => ({
    _initialized: false,
    currentWorkspace: asyncNotStarted(),
    workspaces: asyncNotStarted(),
    warehouses: asyncNotStarted(),
    currentWarehouse: undefined,
  }),
  getters: {
    /**
     * Get the current workspace (unwrap the Async) if available.
     * In general, prefer #currentWorkspace to this call because it differentiates between loading and no value.
     * @returns current workspace, or undefined if no current workspace OR current workspace is not yet loaded.
     */
    currentWorkspaceSync(): Workspace | undefined {
      return asyncValue(this.currentWorkspace) ?? undefined;
    },
    currentWorkspaceId(): string | undefined {
      return asyncValue(this.currentWorkspace)?.id;
    },
    /**
     * Get the overall workspaces status
     * @returns status of workspaces
     */
    workspacesState(): WorkspacesState {
      const workspaces = asyncValue(this.workspaces);
      if (isNil(workspaces)) {
        return WorkspacesState.Loading;
      }
      if (this.currentWorkspace.status === AsyncStatus.Failed) {
        return WorkspacesState.Error;
      }
      if (this.currentWorkspace.status !== AsyncStatus.Succeeded) {
        return WorkspacesState.Loading;
      }
      const current = this.currentWorkspace.result;
      if (!isNil(current)) {
        return WorkspacesState.Loaded;
      }
      if (workspaces.length === 0) {
        return WorkspacesState.NoWorkspaces;
      }
      return WorkspacesState.NoCurrent;
    },
    canOpenCurrentWorkspace(): boolean {
      return (
        this.currentWorkspaceSync?.provider === ProviderType.Databricks &&
        !isNil(this.currentWorkspaceSync?.id)
      );
    },
  },
  actions: {
    async initializeWorkspaceState() {
      if (!this._initialized) {
        this._initialized = true;
        await Promise.all([this.refreshWorkspaces(), this.reloadCurrentWorkspaceState()]);
      }
    },
    async reloadWorkspaceState(workspaceId: string) {
      this.clearWorkspaceState();

      // Load all info
      await this.loadWorkspaceState(workspaceId);
    },
    async reloadCurrentWorkspaceState() {
      const workspaceId = await this.loadCurrentWorkspaceId();
      if (isNil(workspaceId)) {
        this.currentWorkspace = asyncSucceeded(undefined);
        return;
      }
      await this.reloadWorkspaceState(workspaceId);
    },
    async loadWorkspaceState(workspaceId: string) {
      // Load all info
      await Promise.all([
        this.refreshWarehouse(workspaceId),
        this.refreshWarehouses(workspaceId),
        this.refreshWorkspace(workspaceId),
      ]);
      this._initialized = true;
    },
    async setCurrentWorkspace(workspaceId: string) {
      this.clearWorkspaceState();

      await this.setCurrentWorkspaceId(workspaceId);

      await this.loadWorkspaceState(workspaceId);
    },
    async refreshWorkspace(workspaceId: string) {
      try {
        const fullWorkspace = await this.loadFullWorkspace(workspaceId);
        this.currentWorkspace = asyncSucceeded(fullWorkspace.metadata);
        return this.currentWorkspaceId;
      } catch (error: unknown) {
        this.currentWorkspace = asyncFailed("Refreshing workspace failed");
      }
    },
    async loadCurrentWorkspaceId(): Promise<string | undefined> {
      const response = await httpClient.get("/api/workspaces/current");
      const workspaceId: string | undefined = response.data.workspace_id;
      return workspaceId;
    },
    async refreshWorkspaces() {
      this.workspaces = asyncInProgress();
      try {
        const response = await httpClient.get("/api/workspaces");
        this.workspaces = asyncSucceeded(response.data.workspaces ?? []);
      } catch (error: unknown) {
        this.workspaces = asyncFailed("Could not load workspaces");
      }
    },
    async saveWorkspace(workspace: FullWorkspace) {
      await httpClient.post("/api/workspaces", workspace);
      this.refreshWorkspaces();
      await this.reloadCurrentWorkspaceState();
    },
    async getWorkspaceStatus(workspaceId: string): Promise<WorkspaceStatus> {
      const response: AxiosResponse<WorkspaceStatus> = await httpClient.get(
        `/api/workspaces/${workspaceId}/status`
      );
      return response.data;
    },
    async loadFullWorkspace(workspaceId: string): Promise<FullWorkspace> {
      const response = await httpClient.get(`/api/workspaces/${workspaceId}`);
      return response.data.workspace;
    },
    async deleteCurrentWorkspace() {
      const host = this.currentWorkspaceId;
      if (host === undefined) {
        return;
      }
      await httpClient.delete(`/api/workspaces/${host}`);
      this.refreshWorkspaces();
      await this.reloadCurrentWorkspaceState();
    },
    clearWorkspaceState() {
      this.currentWorkspace = asyncInProgress();
      this.currentWarehouse = undefined;
      this.warehouses = asyncInProgress();
    },
    async setCurrentWorkspaceId(workspaceId: string) {
      if (!workspaceId) {
        return;
      }
      await httpClient.post(`/api/workspaces/current/${workspaceId}`);
    },
    async refreshWarehouse(workspaceId: string) {
      const status = await this.getWorkspaceStatus(workspaceId);
      this.currentWarehouse = status.warehouse;
    },
    async refreshWarehouses(workspaceId: string) {
      this.warehouses = asyncInProgress();
      try {
        const response = await httpClient.get(`/api/workspaces/${workspaceId}/warehouses`);
        const config: ListWarehousesResponse = response.data;
        this.warehouses = asyncSucceeded(config.warehouses);
      } catch (error: unknown) {
        this.warehouses = asyncFailed("Could not load warehouses");
      }
    },
    async refreshCurrentWarehouse() {
      if (isNil(this.currentWorkspaceId)) {
        return;
      }
      await this.refreshWarehouse(this.currentWorkspaceId);
    },
    async setCurrentWarehouse(workspaceId: string, warehouseId: string) {
      this.currentWarehouse = undefined;
      await httpClient.post(`/api/workspaces/${workspaceId}/warehouses/current`, {
        warehouse_id: warehouseId,
      });
      await this.refreshWarehouse(workspaceId);
    },
    openCurrentWorkspace() {
      if (this.currentWorkspaceSync?.provider !== ProviderType.Databricks) {
        return;
      }
      const workspaceId = this.currentWorkspaceSync?.id;
      if (isNil(workspaceId)) {
        return;
      }
      useNavigation().openUrl(`https://${workspaceId}/explore`);
    },
  },
});
