import { defineComponent as _defineComponent } from 'vue'
import { stringifyValue, toNative, toTypedValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { isNumber, isString } from "lodash";
import { computed, nextTick, onMounted, Ref, ref, toRefs } from "vue";
import { GraphValueType } from "@/common/lib/value";
import { propertyName, propertyValueType } from "@/common/lib/derived";
import { QueryFilter } from "@/common/lib/query";
import { RangeFilter } from "@/common/lib/fetchApi";
import { Dropdown } from "floating-vue";
import FilterEditor from "../FilterEditor.vue";
import Textbox from "@/common/components/Textbox.vue";
import { DateTime } from "luxon";
import SelectStrip from "@/editor/components/SelectStrip.vue";
import { UserAction } from "@/common/lib/userAction";
import { columnName } from "@/common/lib/query";


export default /*@__PURE__*/_defineComponent({
  __name: 'RangeFilter',
  props: {
    filter: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { filter } = toRefs(props);

const exploreStore = useExploreStore();

const lowerBoundUnderEdit: Ref<number | string | "" | undefined> = ref(undefined);
const upperBoundUnderEdit: Ref<number | string | "" | undefined> = ref(undefined);
const lowerBoundInputEl: Ref<HTMLInputElement | null> = ref(null);
const isEditing: Ref<boolean> = ref(false);
const specifyTimeOfDay: Ref<boolean> = ref(false);

const valueType = computed(function () {
  if (filter.value.property_type != null) {
    return propertyValueType(filter.value.property_type) as GraphValueType;
  } else {
    return propertyValueType(
      exploreStore.columnByAlias(filter.value.on!)!.property_type
    ) as GraphValueType;
  }
});

function subjectName() {
  if (filter.value.property_type != null) {
    return propertyName(filter.value.property_type);
  } else {
    return columnName(exploreStore.columnByAlias(filter.value.on!)!);
  }
}

const lowerBound = computed(() => filter.value.values[0]?.gte ?? filter.value.values[0]?.gt);
const upperBound = computed(() => filter.value.values[0]?.lte ?? filter.value.values[0]?.lt);
const isExact = computed(() => lowerBound.value?.value === upperBound.value?.value);

const opDescription = computed(() => (filter.value.negated ? "is not" : "is"));
const formLabel = computed(() => `${subjectName()} ${opDescription.value} between`);

const inputType = computed(() => {
  switch (valueType.value) {
    case GraphValueType.Date:
      return "date";
    case GraphValueType.Datetime:
      if (specifyTimeOfDay.value) {
        return "datetime-local";
      } else {
        return "date";
      }
    default:
      return "number";
  }
});

function isDate() {
  return [GraphValueType.Date, GraphValueType.Datetime].includes(valueType.value);
}

function dateToInputString(dt: DateTime) {
  return specifyTimeOfDay.value
    ? dt.toISO({ includeOffset: false, suppressMilliseconds: true })!
    : dt.toISODate()!;
}

function startEditing() {
  if (isDate()) {
    specifyTimeOfDay.value =
      valueType.value === GraphValueType.Datetime &&
      (lowerBound.value?._type === GraphValueType.Datetime ||
        upperBound.value?._type === GraphValueType.Datetime);
    lowerBoundUnderEdit.value =
      lowerBound.value == null ? "" : dateToInputString(toNative(lowerBound.value) as DateTime);
    upperBoundUnderEdit.value =
      upperBound.value == null ? "" : dateToInputString(toNative(upperBound.value) as DateTime);
  } else {
    lowerBoundUnderEdit.value = lowerBound.value ? (toNative(lowerBound.value) as number) : "";
    upperBoundUnderEdit.value = upperBound.value ? (toNative(upperBound.value) as number) : "";
  }
  isEditing.value = true;
}

function setSpecifyTimeOfDay(newValue: boolean) {
  const lower = (lowerBoundUnderEdit.value ?? "") as string;
  const upper = (upperBoundUnderEdit.value ?? "") as string;
  specifyTimeOfDay.value = newValue;
  nextTick(function () {
    // After input types change, convert dates to datetimes or vice versa
    const convert = (ds: string) => dateToInputString(DateTime.fromISO(ds));
    if (lower.length > 0) lowerBoundUnderEdit.value = convert(lower);
    if (upper.length > 0) upperBoundUnderEdit.value = convert(upper);
  });
}

function focusFirstField() {
  setTimeout(() => lowerBoundInputEl.value?.focus(), 50); // Boy do I hate this
}

function commit() {
  const rangeFilter: Partial<RangeFilter> = {};
  if (isNumber(lowerBoundUnderEdit.value)) {
    rangeFilter.gte = toTypedValue(lowerBoundUnderEdit.value, valueType.value);
  }
  if (isNumber(upperBoundUnderEdit.value)) {
    rangeFilter.lte = toTypedValue(upperBoundUnderEdit.value, valueType.value);
  }
  if (isString(lowerBoundUnderEdit.value) && lowerBoundUnderEdit.value !== "") {
    rangeFilter.gte = {
      _type: inputType.value === "date" ? GraphValueType.Date : GraphValueType.Datetime,
      value: lowerBoundUnderEdit.value,
    };
  }
  if (isString(upperBoundUnderEdit.value) && upperBoundUnderEdit.value !== "") {
    rangeFilter.lte = {
      _type: inputType.value === "date" ? GraphValueType.Date : GraphValueType.Datetime,
      value: upperBoundUnderEdit.value,
    };
  }
  filter.value.values = [rangeFilter];
  isEditing.value = false;
  exploreStore.load(UserAction.Update_Range_Filter);
}

const isEditValid = computed(function () {
  // At least one bound must be present
  let lowerIsSet, upperIsSet;
  if (isDate()) {
    lowerIsSet = !!lowerBoundUnderEdit.value;
    upperIsSet = !!upperBoundUnderEdit.value;
  } else {
    lowerIsSet = isNumber(lowerBoundUnderEdit.value);
    upperIsSet = isNumber(upperBoundUnderEdit.value);
  }
  if (!lowerIsSet && !upperIsSet) return false;

  // If both bounds are present, bounds must not be inverted
  if (lowerIsSet && upperIsSet) {
    if (isDate()) {
      const lowerDate = DateTime.fromISO(lowerBoundUnderEdit.value as string);
      const upperDate = DateTime.fromISO(upperBoundUnderEdit.value as string);
      if (lowerDate > upperDate) return false;
    } else {
      if (lowerBoundUnderEdit.value! > upperBoundUnderEdit.value!) return false;
    }
  }

  return true;
});

onMounted(function () {
  if (filter.value.values.length === 0) startEditing();
});

const __returned__ = { props, filter, exploreStore, lowerBoundUnderEdit, upperBoundUnderEdit, lowerBoundInputEl, isEditing, specifyTimeOfDay, valueType, subjectName, lowerBound, upperBound, isExact, opDescription, formLabel, inputType, isDate, dateToInputString, startEditing, setSpecifyTimeOfDay, focusFirstField, commit, isEditValid, get stringifyValue() { return stringifyValue }, get GraphValueType() { return GraphValueType }, get Dropdown() { return Dropdown }, FilterEditor, Textbox, SelectStrip }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})