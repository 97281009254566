import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "border-b border-b-gray-900 bg-gray-800 px-20 py-10"
}
const _hoisted_3 = { class: "flex flex-row justify-center gap-15" }
const _hoisted_4 = { class: "flex flex-row justify-center gap-15" }
const _hoisted_5 = { class: "flex min-h-0 basis-full flex-row" }
const _hoisted_6 = {
  key: 0,
  class: "relative flex-none basis-[325px] overflow-y-auto border-r border-gray-200 bg-gray-100 p-15 dark:border-r-gray-900 dark:bg-gray-800"
}
const _hoisted_7 = { class: "relative isolate flex basis-full flex-col" }
const _hoisted_8 = {
  key: 0,
  class: "absolute inset-0 z-10 bg-gray-200 dark:bg-gray-900"
}
const _hoisted_9 = { class: "absolute inset-0 z-0 flex flex-col" }
const _hoisted_10 = { class: "flex flex-none flex-row justify-between border-b border-b-gray-200 bg-gray-100 dark:border-b-gray-900 dark:bg-gray-800" }
const _hoisted_11 = { class: "flex flex-row" }
const _hoisted_12 = { class: "flex items-center justify-between gap-10 pr-5" }
const _hoisted_13 = {
  key: 1,
  class: "min-w-[120px]"
}
const _hoisted_14 = { class: "relative basis-full bg-white dark:bg-gray-900" }
const _hoisted_15 = {
  key: 1,
  class: "relative flex-none basis-[400px] border-l border-gray-300 dark:border-gray-900"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      ($setup.exploreStore.context === $setup.ExploreContext.Embedded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["AskBar"], { class: "flex basis-6/12" }),
              _createVNode($setup["BookmarkMenu"], {
                onUpdateBookmark: $setup.openUpdateBookmark,
                onDisplayError: $setup.displayError,
                onDeleteBookmark: $setup.deleteBookmark,
                projectId: $setup.projectId
              }, null, 8 /* PROPS */, ["projectId"])
            ])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.exploreStore.context === $setup.ExploreContext.Standalone)
        ? (_openBlock(), _createBlock(_Teleport, {
            key: 1,
            to: "#title-bar-controls"
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode($setup["AskBar"], { class: "flex basis-6/12" }),
              _createVNode($setup["BookmarkMenu"], {
                onUpdateBookmark: $setup.openUpdateBookmark,
                onDisplayError: $setup.displayError,
                onDeleteBookmark: $setup.deleteBookmark,
                projectId: $setup.projectId
              }, null, 8 /* PROPS */, ["projectId"])
            ])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_5, [
        ($setup.exploreStore.showSidebars())
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode($setup["QueryBasics"])
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_7, [
          ($setup.exploreMetagraphStore.visible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode($setup["MetagraphPane"])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode($setup["TabSelect"], {
                  tabs: $setup.tabs,
                  modelValue: $setup.exploreStore.mode,
                  "onUpdate:modelValue": $setup.exploreStore.setMode,
                  disabled: $setup.exploreStore.query == null
                }, null, 8 /* PROPS */, ["tabs", "modelValue", "onUpdate:modelValue", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                ($setup.bookmarksEnabled && $setup.exploreStore.query)
                  ? (_openBlock(), _createBlock($setup["IconButton2"], {
                      key: 0,
                      name: "md:star",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.bookmarkStatus = 'create')),
                      size: "l"
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.exportEnabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      (!$setup.exporting)
                        ? (_openBlock(), _createBlock($setup["TextButton"], {
                            key: 0,
                            label: "Export to Excel",
                            icon: "table_export",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.downloadExcel()))
                          }))
                        : (_openBlock(), _createBlock($setup["SpinnerButton"], {
                            key: 1,
                            label: "Exporting…",
                            icon: true
                          }))
                    ]))
                  : _createCommentVNode("v-if", true),
                ($setup.createTestEnabled)
                  ? (_openBlock(), _createBlock($setup["TextButton"], {
                      key: 2,
                      label: "Create test",
                      icon: "md:labs",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.showCreateTestDialog = true))
                    }))
                  : _createCommentVNode("v-if", true),
                _createVNode($setup["IconButton"], {
                  class: "rotate-90",
                  name: $setup.exploreStore.toolsVisible ? 'reveal-panel' : 'hide-panel',
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.exploreStore.toolsVisible = !$setup.exploreStore.toolsVisible))
                }, null, 8 /* PROPS */, ["name"])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              ($setup.exploreStore.mode === $setup.Mode.Table)
                ? (_openBlock(), _createBlock($setup["AsyncWrapper"], {
                    key: 0,
                    async: $setup.exploreStore.table
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["Table"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["async"]))
                : _createCommentVNode("v-if", true),
              ($setup.exploreStore.mode === $setup.Mode.Visualization)
                ? (_openBlock(), _createBlock($setup["Visualization"], { key: 1 }))
                : _createCommentVNode("v-if", true),
              ($setup.exploreStore.mode === $setup.Mode.SQL)
                ? (_openBlock(), _createBlock($setup["AsyncWrapper"], {
                    key: 2,
                    async: $setup.exploreStore.sqlData
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["SqlView"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["async"]))
                : _createCommentVNode("v-if", true)
            ]),
            _createVNode($setup["QueryProblems"])
          ])
        ]),
        ($setup.exploreStore.toolsVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode($setup["ExploreTools"])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    ($setup.exploreStore.creatingCalculation !== undefined)
      ? (_openBlock(), _createBlock($setup["Modal"], { key: 0 }, {
          default: _withCtx(() => [
            _createVNode($setup["CalculationForm"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.showCreateTestDialog)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 1,
          title: "Create Query Engine Test",
          "success-label": "Create Test",
          onSucceeded: _cache[5] || (_cache[5] = ($event: any) => ($setup.createExplorerTest())),
          onCancelled: _cache[6] || (_cache[6] = ($event: any) => ($setup.showCreateTestDialog = false))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Textbox"], {
              label: "Name of integration test",
              modelValue: $setup.testName,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.testName) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.bookmarkStatus === 'create' || $setup.bookmarkStatus === 'update')
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 2,
          title: $setup.bookmarkStatus === 'create' ? 'Add Bookmark' : 'Rename Bookmark',
          icon: "md:star",
          "success-label": "Done",
          onSucceeded: $setup.handleBookmark,
          onCancelled: $setup.clearBookmark
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Textbox"], {
              label: "Bookmark Name",
              modelValue: $setup.bookmarkName,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.bookmarkName) = $event)),
              autoselect: true,
              required: true
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title"]))
      : _createCommentVNode("v-if", true),
    ($setup.bookmarkStatus === 'showInvalidBookmark' || $setup.bookmarkStatus === 'failure')
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 3,
          title: "Error Details",
          icon: "md:error",
          "cancel-label": $setup.bookmarkStatus === 'showInvalidBookmark' ? 'Delete bookmark' : null,
          onSucceeded: _cache[8] || (_cache[8] = ($event: any) => ($setup.bookmarkStatus = undefined)),
          onCancelled: _cache[9] || (_cache[9] = ($event: any) => ($setup.deleteBookmark($setup.currentBookmark)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.bookmarkErrors, (error, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(error), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["cancel-label"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}