import { flatten, merge, omit, uniq } from "lodash";
import { v4 as uuid } from "uuid";
import {
  AliasLocations,
  FetchNConcept,
  FetchNPropertySet,
  FetchNResponse,
  RootAndNeighborRefs,
} from "./fetchApi";
import { Graph } from "./graph";
import { BASE_CONCEPT_TYPE } from "./knowledge";
import { Query } from "./query";
import { modernizeQuery } from "./queryBackCompat";
import { validateQuery } from "./queryValidator";
import { isValue, stringifyValue } from "./value";

// This special query type emulates future Project Coherence functionality.
// If you're seeing it more than 6 months from when this was first committed,
// I trust you to execute my estate faithfully. -js

export interface CombiningQuery {
  queries: Record<string, Query>;
  collate_by?: string; // Alias that must exist in all subqueries, used to re-group results
}

export function isCombiningQuery(query: Query | CombiningQuery) {
  return Object.hasOwn(query, "queries");
}

export function combinedQuerySignature(query: CombiningQuery | Query): Query {
  query = modernizeQuery(query);
  if (!isCombiningQuery(query)) return query as Query;
  const cq = query as CombiningQuery;
  const allRootTypes = uniq(Object.values(cq.queries).map((q) => q.root_concept_type));
  return {
    root_concept_type: allRootTypes.length === 1 ? allRootTypes[0] : BASE_CONCEPT_TYPE,
    columns: Object.values(cq.queries).flatMap((q) => q.columns),
    filters: [],
    group_by: [],
    order_by: [],
  };
}

export function combineQueryResults(
  query: CombiningQuery,
  results: Record<string, FetchNResponse>,
  aliasLocations: Record<string, AliasLocations>
): [FetchNResponse, AliasLocations] {
  function flattenPath(path: RootAndNeighborRefs, result: FetchNResponse) {
    const dataKeys: string[] = [path.root_id];
    for (const pathKey of Object.keys(omit(path, "root_id"))) {
      dataKeys.push(...flatten(path[pathKey]));
    }
    const sets = dataKeys.map((dk) => result.data[dk].properties);
    const flatPropSet: FetchNPropertySet = {};
    for (const set of sets) {
      for (const alias of Object.keys(set)) {
        (flatPropSet[alias] ||= []).push(...set[alias]);
      }
    }
    return flatPropSet;
  }

  const combinedProblems = Object.values(results).flatMap((r) => r.problems);
  const combinedAliasLocs = Object.assign({}, ...Object.values(aliasLocations));
  if (query.collate_by == null) {
    // "Group by all" mode: assume each query has a single result and smoosh them into one
    const propSets: FetchNPropertySet[] = [];
    for (const result of Object.values(results)) {
      propSets.push(flattenPath(result.paths[0], result));
    }
    const oneConcept: FetchNConcept = {
      concept_type: BASE_CONCEPT_TYPE,
      properties: Object.assign({}, ...propSets),
      truncated: [],
    };
    const combinedResponse: FetchNResponse = {
      paths: [{ root_id: "one_result" } as RootAndNeighborRefs],
      data: { one_result: oneConcept },
      problems: combinedProblems,
    };
    return [combinedResponse, combinedAliasLocs];
  } else {
    // Collate mode: Group results that match a particular alias value in all queries.
    const setsByKey: Record<string, FetchNPropertySet[]> = {};
    for (const result of Object.values(results)) {
      for (const path of result.paths) {
        const propSet = flattenPath(path, result);
        const keys = propSet[query.collate_by];
        if (keys.length != 1 || !isValue(keys[0])) continue;
        (setsByKey[stringifyValue(keys[0])] ||= []).push(propSet);
      }
    }
    const combinedResponse: FetchNResponse = {
      paths: [],
      data: {},
      problems: combinedProblems,
    };
    for (const sets of Object.values(setsByKey)) {
      const id = uuid();
      combinedResponse.paths.push({ root_id: id } as RootAndNeighborRefs);
      combinedResponse.data[id] = {
        concept_type: BASE_CONCEPT_TYPE,
        properties: merge({}, ...sets),
        truncated: [],
      };
    }
    return [combinedResponse, combinedAliasLocs];
  }
}

export function validateCombiningQuery(query: CombiningQuery | Query, metagraph: Graph): string[] {
  if (isCombiningQuery(query)) {
    return Object.values((query as CombiningQuery).queries).flatMap((q) =>
      validateQuery(q, metagraph)
    );
  } else {
    return validateQuery(query as Query, metagraph);
  }
}
