<template>
  <Dialog title="Edit project details" icon="md:edit_note" :skip-default-footer="true">
    <div v-if="state === State.Ok || state === State.Saving">
      <Textbox
        label="Description"
        v-model="projectDescription"
        :textarea="true"
        min-height="10em"
        :disabled="state === State.Saving"
      />
      <Textbox
        label="Additional LLM prompt"
        v-model="projectLLMPrompt"
        :textarea="true"
        min-height="10em"
        :disabled="state === State.Saving"
      />
    </div>
    <div v-if="state === State.Loading" class="flex flex-col items-center justify-around">
      <span>Loading…</span>
      <Spinner />
    </div>
    <MessageBar mode="error" v-if="error">
      {{ error }}
    </MessageBar>
    <template #footer>
      <div class="flex flex-row items-center justify-end gap-10 pt-10">
        <template v-if="state !== State.Saving">
          <TextButton label="Cancel" :secondary="true" @click="emit('closed')" />
          <TextButton
            v-if="state === State.Ok"
            label="Update"
            @click="saveProjectDetails"
            data-test="success-button"
          />
        </template>
        <SpinnerButton v-else label="Updating…" />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import Dialog from "@/common/components/Dialog.vue";
import SpinnerButton from "@/common/components/SpinnerButton.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { useKeyHandler } from "@/common/composables/useKeyHandler";
import { KeyCommand } from "@/common/lib/modifierKeys";
import { useUserModuleStore } from "@/common/stores/userModuleStore";
import { onMounted, ref } from "vue";
import Spinner from "./Spinner.vue";
import MessageBar from "./MessageBar.vue";

const props = defineProps<{
  projectId: string;
}>();
const emit = defineEmits(["closed"]);
const userModuleStore = useUserModuleStore();

enum State {
  Loading = "loading",
  Saving = "saving",
  Fatal = "fatal",
  Ok = "ok",
}

const state = ref(State.Loading);

const error = ref<string | undefined>();
const projectDescription = ref("");
const projectLLMPrompt = ref("");

async function saveProjectDetails() {
  state.value = State.Saving;
  error.value = undefined;
  try {
    const description = projectDescription.value.trim();
    const llmPrompt = projectLLMPrompt.value.trim();
    await userModuleStore.updateModule(props.projectId, {
      description,
      app_state: { llmPrompt },
    });
    emit("closed");
  } catch (e) {
    error.value = String(e);
  } finally {
    state.value = State.Ok;
  }
}

const keyHandler = useKeyHandler();
keyHandler.registerHandler(new KeyCommand("Escape"), () => emit("closed"), true, false);
keyHandler.registerHandler(new KeyCommand("Enter"), () => saveProjectDetails(), true, true);

onMounted(async () => {
  try {
    const moduleResponse = await userModuleStore.loadModule(props.projectId);
    projectDescription.value = moduleResponse.module.manifest.description ?? "";
    projectLLMPrompt.value = moduleResponse.module.app_state?.llmPrompt ?? "";
    state.value = State.Ok;
  } catch (e) {
    state.value = State.Fatal;
    error.value = String(e);
  }
});
</script>
