<template>
  <div class="absolute inset-0 m-15 mt-10 flex flex-col">
    <div class="mb-10 flex flex-row items-center">
      <div class="basis-full text-xl text-gray-500">Visualization Settings</div>
      <IconButtonV2
        name="md:content_copy"
        size="l"
        label="Copy Widget JSON"
        @click="copyWidgetJSON"
        :disabled="type == null"
      />
    </div>
    <div class="basis-full overflow-y-auto">
      <Select
        :options="types"
        :option-label="typeName"
        required
        label="Visualization Type"
        placeholder="Choose a type to get started"
        v-model="type"
        @update:model-value="prepareNewType"
      />

      <div v-for="control of controls" :key="control.key" class="my-10">
        <component
          :is="`viz-config-control-${control.type}`"
          :control="control"
          :query="query"
          :model-value="config![control.key] as PropertyValueGenerator"
          @update:model-value="
            (value: unknown) =>
              value === undefined ? delete config![control.key] : (config![control.key] = value)
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Select from "@/common/components/Select.vue";
import { PropertyValueGenerator } from "@/reader/lib/visualization";
import { Visualization, VisualizationType } from "@/reader/lib/visualizationTypes";
import { VISUALIZATION_CONFIG_DEFS } from "@/reader/lib/visualizationTypes";
import { fromPairs } from "lodash";
import { computed } from "vue";
import { Query } from "@/common/lib/query";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";
import { Widget } from "@/reader/lib/page";
import { v4 as uuidv4 } from "uuid";
import { useExploreStore } from "@/reader/stores/explore";

const exploreStore = useExploreStore();

const type = defineModel<VisualizationType | undefined>("type", { required: true });
const config = defineModel<Record<string, unknown> | undefined>("config", { required: true });
defineProps<{ query: Query }>();

const types = computed(() => Object.keys(VISUALIZATION_CONFIG_DEFS));
const controls = computed(() => configDef()?.controls ?? []);

function configDef() {
  if (type.value == null) return undefined;
  return VISUALIZATION_CONFIG_DEFS[type.value];
}

function typeName(vizType: string) {
  return VISUALIZATION_CONFIG_DEFS[vizType].label;
}

function prepareNewType(newType?: VisualizationType) {
  if (newType == null) {
    config.value = {};
  } else {
    const pairs = VISUALIZATION_CONFIG_DEFS[newType].controls.map((c) => [c.key, undefined]);
    config.value = fromPairs(pairs);
  }
}

function copyWidgetJSON() {
  const widget: Widget = {
    key: uuidv4(),
    top: 0,
    left: 0,
    width: 4,
    height: 4,
    visualization: {
      type: type.value!,
      title: "Explored Visualization",
      config: config.value!,
      query: exploreStore.query!,
    } as Visualization,
  };
  navigator.clipboard.writeText(JSON.stringify(widget, undefined, 2));
}
</script>
