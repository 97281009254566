import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-bold" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Dropdown"], {
    placement: "right",
    shown: $setup.isEditing,
    "onUpdate:shown": _cache[4] || (_cache[4] = ($event: any) => (($setup.isEditing) = $event)),
    onApplyShow: $setup.focusFirstField
  }, {
    popper: _withCtx(() => [
      _createVNode($setup["FilterEditor"], {
        label: $setup.formLabel,
        onCancel: _cache[3] || (_cache[3] = ($event: any) => ($setup.isEditing = false)),
        onCommit: $setup.commit,
        valid: $setup.isEditValid
      }, {
        default: _withCtx(() => [
          ($setup.valueType === $setup.GraphValueType.Datetime)
            ? (_openBlock(), _createBlock($setup["SelectStrip"], {
                key: 0,
                "model-value": $setup.specifyTimeOfDay,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = (v) => $setup.setSpecifyTimeOfDay(v as boolean)),
                options: [
            { label: 'Date', value: false },
            { label: 'Date & Time', value: true },
          ],
                class: "mb-5 h-[28px]"
              }, null, 8 /* PROPS */, ["model-value"]))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["Textbox"], {
            type: $setup.inputType,
            modelValue: $setup.lowerBoundUnderEdit,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.lowerBoundUnderEdit) = $event)),
            ref: "lowerBoundInputEl",
            label: $setup.inputType === 'date' ? 'Start date' : 'Minimum value',
            class: "w-full"
          }, null, 8 /* PROPS */, ["type", "modelValue", "label"]),
          _createVNode($setup["Textbox"], {
            type: $setup.inputType,
            modelValue: $setup.upperBoundUnderEdit,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.upperBoundUnderEdit) = $event)),
            label: $setup.inputType === 'date' ? 'End date' : 'Maximum value',
            class: "w-full"
          }, null, 8 /* PROPS */, ["type", "modelValue", "label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "valid"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        onClick: $setup.startEditing,
        class: "cursor-pointer border-b border-b-transparent hover:border-b-gray-500"
      }, [
        _createTextVNode(_toDisplayString($setup.opDescription) + " ", 1 /* TEXT */),
        ($setup.filter.values.length > 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              ($setup.isExact)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[5] || (_cache[5] = _createTextVNode(" exactly ")),
                    _createElementVNode("span", _hoisted_1, _toDisplayString($setup.stringifyValue($setup.lowerBound!)), 1 /* TEXT */)
                  ], 64 /* STABLE_FRAGMENT */))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    ($setup.upperBound == null)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _cache[6] || (_cache[6] = _createTextVNode(" at least ")),
                          _createElementVNode("span", _hoisted_2, _toDisplayString($setup.stringifyValue($setup.lowerBound!)), 1 /* TEXT */)
                        ], 64 /* STABLE_FRAGMENT */))
                      : ($setup.lowerBound == null)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _cache[7] || (_cache[7] = _createTextVNode(" at most ")),
                            _createElementVNode("span", _hoisted_3, _toDisplayString($setup.stringifyValue($setup.upperBound)), 1 /* TEXT */)
                          ], 64 /* STABLE_FRAGMENT */))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _cache[8] || (_cache[8] = _createTextVNode(" between ")),
                            _createElementVNode("span", _hoisted_4, _toDisplayString($setup.stringifyValue($setup.lowerBound)), 1 /* TEXT */),
                            _cache[9] || (_cache[9] = _createTextVNode(" and ")),
                            _createElementVNode("span", _hoisted_5, _toDisplayString($setup.stringifyValue($setup.upperBound)), 1 /* TEXT */)
                          ], 64 /* STABLE_FRAGMENT */))
                  ], 64 /* STABLE_FRAGMENT */))
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode("(click to set)")
            ], 64 /* STABLE_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["shown"]))
}