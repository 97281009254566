import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "input peer flex w-full flex-col items-start self-stretch" }
const _hoisted_2 = {
  key: 0,
  class: "text-s pb-5 pr-0 pt-5 font-bold text-gray-500 peer-focus:text-orange"
}
const _hoisted_3 = {
  key: 0,
  class: "text-orange"
}
const _hoisted_4 = {
  key: 1,
  class: "flex w-full justify-between pt-5"
}
const _hoisted_5 = {
  key: 0,
  class: "flex"
}
const _hoisted_6 = {
  key: 1,
  class: "ml-auto flex"
}
const _hoisted_7 = {
  key: 2,
  class: "pt-5 text-xs text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label && ($setup.hasSelectedValues || $props.placeholder))
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString($props.label), 1 /* TEXT */),
          ($setup.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " *"))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["vSelect"], {
      class: "dropdown-options self-stretch",
      options: $props.options,
      "model-value": $props.delaySave ? $setup.selectedValues : $setup.modelValue,
      "onUpdate:modelValue": $setup.handleUpdate,
      placeholder: $props.placeholder || $props.label,
      label: $setup.isString($props.optionLabel) ? $props.optionLabel : undefined,
      "get-option-label": $setup.isFunction($props.optionLabel) ? $props.optionLabel : undefined,
      reduce: $props.optionValue,
      disabled: $props.disabled,
      clearable: $props.clearable,
      multiple: $props.multiple,
      filter: $props.filter
    }, {
      option: _withCtx((option) => [
        _renderSlot(_ctx.$slots, "option-data", _normalizeProps(_guardReactiveProps(option)))
      ]),
      "selected-option": _withCtx((option) => [
        ($setup.slots['selected-option-data'])
          ? _renderSlot(_ctx.$slots, "selected-option-data", _normalizeProps(_mergeProps({ key: 0 }, option)))
          : _renderSlot(_ctx.$slots, "option-data", _normalizeProps(_mergeProps({ key: 1 }, option)))
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["options", "model-value", "placeholder", "label", "get-option-label", "reduce", "disabled", "clearable", "multiple", "filter"]),
    ($props.delaySave || $props.clearAll)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          ($setup.hasSelectedValues && $props.clearAll)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode($setup["TextButton"], {
                  label: "Clear All",
                  onClick: $setup.clearSelection,
                  secondary: true
                })
              ]))
            : _createCommentVNode("v-if", true),
          ($props.delaySave && $setup.hasChanges)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode($setup["TextButton"], {
                  label: "Cancel",
                  onClick: $setup.resetSelection,
                  secondary: true
                }),
                _createVNode($setup["TextButton"], {
                  label: "Apply",
                  onClick: $setup.commitSelection
                })
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    ($props.helperText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($props.helperText), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}