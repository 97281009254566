import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "sticky" }
const _hoisted_3 = {
  key: 1,
  class: "w-[25px]"
}
const _hoisted_4 = { class: "p-10 align-baseline" }
const _hoisted_5 = {
  key: 0,
  class: "select-text text-nowrap text-right"
}
const _hoisted_6 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_7 = {
  key: 0,
  class: "p-10 align-baseline"
}
const _hoisted_8 = {
  key: 0,
  class: "select-text text-nowrap text-right opacity-75"
}
const _hoisted_9 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_10 = {
  key: 1,
  class: "p-10 align-baseline"
}
const _hoisted_11 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_12 = {
  key: 0,
  class: "bg-red select-text text-nowrap text-right"
}
const _hoisted_13 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_14 = { class: "p-10 align-baseline" }
const _hoisted_15 = {
  key: 0,
  class: "select-text text-nowrap text-right opacity-75"
}
const _hoisted_16 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_17 = { class: "p-10 align-baseline" }
const _hoisted_18 = {
  key: 1,
  class: "text-right opacity-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FinancialSubtable = _resolveComponent("FinancialSubtable", true)!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.rows, (row, rowIndex) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: rowIndex }, [
      _createElementVNode("tr", {
        class: _normalizeClass([{
        'odd:bg-gray-100': row.contents == null && !row.highlight,
        'bg-finance-row_even odd:bg-finance-row_odd': row.highlight,
      }, "cursor-pointer hover:bg-finance-highlight"]),
        onClick: ($event: any) => (row.contents != null && $setup.expandMap(row.label))
      }, [
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("div", {
            class: "flex flex-row items-center text-nowrap font-bold",
            style: _normalizeStyle($setup.indentStyle)
          }, [
            (row.contents != null)
              ? (_openBlock(), _createBlock($setup["Icon"], {
                  key: 0,
                  name: 
              $setup.expandedMap.has(row.label) ? 'md:keyboard_arrow_down' : 'md:keyboard_arrow_right'
            ,
                  color: "medium-gray",
                  size: "xl"
                }, null, 8 /* PROPS */, ["name"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3)),
            _createTextVNode(" " + _toDisplayString(row.label), 1 /* TEXT */)
          ], 4 /* STYLE */)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cells(row), (cell, colIndex) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: colIndex }, [
            (!$props.skipMonths || colIndex >= $props.skipMonths)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createCommentVNode(" Value "),
                  _createElementVNode("td", _hoisted_4, [
                    _createElementVNode("div", {
                      style: _normalizeStyle(colIndex == 0 ? $setup.indentStyle : undefined)
                    }, [
                      (cell.value != null)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode($setup["GraphValueComponent"], {
                              value: cell.value.formattedValue
                            }, null, 8 /* PROPS */, ["value"])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
                    ], 4 /* STYLE */)
                  ]),
                  _createCommentVNode(" Over comparison value "),
                  (colIndex > 0)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                        (
                cell.overComparisonValue != null &&
                cell.overComparisonValue.formattedValue.value !== '100%'
              )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createVNode($setup["GraphValueComponent"], {
                                value: cell.overComparisonValue?.formattedValue
                              }, null, 8 /* PROPS */, ["value"])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_9, "-"))
                      ]))
                    : _createCommentVNode("v-if", true),
                  _createCommentVNode(" Over previous period "),
                  (colIndex > 0)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_10, [
                        (cell.overPreviousPeriod != null)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["select-text text-nowrap text-right opacity-75", {
                'text-red-500': $setup.isAsset(cell) < 0,
                'text-green-500': $setup.isAsset(cell) > 0,
              }])
                            }, [
                              _createVNode($setup["GraphValueComponent"], {
                                value: cell.overPreviousPeriod?.formattedValue
                              }, null, 8 /* PROPS */, ["value"])
                            ], 2 /* CLASS */))
                          : (_openBlock(), _createElementBlock("div", _hoisted_11, "-"))
                      ]))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.trailingColumns, (colName, colIndex) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: colName }, [
            _createElementVNode("td", {
              class: _normalizeClass(["p-10 align-baseline", { 'border-l-[8px] border-solid border-l-finance-highlight': colIndex === 0 }])
            }, [
              _createElementVNode("div", {
                style: _normalizeStyle(colIndex == 0 ? $setup.indentStyle : undefined)
              }, [
                (row.cells[colIndex + $props.columns.length].value?.formattedValue)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode($setup["GraphValueComponent"], {
                        value: row.cells[colIndex + $props.columns.length].value!.formattedValue
                      }, null, 8 /* PROPS */, ["value"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_13, "-"))
              ], 4 /* STYLE */)
            ], 2 /* CLASS */),
            _createCommentVNode(" Over comparison value "),
            _createElementVNode("td", _hoisted_14, [
              (
              row.cells[colIndex + $props.columns?.length].overComparisonValue != null &&
              row.cells[colIndex + $props.columns?.length].overComparisonValue!.formattedValue.value !==
                '100%'
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode($setup["GraphValueComponent"], {
                      value: row.cells[colIndex + $props.columns?.length].overComparisonValue!.formattedValue
                    }, null, 8 /* PROPS */, ["value"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_16, "-"))
            ])
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */)),
        _createCommentVNode(" Variance "),
        _createElementVNode("td", _hoisted_17, [
          (row.variance != null)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["select-text text-nowrap text-right font-bold", {
            'text-red-500': $setup.isAssetVariance(row.variance, row.cells[0].asset) < 0,
            'text-green-500': $setup.isAssetVariance(row.variance, row.cells[0].asset) > 0,
          }])
              }, [
                _createVNode($setup["GraphValueComponent"], {
                  value: row.variance?.formattedValue
                }, null, 8 /* PROPS */, ["value"])
              ], 2 /* CLASS */))
            : (_openBlock(), _createElementBlock("div", _hoisted_18, "-"))
        ])
      ], 10 /* CLASS, PROPS */, _hoisted_1),
      (row.contents != null && $setup.expandedMap.has(row.label))
        ? (_openBlock(), _createBlock(_component_FinancialSubtable, {
            key: 0,
            rows: row.contents,
            columns: $props.columns,
            depth: $setup.depth + 1,
            trailingColumns: $props.trailingColumns,
            expandedMap: $setup.expandedMap,
            onUpdateExpanded: _cache[0] || (_cache[0] = (rowLabel) => $setup.emits('update-expanded', rowLabel)),
            "skip-months": $props.skipMonths
          }, null, 8 /* PROPS */, ["rows", "columns", "depth", "trailingColumns", "expandedMap", "skip-months"]))
        : _createCommentVNode("v-if", true)
    ], 64 /* STABLE_FRAGMENT */))
  }), 128 /* KEYED_FRAGMENT */))
}