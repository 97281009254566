import { environment } from "@/common/environments/environmentLoader";
import { httpClient } from "@/common/http/http";
import { FailureType } from "@/common/lib/failure";
import { User } from "@/common/lib/user";
import { UserSettings } from "@/common/lib/userSettings";
import { useFailureStore } from "@/common/stores/failureStore";
import { defineStore } from "pinia";
import { useSentry } from "../monitoring/sentry/sentryStore";

interface State {
  user?: User;
  userSettings: UserSettings | undefined;
  enabled: boolean;
}

export const useUserStore = defineStore("user", {
  state: (): State => ({
    user: undefined,
    userSettings: undefined,
    enabled: environment.require("AUTH_TYPE") != "none",
  }),
  getters: {
    globalAdmin: (state) => {
      return state.user?.global_admin === true;
    },
  },
  actions: {
    async reloadUser() {
      if (!this.enabled) {
        return;
      }
      const response = await httpClient.get("/api/user");
      this.user = response.data.user;
      const settingsResponse = await httpClient.get("/api/user/settings");
      this.userSettings = settingsResponse.data.user_settings;
    },
    async loadUserOnce() {
      if (!this.enabled || this.user) {
        return;
      }
      const response = await httpClient.get("/api/user");
      this.user = response.data.user;
      if (this.user) {
        useSentry().sentry?.setUser(this.user);
      }
    },
    async updateUserName(userName: string): Promise<boolean> {
      try {
        const params = { name: userName };
        await httpClient.patch(`/api/user`, params);
        await this.reloadUser();
        return true;
      } catch (error: unknown) {
        useFailureStore().backendFail({
          type: FailureType.Api,
          description: "Failed to update user name",
          error,
          hideUndo: true,
        });
        return false;
      }
    },
    async resyncUser(): Promise<boolean> {
      try {
        const response = await httpClient.post("/api/user/resync");
        this.user = response.data;
        return true;
      } catch (error: unknown) {
        useFailureStore().backendFail({
          type: FailureType.Api,
          description: "Failed to resync user",
          error,
          hideUndo: true,
        });
        return false;
      }
    },
    async updateUserPassword(newPassword: string): Promise<{ success: boolean; error?: string }> {
      try {
        const params = { new_password: newPassword };
        const response = await httpClient.patch<{ success: boolean; error?: string }>(
          "/api/user/password",
          params
        );
        return response.data;
      } catch (error: unknown) {
        useFailureStore().backendFail({
          type: FailureType.Api,
          description: "Failed to update user password",
          error,
          hideUndo: true,
        });
        return { success: true };
      }
    },
    async updateUserSettings(params: UserSettings): Promise<boolean> {
      try {
        await httpClient.patch(`/api/user/settings`, params);
        await this.reloadUser();
        return true;
      } catch (error: unknown) {
        useFailureStore().backendFail({
          type: FailureType.Api,
          description: "Failed to update user settings",
          error,
          hideUndo: true,
        });
        return false;
      }
    },
  },
});
