import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "py-[3px] pr-4" }
const _hoisted_3 = {
  key: 0,
  class: "max-w-[80px] overflow-hidden text-ellipsis pl-5 text-right"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "max-w-[80px] overflow-hidden text-ellipsis pl-5 text-right font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.itemDetails, (item) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: item.key,
          class: _normalizeClass(["group/legenditem whitespace-nowrap", { 'cursor-pointer': item.identifier != null }]),
          onClick: ($event: any) => {if (item.identifier != null) $setup.emit('select', item.identifier);}
        }, [
          _createElementVNode("td", _hoisted_2, [
            _createElementVNode("div", {
              style: _normalizeStyle({ backgroundColor: item.color, outlineColor: item.color }),
              class: _normalizeClass(["h-15 w-15 rounded-full outline-2", { 'group-hover/legenditem:outline': item.identifier != null }])
            }, null, 6 /* CLASS, STYLE */)
          ]),
          _createElementVNode("td", {
            class: _normalizeClass(["max-w-[80px] overflow-hidden text-ellipsis", { 'font-semibold': item.identifier != null }])
          }, _toDisplayString(item.label), 3 /* TEXT, CLASS */),
          (item.value)
            ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(item.value), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ], 10 /* CLASS, PROPS */, _hoisted_1))
      }), 128 /* KEYED_FRAGMENT */)),
      ($setup.total)
        ? (_openBlock(), _createElementBlock("tr", _hoisted_4, [
            _cache[0] || (_cache[0] = _createElementVNode("td", null, null, -1 /* HOISTED */)),
            _cache[1] || (_cache[1] = _createElementVNode("td", { class: "py-4 text-right font-semibold" }, "Total", -1 /* HOISTED */)),
            _createElementVNode("td", _hoisted_5, _toDisplayString($setup.totalString), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}