import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "my-10" }
const _hoisted_2 = { class: "uppercase text-gray-500" }
const _hoisted_3 = { class: "values" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($setup.propTypeLabel), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.valueDetails, ({ value, color }, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "flex items-baseline"
        }, [
          color
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle({ backgroundColor: color }),
                class: "mr-4 h-10 w-10 rounded-full"
              }, null, 4 /* STYLE */))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["GraphValueComponent"], { value: value }, null, 8 /* PROPS */, ["value"])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}