import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { rootColumns } from "@/reader/lib/explore";
import { computed, inject, Ref, ComputedRef } from "vue";
import vSelect from "vue-select";
import { DarkMode } from "@/common/lib/keys";
import { columnName, QueryColumn } from "@/common/lib/query";
import { v4 as uuid } from "uuid";


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupBy',
  props: {
    "modelValue": { type: [Object, null], ...{ required: true } },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {
  __expose();

const darkMode = inject(DarkMode) as Ref<boolean>;
const model = _useModel<QueryColumn | null>(__props, "modelValue");

const options: ComputedRef<QueryColumn[]> = computed(() =>
  rootColumns().map((column) => ({
    alias: uuid(),
    property_type: column.property_type,
  }))
);

function groupByLabel(col: QueryColumn) {
  return columnName(col);
}

const __returned__ = { darkMode, model, options, groupByLabel, get vSelect() { return vSelect } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})