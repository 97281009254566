import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import {
  DerivedPropertyTerm,
  DateDiffPropertyType,
  PropertyOpType,
  TimeUnit,
} from "@/common/lib/derived";
import { computed, inject, Ref, ref, watchEffect } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import { DarkMode } from "@/common/lib/keys";
import vSelect from "vue-select";
import { capitalize } from "lodash";
import pluralize from "pluralize";


export default /*@__PURE__*/_defineComponent({
  __name: 'DateDiffPropertyDef',
  props: {
    "modelValue": { type: [Object, null], ...{ required: true } },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {
  __expose();

const darkMode = inject(DarkMode) as Ref<boolean>;

const OP = PropertyOpType.DateDiff;

const unit = ref<TimeUnit>(TimeUnit.Day);
const start: Ref<DerivedPropertyTerm | null> = ref(null);
const end: Ref<DerivedPropertyTerm | null> = ref(null);

const model = _useModel<DateDiffPropertyType | null>(__props, "modelValue");

const options = computed(() => Object.values(TimeUnit));

function formatUnit(unit: TimeUnit) {
  return pluralize(capitalize(unit));
}

watchEffect(function () {
  if (start.value == null || end.value == null) {
    model.value = null;
  } else {
    model.value = {
      op: OP,
      unit: unit.value,
      start: start.value,
      end: end.value,
    };
  }
});

const __returned__ = { darkMode, OP, unit, start, end, model, options, formatUnit, DerivedPropertyDef, get vSelect() { return vSelect } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})