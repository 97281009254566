import { defineComponent as _defineComponent } from 'vue'
import { generateValue } from "@/reader/lib/visualization";
import { IndicatorVisualization } from "@/reader/lib/visualizationTypes";
import { computed, toRefs } from "vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { stringifyValue } from "@/common/lib/value";


export default /*@__PURE__*/_defineComponent({
  __name: 'Indicator',
  props: {
    visualization: { type: Object, required: true },
    results: { type: Array, required: true },
    height: { type: Number, required: true },
    width: { type: Number, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { visualization, results, height, width } = toRefs(props);

const datum = computed(function () {
  const viz = visualization.value;
  if (results.value.length !== 1) return null;
  return generateValue(viz.config.value, results.value[0], viz.query);
});
const textHeight = computed(() => {
  let size = height.value / 5 + 25;
  const stringLength = stringifyValue(datum.value?.formattedValue).length;

  if (stringLength * (size / 1.5) > width.value) {
    size = (1.5 * width.value) / stringLength;
  }
  return size;
});

const __returned__ = { props, visualization, results, height, width, datum, textHeight, GraphValueComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})