import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-xs text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Checkbox"], {
      label: $setup.control.label,
      modelValue: $setup.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event))
    }, null, 8 /* PROPS */, ["label", "modelValue"]),
    ($setup.control.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.control.description), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}