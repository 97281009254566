import { defineComponent as _defineComponent } from 'vue'
import {
  generateValue,
  generateValues,
  generatorName,
  generatorOutputType,
  ValueGenerator,
} from "@/reader/lib/visualization";
import { computed, toRefs } from "vue";
import { last, tail } from "lodash";
import { stringifyValueOrCompositeValue } from "@/common/lib/graph";
import { GraphValue, QUANTITATIVE_VALUE_TYPES, toValue } from "@/common/lib/value";
import SubtableComponent from "./table/Subtable.vue";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { TableVisualization, TableVisualizationGroup } from "@/reader/lib/visualizationTypes";
import { combinedQuerySignature } from "@/common/lib/combiningQuery";

export interface Subtable {
  rows?: (ValueWithFormattedValue | null)[][];
  groups?: TableGroup[];
}

export interface Column {
  generator: ValueGenerator;
  label: string;
  isQuantitative: boolean;
  hideBars: boolean;
}

interface TableGroup {
  id: string;
  name: GraphValue;
  contents: Subtable;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Table',
  props: {
    visualization: { type: Object, required: true },
    results: { type: Array, required: true },
    width: { type: Number, required: true },
    height: { type: Number, required: true }
  },
  emits: ["select"],
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;



const { visualization, results } = toRefs(props);

const columns = computed(function (): Column[] {
  const query = combinedQuerySignature(visualization.value.query);
  return visualization.value.config.columns.map(function (colspec) {
    const valueType = generatorOutputType(colspec.value, query);
    return {
      generator: colspec.value,
      label: colspec.label ?? generatorName(colspec.value, query),
      isQuantitative: valueType ? QUANTITATIVE_VALUE_TYPES.includes(valueType) : false,
      hideBars: colspec.hide_bars ?? false,
    };
  });
});

function buildRows(results: UseQueryResult[]) {
  const query = combinedQuerySignature(visualization.value.query);
  return results.map(function (row) {
    return columns.value.map((col) => generateValue(col.generator, row, query));
  });
}

function buildGroups(
  results: UseQueryResult[],
  groupspecs: TableVisualizationGroup[]
): TableGroup[] {
  const query = combinedQuerySignature(visualization.value.query);
  const groupspec = groupspecs[0];
  const rowGroups: UseQueryResult[][] = [];
  let currentId: string | null | undefined = undefined;
  for (const row of results) {
    const category = row.valuesByAlias[groupspec.category];
    const id = category ? stringifyValueOrCompositeValue(category[0]) : null;
    if (id !== currentId) {
      currentId = id;
      rowGroups.push([]);
    }
    last(rowGroups)!.push(row);
  }
  return rowGroups.map(function (rowGroup) {
    const firstRow = rowGroup[0];
    const values = generateValues({ ...groupspec }, firstRow, query);
    return {
      id: values.category ? stringifyValueOrCompositeValue(values.category.originalValue) : "",
      name: (values.category_name ?? values.category)?.formattedValue ?? toValue("-"),
      contents: buildSubtable(rowGroup, tail(groupspecs)),
    };
  });
}

function buildSubtable(results: UseQueryResult[], groupspecs: TableVisualizationGroup[]): Subtable {
  if (groupspecs.length == 0) {
    return { rows: buildRows(results) };
  } else {
    return { groups: buildGroups(results, groupspecs) };
  }
}

const table = computed(() => buildSubtable(results.value, visualization.value.config.groups ?? []));

const __returned__ = { props, visualization, results, columns, buildRows, buildGroups, buildSubtable, table, SubtableComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})