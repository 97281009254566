<template>
  <div class="h-full bg-gray-200 dark:bg-gray-800">
    <div class="user-details">
      <div class="flex bg-gray-100 dark:bg-gray-900">
        <TabSelect :tabs="availableTabs" v-model="selectedTab" />
      </div>
      <div class="m-30 flex flex-col gap-30 text-gray-800 dark:text-gray-200">
        <UserSettings v-if="selectedTab === 'user-settings'" />
        <ConnectionSettings v-if="selectedTab === SelectedTab.Connections" />
        <UserPreferences v-if="selectedTab === SelectedTab.Preferences" />
        <AdminPage v-if="selectedTab === SelectedTab.Admin" />
        <QuickBooksTab v-if="selectedTab === SelectedTab.QuickBooks" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TabSelect from "@/common/components/TabSelect.vue";
import { SelectedTab, useSettingsStore } from "@/common/stores/settingsStore";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { onMounted, watch } from "vue";
import ConnectionSettings from "@/common/components/settings/ConnectionSettings.vue";
import { useRouter } from "vue-router";
import AdminPage from "@/common/components/admin/AdminPage.vue";
import UserPreferences from "./UserPreferences.vue";
import UserSettings from "./UserSettings.vue";
import QuickBooksTab from "./QuickBooksTab.vue";

const userStore = useUserStore();
const router = useRouter();

const { availableTabs, selectedTab } = storeToRefs(useSettingsStore());

watch(selectedTab, (newValue) => {
  router.replace({ name: newValue });
});
onMounted(() => {
  userStore.reloadUser();
});
</script>
