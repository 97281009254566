import { defineComponent as _defineComponent } from 'vue'
import { useAuthProvider } from "@/common/auth/authStore";
import Dialog from "@/common/components/Dialog.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import MessageBar from "./MessageBar.vue";

interface ChangePassword {
  newPassword: string;
  confirmNewPassword: string;
  error?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserSettings',
  setup(__props, { expose: __expose }) {
  __expose();

const auth = useAuthProvider();
const editingUserName = ref<string | undefined>();
const editingChangePassword = ref<ChangePassword | undefined>();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

async function resync() {
  await userStore.resyncUser();
}

function logout() {
  auth.logout(window.location.origin + environment.require("AUTH0_LOGOUT_PATH"));
}

function editUserName() {
  editingUserName.value = user?.value?.name;
}

async function finishEditUserName() {
  if (!editingUserName.value) {
    return;
  }
  const success = await userStore.updateUserName(editingUserName.value);
  if (!success) {
    return;
  }
  editingUserName.value = undefined;
}

async function finishChangePassword() {
  if (!editingChangePassword.value) {
    return;
  }
  editingChangePassword.value.error = undefined;
  const result = await userStore.updateUserPassword(editingChangePassword.value.newPassword);
  if (result.error) {
    editingChangePassword.value.error = result.error;
    return;
  }
  editingChangePassword.value = undefined;
}

const __returned__ = { auth, editingUserName, editingChangePassword, userStore, user, resync, logout, editUserName, finishEditUserName, finishChangePassword, Dialog, Textbox, TextButton, MessageBar }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})