import { createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-10" }
const _hoisted_2 = { class: "my-10" }
const _hoisted_3 = { class: "my-10" }
const _hoisted_4 = { class: "selection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("label", { class: "mb-5 block" }, "Start:", -1 /* HOISTED */)),
      _createVNode($setup["DerivedPropertyDef"], {
        "parent-op": $setup.OP,
        modelValue: $setup.start,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.start) = $event))
      }, null, 8 /* PROPS */, ["parent-op", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { class: "mb-5 block" }, "End:", -1 /* HOISTED */)),
      _createVNode($setup["DerivedPropertyDef"], {
        "parent-op": $setup.OP,
        modelValue: $setup.end,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.end) = $event))
      }, null, 8 /* PROPS */, ["parent-op", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[5] || (_cache[5] = _createElementVNode("label", { class: "mb-5 block" }, "Unit:", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["vSelect"], {
          modelValue: $setup.unit,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.unit) = $event)),
          options: $setup.options,
          appendToBody: true,
          class: _normalizeClass(["select", { 'light-select': !$setup.darkMode }]),
          getOptionLabel: $setup.formatUnit
        }, null, 8 /* PROPS */, ["modelValue", "options", "class"])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}