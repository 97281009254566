import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { PropertyValueGenerator, ValueGeneratorType } from "@/reader/lib/visualization";
import Select from "@/common/components/Select.vue";
import { ColumnVisualizationConfigControl } from "@/reader/lib/visualizationConfig";
import { columnName, findDeepColumnByAlias, Query } from "@/common/lib/query";
import { computed, Ref, toRefs } from "vue";
import { propertyValueType } from "@/common/lib/derived";


export default /*@__PURE__*/_defineComponent({
  __name: 'VisualizationColumnControl',
  props: /*@__PURE__*/_mergeModels({
    control: { type: Object, required: true },
    query: { type: Object, required: true }
  }, {
    "modelValue": { type: null, ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { query, control } = toRefs(props);

const model = _useModel<PropertyValueGenerator | undefined>(__props, "modelValue");

const columns: Ref<PropertyValueGenerator[]> = computed(function () {
  let columns = query.value.columns;
  const limit = control.value.config.limit_value_types;
  if (limit != null) {
    columns = columns.filter((col) => limit.includes(propertyValueType(col.property_type)));
  }
  return columns.map((c) => ({
    type: ValueGeneratorType.Property,
    alias: c.alias,
  }));
});

function columnLabel(pvg: PropertyValueGenerator) {
  return columnName(findDeepColumnByAlias(query.value, pvg.alias)!);
}

function setModel(newValue: PropertyValueGenerator | undefined | null) {
  if (newValue === null) newValue = undefined;
  model.value = newValue;
}

const __returned__ = { props, query, control, model, columns, columnLabel, setModel, Select }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})