<template>
  <div class="my-10">
    <label class="mb-5 block">Start:</label>
    <DerivedPropertyDef :parent-op="OP" v-model="start" />
  </div>
  <div class="my-10">
    <label class="mb-5 block">End:</label>
    <DerivedPropertyDef :parent-op="OP" v-model="end" />
  </div>
  <div class="my-10">
    <label class="mb-5 block">Unit:</label>
    <div class="selection">
      <v-select
        v-model="unit"
        :options="options"
        :appendToBody="true"
        class="select"
        :class="{ 'light-select': !darkMode }"
        :getOptionLabel="formatUnit"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  DerivedPropertyTerm,
  DateDiffPropertyType,
  PropertyOpType,
  TimeUnit,
} from "@/common/lib/derived";
import { computed, inject, Ref, ref, watchEffect } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import { DarkMode } from "@/common/lib/keys";
import vSelect from "vue-select";
import { capitalize } from "lodash";
import pluralize from "pluralize";

const darkMode = inject(DarkMode) as Ref<boolean>;

const OP = PropertyOpType.DateDiff;

const unit = ref<TimeUnit>(TimeUnit.Day);
const start: Ref<DerivedPropertyTerm | null> = ref(null);
const end: Ref<DerivedPropertyTerm | null> = ref(null);

const model = defineModel<DateDiffPropertyType | null>({ required: true });

const options = computed(() => Object.values(TimeUnit));

function formatUnit(unit: TimeUnit) {
  return pluralize(capitalize(unit));
}

watchEffect(function () {
  if (start.value == null || end.value == null) {
    model.value = null;
  } else {
    model.value = {
      op: OP,
      unit: unit.value,
      start: start.value,
      end: end.value,
    };
  }
});
</script>
