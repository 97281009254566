import { VisualizationType } from "@/reader/lib/visualizationTypes";
import { App } from "vue";
import DiscreteDistribution from "./DiscreteDistribution.vue";
import FinancialStatement from "./FinancialStatement.vue";
import Indicator from "./Indicator.vue";
import PieChart from "./PieChart.vue";
import Sankey from "./Sankey.vue";
import Table from "./Table.vue";
import TimeDistribution from "./TimeDistribution.vue";

export default function (app: App): void {
  app.component(`visualization-${VisualizationType.DiscreteDistribution}`, DiscreteDistribution);
  app.component(`visualization-${VisualizationType.TimeDistribution}`, TimeDistribution);
  app.component(`visualization-${VisualizationType.Indicator}`, Indicator);
  app.component(`visualization-${VisualizationType.PieChart}`, PieChart);
  app.component(`visualization-${VisualizationType.Table}`, Table);
  app.component(`visualization-${VisualizationType.Sankey}`, Sankey);
  app.component(`visualization-${VisualizationType.FinancialStatement}`, FinancialStatement);
}
