<template>
  <div class="flex flex-col items-start gap-15">
    <div class="text-xl">Users</div>
    <div v-if="users.status === AsyncStatus.Succeeded">
      <table class="w-full table-fixed border-collapse">
        <thead>
          <tr class="text-left">
            <th scope="col" class="p-5">Full name</th>
            <th scope="col" class="p-5">Email</th>
            <th scope="col" class="p-5">Status</th>
            <th scope="col" class="p-5">Organization</th>
            <th scope="col" class="p-5">Default workspace</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in users.result"
            :key="user.user_id"
            @click="currentUserId = user.user_id"
            class="hover:bg-orange"
            :class="{ 'bg-orange': user.user_id === currentUserId }"
          >
            <td class="p-5">{{ user.name }}</td>
            <td class="p-5">{{ user.email }}</td>
            <td class="p-5">{{ user.status }}</td>
            <td class="p-5">{{ user.organization ?? "-" }}</td>
            <td class="p-5">{{ user.settings?.["workspace_id"] ?? "-" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="users.status === AsyncStatus.InProgress">
      <Spinner />
    </div>
  </div>
  <Dialog
    icon="md:settings"
    :title="`Settings for ${currentUser.name}`"
    v-if="currentUser"
    :success-label="null"
    cancel-label="Done"
    @cancelled="currentUserId = undefined"
  >
    <MessageBar mode="error" v-if="errorMessage">{{ errorMessage }}</MessageBar>
    <div class="flex flex-col gap-5">
      <AdminSetting label="ID" :value="currentUser.user_id" :readonly="true" :copyable="true" />
      <AdminSetting
        label="Admin"
        :value="toString(currentUser.status === 'admin')"
        :readonly="true"
      />
      <AdminSetting
        label="Name"
        :value="currentUser.name"
        @update="(name) => updateName(currentUser!.user_id, name)"
        :loading="loading"
      />
      <AdminSetting label="Email" :value="currentUser.email" :readonly="true" />
      <AdminSetting
        label="Password"
        value="••••••••"
        @update="(pw) => changePassword(currentUser!.user_id, pw)"
        :loading="loading"
      />
      <AdminSetting
        label="Homepage"
        :value="currentUser.settings?.homepage"
        @update="(url) => setHomepage(currentUser!.user_id, url)"
        :loading="loading"
      />
      <AdminSetting
        label="Default workspace"
        :value="currentUser.settings?.workspace_name"
        :readonly="true"
        class="border-t border-t-gray-900 pt-10"
      />
      <AdminWarehouseChooser
        v-if="currentUser.settings?.workspace_id"
        :user-id="currentUser.user_id"
        :workspace-id="currentUser.settings.workspace_id"
        :current-warehouse-id="currentUser.settings.warehouse_id"
        @warehouse-updated="adminStore.loadUsers"
      />
      <TextButton label="Resync" @click="resync(currentUser!.user_id)" />
    </div>
  </Dialog>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useAdminStore } from "../../stores/adminStore";
import { AsyncStatus, asyncValue } from "../../lib/async";
import { computed, onMounted, ref } from "vue";
import AdminSetting from "./AdminSetting.vue";
import { isNil, toString } from "lodash";
import Dialog from "../Dialog.vue";
import MessageBar from "../MessageBar.vue";
import AdminWarehouseChooser from "./AdminWarehouseChooser.vue";
import Spinner from "../Spinner.vue";
import TextButton from "../TextButton.vue";

const adminStore = useAdminStore();
const { users } = storeToRefs(adminStore);
const loading = ref(false);

const currentUserId = ref<string | undefined>();
const currentUser = computed(() => {
  const currentUsers = asyncValue(users.value);
  if (isNil(currentUsers)) {
    return undefined;
  }
  return currentUsers.find((u) => u.user_id === currentUserId.value);
});

const errorMessage = ref("");

async function setHomepage(userId: string, homepage: string) {
  await doUpdate(() => adminStore.setHomepage(userId, { default_homepage: homepage }));
}

async function changePassword(userId: string, password: string) {
  await doUpdate(async () => {
    const result = await adminStore.changePassword(userId, password);
    if (result.error) {
      errorMessage.value = result.error;
    }
  });
}

async function updateName(userId: string, name: string) {
  await doUpdate(() => adminStore.updateName(userId, name));
}

async function resync(userId: string) {
  await doUpdate(() => adminStore.resyncUser(userId));
}

async function doUpdate(action: () => Promise<unknown>) {
  loading.value = true;
  errorMessage.value = "";
  try {
    await action();
    await adminStore.loadUsers();
  } finally {
    loading.value = false;
  }
}

onMounted(() => adminStore.loadUsers());
</script>
