import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_2 = { class: "flex flex-col items-start gap-15" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "italic"
}
const _hoisted_5 = { class: "flex flex-col items-start gap-15" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "italic"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-xl" }, "Home Page", -1 /* HOISTED */)),
            ($setup.userSettings?.default_homepage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.userSettings?.default_homepage), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, "Home page not set")),
            _createVNode($setup["TextButton"], {
              label: "Update\n        home page",
              onClick: $setup.editHomePage
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-xl" }, "Default LLM", -1 /* HOISTED */)),
            ($setup.currentDefaultLLMLabel)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($setup.currentDefaultLLMLabel), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, "Default LLM not set")),
            _createVNode($setup["TextButton"], {
              label: "Update default LLM",
              onClick: $setup.editDefaultLLM
            })
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.editingHomePage !== undefined)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 1,
          title: "Update home page",
          "success-label": "Update",
          onSucceeded: $setup.finishEditHomePage,
          onCancelled: _cache[1] || (_cache[1] = ($event: any) => ($setup.editingHomePage = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Textbox"], {
              label: "Home page path",
              modelValue: $setup.editingHomePage,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.editingHomePage) = $event)),
              autoselect: true
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[6] || (_cache[6] = _createTextVNode(" Enter a custom URL path to determine where you are taken upon login (e.g., /reader). "))
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.editingDefaultLLM !== undefined)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 2,
          title: "Update default LLM",
          "success-label": "Update",
          onSucceeded: $setup.finishEditDefaultLLM,
          onCancelled: _cache[3] || (_cache[3] = ($event: any) => ($setup.editingDefaultLLM = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Select"], {
              label: "Select default LLM",
              modelValue: $setup.editingDefaultLLM,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.editingDefaultLLM) = $event)),
              options: $setup.llmMenuItems,
              "option-label": (option) => option.label,
              "option-value": (option) => option.key,
              class: "h-[350px]"
            }, null, 8 /* PROPS */, ["modelValue", "options", "option-label", "option-value"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}