<template>
  <div class="absolute inset-0 flex min-w-0">
    <div class="basis-full overflow-hidden">
      <Chart :spec="spec" @select="handleSelect" />
    </div>
    <div class="relative" v-if="showLegend">
      <ResizeObserver :emit-on-mount="true" @notify="handleLegendResize" />
      <Legend :items="legend" :total="total" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  colorizer,
  emptyFormattedValue,
  generatePropertyValue,
  generateValueSets,
  generatorAlias,
  generatorName,
  ValueGenerator,
  ValueGeneratorType,
  visualizationTheme,
} from "@/reader/lib/visualization";
import { ComputedRef, Ref, computed, inject, ref, toRefs } from "vue";
import * as vega from "vega";
import Chart from "@/common/components/Chart.vue";
import { DarkMode } from "@/common/lib/keys";
import { FloatValue, GraphValue, stringifyValue, toNative, toValue } from "@/common/lib/value";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { GraphCompoundValue, stringifyValueOrCompositeValue } from "@/common/lib/graph";
import { groupBy, isString, pick, sum } from "lodash";
import { DiscreteDistributionVisualization } from "@/reader/lib/visualizationTypes";
import { combinedQuerySignature } from "@/common/lib/combiningQuery";
import Legend, { LegendItem } from "../page/Legend.vue";
import { ResizeObserver } from "vue-resize";
import { ValueWithFormattedValue } from "@/common/lib/format";

const legendWidth = ref(0);

const props = defineProps<{
  visualization: DiscreteDistributionVisualization;
  results: UseQueryResult[];
  width?: number;
}>();
const { visualization, results, width } = toRefs(props);

const emit = defineEmits<{ select: [alias: ValueGenerator, value: GraphValue | null] }>();

const darkMode = inject(DarkMode) as Ref<boolean>;

interface Datum {
  index: number;
  category: GraphValue | GraphCompoundValue | null;
  categoryId: string;
  categoryName: [string, string];
  subcategory: GraphValue | GraphCompoundValue | null;
  subcategoryId: string;
  value: number;
  tooltip: Record<string, string>;
  labelTooltip: Record<string, string | number> | string;
  color: string;
}

const generators = () =>
  pick(visualization.value.config, "category", "category_name", "value", "subcategory");
const query = () => combinedQuerySignature(visualization.value.query);
const isStacked = computed(() => visualization.value.config.subcategory != null);
const showLegend = computed(() => isStacked.value || visualization.value.config.show_total);
const valueSets = computed(() =>
  generateValueSets(generators(), results.value, query(), ["category", "value"])
);
const colors = computed(function () {
  const coloredAlias = generators().subcategory != null ? "subcategory" : "category";
  const useArbitraryColors =
    generators().subcategory != null || !!visualization.value.config.multicolor;
  return colorizer(query(), generators(), darkMode.value, coloredAlias, useArbitraryColors);
});

const data = computed(function () {
  const viz = visualization.value;
  const categoryLabel = generatorName(viz.config.category_name ?? viz.config.category, query());
  const subcatLabel = viz.config.subcategory && generatorName(viz.config.subcategory, query());
  const valueLabel = generatorName(viz.config.value, query());
  let categorySums: Record<string, number>;
  if (viz.config.subcategory) {
    const categoryTotals = groupBy(valueSets.value, (valueSet) => {
      return valueSet.category?.originalValue?.value ?? "Unknown";
    });
    categorySums = Object.keys(categoryTotals).reduce(
      (totals, categoryId) => {
        const sum = categoryTotals[categoryId].reduce(
          (acc, values) => acc + toNative(values.value!.originalValue as FloatValue),
          0
        );
        totals[categoryId] = sum;
        return totals;
      },
      {} as Record<string, number>
    );
  }

  return valueSets.value.map(function (values, index): Datum {
    function getLabelTooltip(): Record<string, string | number> | string {
      if (values.subcategory != null) {
        return {
          Name: categoryName,
          Total: categorySums[categoryId] || 0,
        };
      } else {
        return categoryName;
      }
    }
    const categoryId = stringifyValueOrCompositeValue(values.category?.originalValue);
    const categoryName = stringifyValue((values.category_name ?? values.category)?.formattedValue);
    const subcategoryId = stringifyValueOrCompositeValue(values.subcategory?.originalValue);
    const tooltip: Record<string, string> = { [categoryLabel]: categoryName };
    if (values.subcategory != null) {
      tooltip[subcatLabel!] = subcategoryId;
    }
    tooltip[valueLabel] = stringifyValue(values.value!.formattedValue);
    return {
      index,
      category: values.category?.originalValue ?? null,
      categoryId,
      categoryName: [categoryName, categoryId],
      subcategory: values.subcategory?.originalValue ?? null,
      subcategoryId,
      value: toNative(values.value!.originalValue as FloatValue),
      tooltip,
      labelTooltip: getLabelTooltip(),
      color: colors.value(values),
    };
    // Why does categoryName include the id as well? Because both the domain and
    // range of the scale mapping categoryId to categoryName must be unique, so
    // without this, duplicate categoryNames cause brokenness
  });
});

const height = computed(
  () => 30 + Object.keys(groupBy(data.value, (d) => d.categoryId)).length * 25
);

const legend = computed(function () {
  if (!isStacked.value) return [];
  const categories = groupBy(valueSets.value, (vs) =>
    stringifyValueOrCompositeValue(vs.subcategory?.originalValue)
  );
  return Object.values(categories).map(function (valueSets): LegendItem {
    const valueSet = valueSets[0];
    let value: ValueWithFormattedValue | undefined = undefined;
    if (visualization.value.config.show_legend_values) {
      const total = sum(valueSets.map((vs) => toNative(vs.value!.originalValue as GraphValue)));
      value = generatePropertyValue(visualization.value.config.value, toValue(total), query());
    }
    return {
      identifier: valueSet.subcategory?.originalValue as GraphValue | undefined,
      label: valueSet.subcategory ?? emptyFormattedValue(),
      color: colors.value(valueSet),
      value,
    };
  });
});

const total = computed(function () {
  if (!visualization.value.config.show_total) return undefined;
  const totalNum = sum(
    valueSets.value.map((vs) => toNative(vs.value!.originalValue as GraphValue))
  );
  return generatePropertyValue(visualization.value.config.value, toValue(totalNum), query());
});

function tickTransformer() {
  const generator = visualization.value.config.value;
  if (!isString(generator) && generator.type === ValueGeneratorType.Property) {
    return generator.transformer;
  }
  return undefined;
}

const spec: ComputedRef<vega.Spec> = computed(function () {
  const theme = visualizationTheme(darkMode.value);
  const spec: vega.Spec = {
    width: (width.value ?? 370) - legendWidth.value - 10,
    height: height.value,
    padding: 5,
    autosize: "fit",
    data: [
      {
        name: "table",
        values: data.value,
        transform: [
          {
            type: "stack",
            groupby: ["categoryId"],
            field: "value",
            as: ["x0", "x1"],
          },
        ],
      },
    ],
    signals: [
      {
        name: "selection",
        value: null,
        on: [{ events: "@bar:click", update: "datum.category" }],
      },
    ],
    scales: [
      {
        name: "x",
        type: "linear",
        domain: { data: "table", field: "x1" },
        range: "width",
        nice: true,
      },
      {
        name: "y",
        type: "band",
        domain: { data: "table", field: "categoryId" },
        range: "height",
        padding: 0.2,
      },
      {
        name: "y_names",
        type: "ordinal",
        domain: { data: "table", field: "categoryId" },
        range: { data: "table", field: "categoryName" },
      },
    ],
    axes: [
      {
        orient: "bottom",
        format: "s",
        scale: "x",
        tickCount: 5,
        labelColor: theme.label,
        labelFontSize: 12,
        labelOverlap: true,
        encode: {
          labels: tickTransformer()
            ? {
                update: {
                  text: { signal: `transform(datum.value, "${tickTransformer()}")` },
                },
              }
            : undefined,
        },
      },
      {
        scale: "y",
        orient: "left",
        labelColor: theme.label,
        labelLimit: 120,
        domain: false,
        ticks: false,
        labelPadding: 5,
        encode: {
          labels: {
            update: {
              text: { signal: "scale('y_names', datum.value)[0]" },
            },
          },
        },
      },
    ],
    marks: [
      {
        name: "bar",
        type: "rect",
        from: { data: "table" },
        encode: {
          update: {
            fill: { signal: "datum.color" },
            opacity: { value: 0.8 },
            x: { scale: "x", field: "x0" },
            x2: { scale: "x", field: "x1" },
            y: { scale: "y", field: "categoryId" },
            height: { scale: "y", band: 1 },
            tooltip: { signal: "datum.tooltip" },
          },
          hover: {
            opacity: { value: 1.0 },
            cursor: { value: "pointer" },
          },
        },
      },
      {
        type: "rect",
        from: { data: "table" },
        encode: {
          enter: {
            x: {
              value: -50,
            },
            x2: { value: 0 },
            y: { scale: "y", field: "categoryId" },
            height: { scale: "y", band: 1 },
            fill: { value: "transparent" },
            tooltip: { signal: "datum.labelTooltip" },
          },
          update: {
            fillOpacity: { value: 0 },
          },
          hover: {
            fillOpacity: { value: 0.1 },
          },
        },
      },
    ],
  };
  return spec;
});

function handleSelect(category: unknown) {
  const alias = generatorAlias(visualization.value.config.category);
  if (alias) emit("select", alias, category as GraphValue | null);
}

function handleLegendResize({ width: x }: { width: number }) {
  legendWidth.value = x;
}
</script>
