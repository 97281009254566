import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-start gap-15" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "w-full table-fixed border-collapse" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "p-5" }
const _hoisted_6 = { class: "p-5" }
const _hoisted_7 = { class: "p-5" }
const _hoisted_8 = { class: "p-5" }
const _hoisted_9 = { class: "p-5" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "flex flex-col gap-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-xl" }, "Users", -1 /* HOISTED */)),
      ($setup.users.status === $setup.AsyncStatus.Succeeded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("table", _hoisted_3, [
              _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
                _createElementVNode("tr", { class: "text-left" }, [
                  _createElementVNode("th", {
                    scope: "col",
                    class: "p-5"
                  }, "Full name"),
                  _createElementVNode("th", {
                    scope: "col",
                    class: "p-5"
                  }, "Email"),
                  _createElementVNode("th", {
                    scope: "col",
                    class: "p-5"
                  }, "Status"),
                  _createElementVNode("th", {
                    scope: "col",
                    class: "p-5"
                  }, "Organization"),
                  _createElementVNode("th", {
                    scope: "col",
                    class: "p-5"
                  }, "Default workspace")
                ])
              ], -1 /* HOISTED */)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.users.result, (user) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: user.user_id,
                    onClick: ($event: any) => ($setup.currentUserId = user.user_id),
                    class: _normalizeClass(["hover:bg-orange", { 'bg-orange': user.user_id === $setup.currentUserId }])
                  }, [
                    _createElementVNode("td", _hoisted_5, _toDisplayString(user.name), 1 /* TEXT */),
                    _createElementVNode("td", _hoisted_6, _toDisplayString(user.email), 1 /* TEXT */),
                    _createElementVNode("td", _hoisted_7, _toDisplayString(user.status), 1 /* TEXT */),
                    _createElementVNode("td", _hoisted_8, _toDisplayString(user.organization ?? "-"), 1 /* TEXT */),
                    _createElementVNode("td", _hoisted_9, _toDisplayString(user.settings?.["workspace_id"] ?? "-"), 1 /* TEXT */)
                  ], 10 /* CLASS, PROPS */, _hoisted_4))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.users.status === $setup.AsyncStatus.InProgress)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode($setup["Spinner"])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    ($setup.currentUser)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 0,
          icon: "md:settings",
          title: `Settings for ${$setup.currentUser.name}`,
          "success-label": null,
          "cancel-label": "Done",
          onCancelled: _cache[4] || (_cache[4] = ($event: any) => ($setup.currentUserId = undefined))
        }, {
          default: _withCtx(() => [
            ($setup.errorMessage)
              ? (_openBlock(), _createBlock($setup["MessageBar"], {
                  key: 0,
                  mode: "error"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.errorMessage), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_11, [
              _createVNode($setup["AdminSetting"], {
                label: "ID",
                value: $setup.currentUser.user_id,
                readonly: true,
                copyable: true
              }, null, 8 /* PROPS */, ["value"]),
              _createVNode($setup["AdminSetting"], {
                label: "Admin",
                value: $setup.toString($setup.currentUser.status === 'admin'),
                readonly: true
              }, null, 8 /* PROPS */, ["value"]),
              _createVNode($setup["AdminSetting"], {
                label: "Name",
                value: $setup.currentUser.name,
                onUpdate: _cache[0] || (_cache[0] = (name) => $setup.updateName($setup.currentUser!.user_id, name)),
                loading: $setup.loading
              }, null, 8 /* PROPS */, ["value", "loading"]),
              _createVNode($setup["AdminSetting"], {
                label: "Email",
                value: $setup.currentUser.email,
                readonly: true
              }, null, 8 /* PROPS */, ["value"]),
              _createVNode($setup["AdminSetting"], {
                label: "Password",
                value: "••••••••",
                onUpdate: _cache[1] || (_cache[1] = (pw) => $setup.changePassword($setup.currentUser!.user_id, pw)),
                loading: $setup.loading
              }, null, 8 /* PROPS */, ["loading"]),
              _createVNode($setup["AdminSetting"], {
                label: "Homepage",
                value: $setup.currentUser.settings?.homepage,
                onUpdate: _cache[2] || (_cache[2] = (url) => $setup.setHomepage($setup.currentUser!.user_id, url)),
                loading: $setup.loading
              }, null, 8 /* PROPS */, ["value", "loading"]),
              _createVNode($setup["AdminSetting"], {
                label: "Default workspace",
                value: $setup.currentUser.settings?.workspace_name,
                readonly: true,
                class: "border-t border-t-gray-900 pt-10"
              }, null, 8 /* PROPS */, ["value"]),
              ($setup.currentUser.settings?.workspace_id)
                ? (_openBlock(), _createBlock($setup["AdminWarehouseChooser"], {
                    key: 0,
                    "user-id": $setup.currentUser.user_id,
                    "workspace-id": $setup.currentUser.settings.workspace_id,
                    "current-warehouse-id": $setup.currentUser.settings.warehouse_id,
                    onWarehouseUpdated: $setup.adminStore.loadUsers
                  }, null, 8 /* PROPS */, ["user-id", "workspace-id", "current-warehouse-id", "onWarehouseUpdated"]))
                : _createCommentVNode("v-if", true),
              _createVNode($setup["TextButton"], {
                label: "Resync",
                onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.resync($setup.currentUser!.user_id)))
              })
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}