import { VisualizationConfigControlType } from "@/reader/lib/visualizationConfig";
import { App } from "vue";
import VisualizationCheckboxControl from "./VisualizationCheckboxControl.vue";
import VisualizationColumnControl from "./VisualizationColumnControl.vue";

export default function (app: App): void {
  app.component(
    `viz-config-control-${VisualizationConfigControlType.Column}`,
    VisualizationColumnControl
  );
  app.component(
    `viz-config-control-${VisualizationConfigControlType.Checkbox}`,
    VisualizationCheckboxControl
  );
}
